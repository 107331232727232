/** @jsxImportSource @emotion/react */
import React from 'react';
import cn from 'classnames';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { menuStyle } from '../common/styles/NavStyles';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';

export interface MenuListProps<T = string> {
  menus: T[];
  selected: string | string[];
  key?: string;
  onClick: Function;
  css?: any;
}

interface MenuListChildComponentProps extends ListChildComponentProps<string> {
  onClick?: Function;
  selected?: string | string[];
}

function EmptyRow() {
  return (
    <ListItem>
      <ListItemText>No Data...</ListItemText>
    </ListItem>
  );
}

function RenderRow({
  style,
  data,
  onClick,
  selected,
}: MenuListChildComponentProps) {
  return (
    <ListItem
      button
      style={{ ...style, borderBottom: '1px solid rgb(221, 221, 221)' }}
      onClick={() => onClick && onClick(data)}
      className={cn({ selected: data === selected })}
    >
      <ListItemText>{data}</ListItemText>
    </ListItem>
  );
}

function MenuList({ menus, selected, key, ...menuProps }: MenuListProps) {
  const [idx, setIdx] = React.useState(-1);

  React.useEffect(() => {
    setIdx(menus.indexOf(selected as string));
  }, [menus]);

  const listRef = React.useCallback(
    (node) => {
      if (node && idx > -1) {
        node.scrollToItem(idx, 'auto');
      }
    },
    [idx],
  );

  return (
    <div css={[menuStyle]}>
      {menus.length > 0 ? (
        <List
          ref={listRef}
          height={665}
          itemCount={menus.length}
          itemSize={46}
          width="100%"
        >
          {({ index, ...childProps }) => (
            <RenderRow
              {...childProps}
              data={menus[index]}
              selected={selected}
              index={index}
              {...menuProps}
            />
          )}
        </List>
      ) : (
        <EmptyRow />
      )}
    </div>
  );
}

export default MenuList;
