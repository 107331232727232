import { Column } from '../../../components/CustomTable';
import { TableQuery } from '../../table/staticData/table';

export const initialReportQuery: TableQuery = {
  sort: 'createdDate,desc',
  page: '0',
  search: '',
  size: '10',
  type: 'publicKey',
};
export const columns: Column[] = [
  {
    name: '확인코드',
    property: 'publicKey',
  },
  {
    name: '타입',
    property: 'type',
  },
  {
    name: '서비스코드',
    property: 'serviceCode',
    align: 'right',
  },
  {
    name: 'IP 주소',
    property: 'ipAddress',
  },
  {
    name: 'API 토큰',
    property: 'apiToken',
  },
  {
    name: '상태',
    property: 'status',
    align: 'center',
  },
  {
    name: '날짜',
    property: 'createdDate',
  },
];
