import React, { useState, useCallback } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Form from '../../components/SubmitFormWithFormik';
import { newsField } from './staticData/news';
import { boardForm } from '../../common/styles/FormStyles';
import ImageUpload from '../../components/ImageUpload';
import { NewsEntity } from '../../features/news/newsApi';
import { InformationType } from '../../features/disease/subDiseaseApi';
import { useAppSelector } from '../../app/hooks';
import Information from '../../features/disease/DiseaseInformation';
import { FormLabel, FormContainer } from '../../common/styles/FormStyles';
import PopUp from '../../components/PopUp';
import { informationField } from '../../features/disease/staticData/subDisease';
import Player from '../../components/Player';
import TextField from '@material-ui/core/TextField';
interface NewsFormProps {
  data?: NewsEntity;
  onSubmit: any;
}

const initialInfo = {
  title: '',
  type: 'NEWS',
  url: '',
  video: null,
};

function NewsForm({ data, onSubmit }: NewsFormProps) {
  const loading = useAppSelector((state) => state.files.loading);

  const [info, setInfo] = useState<InformationType>(initialInfo);

  const [information, setInformation] = useState(data?.information);
  const handleSubmit = useCallback(
    (v) => {
      onSubmit({
        ...v,
        information: (information || []).map((m: any) => {
          const obj = {
            ...m,
            videoId: m.video ? m.video.id : null,
          };
          delete obj.video;
          delete obj.id;

          return obj;
        }),
      });
    },
    [onSubmit, information],
  );
  const handleClick = useCallback(
    (index: number, method: string) => {
      if (information) {
        // 삭제
        if (method === 'delete') {
          const map = information.filter((m, i) => i !== index);
          setInformation(map);
        }
      }
    },
    [setInformation, information],
  );
  const handleInfomationSubmit = useCallback(
    (v) => {
      let map = [];
      if (information) {
        if (Number.isInteger(v.id)) {
          // 수정
          map = information.map((m, i) => {
            if (i === v.id) return v;
            else return m;
          });
        } else {
          // 추가
          map = [...information, v];
        }
      } else {
        map = [v];
      }

      setInformation(map);
      setOpen(false);
    },
    [information],
  );

  const [open, setOpen] = useState(false);
  const handlePop = useCallback(
    (index?, method?) => {
      if (method === 'put') {
        if (information) {
          setInfo({
            ...information[index],
            id: index,
          });
        }
      }
      if (!method) {
        setInfo(initialInfo);
      }
      setOpen(true);
    },
    [information],
  );

  // news formik
  const formik = useFormik({
    initialValues: {
      title: data ? data.title : '',
      contents: data ? data.contents : '',
      imageId: data ? (data.image ? data.image.id : null) : null,
      information: data ? data.information : [],
    },
    validationSchema: Yup.object({
      imageId: Yup.number().required('required'),
    }),
    onSubmit: handleSubmit,
  });

  return (
    <>
      <Form formik={formik} css={boardForm} formData={newsField}>
        <ImageUpload formik={formik} defaultImage={data ? data.image : null} />
        <div style={{ height: 20 }} />
        <FormContainer>
          <FormLabel>관련 정보</FormLabel>
          <div style={{ padding: '0px 20px' }}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <button
                style={{
                  background: 'none',
                  border: '1px solid #68AACF',
                  color: '#68AACF',
                  padding: '5px 10px',
                  borderRadius: 4,
                  cursor: 'pointer',
                }}
                onClick={(e) => {
                  e.preventDefault();
                  handlePop();
                }}
              >
                관련 정보 추가
              </button>
            </div>
            {(information || []).map((info, i) => {
              return (
                <Information information={info} key={i}>
                  <button
                    className="none-button"
                    onClick={(e) => {
                      e.preventDefault();
                      handlePop(i, 'put');
                    }}
                  >
                    수정
                  </button>
                  <button
                    className="none-button"
                    onClick={(e) => {
                      e.preventDefault();
                      handleClick(i, 'delete');
                    }}
                  >
                    삭제
                  </button>
                </Information>
              );
            })}
            {(!information || information.length <= 0) && (
              <div style={{ textAlign: 'center', color: '#C4C4C4' }}>
                관련 정보가 없습니다.
              </div>
            )}
          </div>
        </FormContainer>
        <button type="submit" disabled={loading} style={{ marginBottom: 50 }}>
          {`${loading ? 'Loading...' : data ? '수정' : '등록'}하기`}
        </button>
      </Form>
      {open && (
        <PopUp title="관련 정보 추가" onClose={() => setOpen(false)}>
          <div style={{ width: '90%' }}>
            <InformationPopupForm
              data={info}
              onSubmit={handleInfomationSubmit}
            />
          </div>
        </PopUp>
      )}
    </>
  );
}

export default NewsForm;

function InformationPopupForm({
  data,
  onSubmit,
}: {
  data: InformationType;
  onSubmit: any;
}) {
  const loading = useAppSelector((state) => state.files.loading);

  // info formik
  const formik = useFormik({
    initialValues: {
      title: data.title,
      url: data.url,
      type: data.type,
      videoId: data.video ? data.video.id : null,
      videoType: data.video ? 'file' : 'link',
      id: data.id,
      video: data.video || null,
    },
    validationSchema: Yup.object({
      title: Yup.string().required('required'),
      url: Yup.string().url(),
    }),
    onSubmit: (v) => {
      //videotype 분기
      const o = {
        ...v,
        videoId: v.videoType === 'file' ? Number(v.videoId) : null,
        url: v.videoType === 'file' ? null : v.url,
        video: v.videoType === 'file' ? v.video : null,
      };

      onSubmit(o);
    },
  });

  const disableArr = React.useMemo(() => {
    return data.title.length > 0 ? ['type'] : [''];
  }, [data]);

  return (
    <Form
      formik={formik}
      formData={informationField}
      css={boardForm}
      disableArr={disableArr}
    >
      {formik.values.type === 'VIDEO' ? (
        <Player
          formik={formik}
          defaultVideo={data ? data.video : null}
          valueType="url"
        />
      ) : (
        <>
          <TextField
            type="text"
            name="url"
            onChange={formik.handleChange}
            value={formik.values.url}
            placeholder="링크 주소를 입력하세요"
            variant="outlined"
            label="URL"
            error={!!formik.errors['url']}
            helperText={formik.errors['url']}
            required
          />
          <Information information={formik.values} children={<></>} />
        </>
      )}

      <button type="submit" disabled={loading} style={{ marginBottom: 0 }}>
        {`${data.title.length > 0 ? '수정' : '등록'}하기`}
      </button>
    </Form>
  );
}
