import apiModule, {
  ApiRequest,
  ApiRequestConfig,
} from '../../common/wrapper/axios';
import { name } from './newsSlice';
import qs from 'query-string';
import { TableEntity, TableQuery } from '../table/staticData/table';
import { FileEntity } from '../file/fileApi';
import { InformationType } from '../disease/subDiseaseApi';
export interface NewsDto {
  title: string;
  contents: string;
  imageId: number;
  videoId: number | null;
  videoUrl: string | null;
}

export type ImageEntity = FileEntity;
export type VideoEntity = FileEntity;
export interface NewsEntity {
  id: number;
  title: string;
  contents: string;
  image: ImageEntity;
  video: VideoEntity;
  videoUrl: string;
  information: InformationType[];
}

export interface RequestDto<D> {
  method: ApiRequestConfig['method'];
  id?: number;
  data?: D;
  query?: TableQuery;
  headers?: any;
  hash?: string;
}

export type NewsRequestDto = RequestDto<NewsDto>;
export type NewsTableEntity = TableEntity<NewsEntity>;

export const requestNews: ApiRequest<NewsRequestDto, NewsTableEntity> = (
  params = { method: 'get' },
) => {
  const { method, id, data, query } = params;

  let url = `${name}`;
  if (id) {
    url = `${url}/${id}`;
  }
  // FIXME: query가 string일수도..
  if (query) {
    url = `${url}?${qs.stringify(query)}`;
  }

  return apiModule.request({
    method,
    url,
    data,
  });
};
