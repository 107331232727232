import React, { useCallback } from 'react';
import { useFormik } from 'formik';
import Form from '../../components/SubmitFormWithFormik';
import { pushField } from './staticData/push';
import { boardForm } from '../../common/styles/FormStyles';
import Grid from '@material-ui/core/Grid';
import NotiRenderer from './NotiRenderComponent';
import ios from '../../assets/ios-noti-example.png';
import android from '../../assets/android-noti-example.png';
import { pushActions } from './pushSlice';
import { useAppDispatch } from '../../app/hooks';

function PushForm() {
  const dispatch = useAppDispatch();

  const handleSubmit = useCallback(
    (v) => {
      dispatch(pushActions.requestPush(v));
    },
    [dispatch],
  );

  const formik = useFormik({
    initialValues: {
      title: '',
      body: '',
    },
    onSubmit: handleSubmit,
  });

  return (
    <Grid container>
      <Grid item xs={8}>
        <Form formik={formik} formData={pushField} css={boardForm}>
          <button type="submit" style={{ marginBottom: 50 }}>
            전송 하기
          </button>
        </Form>
      </Grid>
      <Grid item xs={4}>
        <NotiRenderer
          imgPath={ios}
          deviceName="IOS"
          title={formik.values['title']}
          body={formik.values['body']}
        />
        <NotiRenderer
          imgPath={android}
          deviceName="Android"
          title={formik.values['title']}
          body={formik.values['body']}
        />
      </Grid>
    </Grid>
  );
}

export default PushForm;
