import { Column } from '../../../components/CustomTable';
import { MemberTableEntity } from '../memberApi';
import { Column as GridColumn, TextEditor } from 'react-data-grid';
import DeleteInstButton from '../DeleteInstButton';

export const initialMembers: MemberTableEntity = {
  content: [],
  empty: true,
  first: true,
  last: true,
  number: 0,
  numberOfElements: 0,
  pageable: {
    offset: 0,
    pageNumber: 0,
    pageSize: 0,
    paged: true,
    sort: {
      empty: true,
      sorted: true,
      unsorted: true,
    },
    unpaged: true,
  },
  size: 0,
  sort: {
    empty: true,
    sorted: true,
    unsorted: true,
  },
  totalElements: 0,
  totalPages: 0,
};

export type Member = {
  id: number;
  email: string;
  nickname: string;
  phoneNumber: string;
  institution: string;
  authority: string;
  lastLoginDate: string;
  accountDeleted: boolean;
  accountLock: boolean;
  accountExpired: boolean;
};

export type SelectBox = {
  value: string;
  label: string;
};

export const columns: Column[] = [
  {
    name: '사용자이름',
    property: 'nickname',
  },
  {
    name: '이메일',
    property: 'email',
  },
  {
    name: '전화번호',
    property: 'phoneNumber',
  },
  {
    name: '소속기관',
    property: 'institution',
  },
  {
    name: '권한',
    property: 'authority',
  },
  {
    name: '마지막접속시간',
    property: 'lastLoginDate',
  },
  {
    name: '수정',
    property: 'modify',
  },
  {
    name: '비밀번호재전송',
    property: 'resend',
  },
];

export const institutioncolumns: GridColumn<any>[] = [
  {
    name: '기관명',
    key: 'institutionName',
    headerCellClass: 'cell-center',
    editor: TextEditor,
  },
  {
    name: '편집',
    key: 'action',
    headerCellClass: 'cell-center',
    cellClass: 'cell-center',
    formatter({ row }) {
      return <DeleteInstButton id={row.id} />;
    },
    width: 50,
  },
];
