import React from 'react';
import Histogram from './Histogram';
import Genotype from './Genotype';

function GenoTypeForm() {
  return (
    <div>
      <Histogram />
      <Genotype />
    </div>
  );
}

export default GenoTypeForm;

export function EmptyRowsRenderer() {
  return <div style={{ textAlign: 'center' }}>No Data...</div>;
}
