import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { resetAuthTokenAndHeader } from '../auth/authService';

function LogoutButton({ onClose }: any) {
  const handleClick = () => {
    resetAuthTokenAndHeader().then((v) => {
      if (v) {
        onClose();
        window.location.replace('/');
      }
    });
  };

  return <MenuItem onClick={handleClick}>로그아웃</MenuItem>;
}

export default LogoutButton;
