import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import * as reportApi from './reportApi';
import { RootState } from '../../app/store';
import { theme } from '../../common/styles';

export const name = 'reportContent';

//state
interface ReportContentState {
  content: reportApi.ReportEntity | null;
  selected: string | string[];
  selectedDisease: string;
  selectedSubDisease: string;
  loading: boolean;
  error: string | undefined;
}

const initialState: ReportContentState = {
  content: null,
  selected: '',
  selectedDisease: '',
  selectedSubDisease: '',
  loading: false,
  error: '',
};

//async
const fetchReportByPublicKey = createAsyncThunk<
  reportApi.ReportEntity,
  reportApi.ReportDto
>(`${name}/fetchReportByPublicKey`, async (dto, { rejectWithValue }) => {
  try {
    const { data } = await reportApi.fetchReportByPublicKey(dto);
    return data;
  } catch (e) {
    return rejectWithValue(e.message);
  }
});

//slice
const reportSlice = createSlice({
  name,
  initialState,
  reducers: {
    resetReportContent(state) {
      state.content = null;
      state.selectedDisease = '';
      state.selectedSubDisease = '';
    },
    setSelectedKey(state, action: PayloadAction<any>) {
      state.selected = action.payload;
    },
    setSelectDisease(state, action) {
      state.selectedDisease = action.payload;
      state.selectedSubDisease = String(
        state.content?.diseases?.filter((f: any) => {
          return String(f.id) === action.payload;
        })[0].subDiseases[0].subDisease.id,
      );
    },
    setSelectSubDisease(state, action) {
      state.selectedSubDisease = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReportByPublicKey.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchReportByPublicKey.fulfilled, (state, action) => {
        state.loading = false;
        state.content = action.payload;
        // 상태 초기화
        state.selectedDisease = String(
          action.payload.diseases && action.payload.diseases[0].id,
        );
        state.selectedSubDisease = String(
          action.payload.diseases &&
            action.payload.diseases[0].subDiseases[0].subDisease.id,
        );
      })
      .addCase(fetchReportByPublicKey.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

//selector
const reportContentSelector = (state: RootState) =>
  state[name].content?.diseases;
const selectedDiseaseSelector = (state: RootState) =>
  state[name].selectedDisease;
const selectedSubDiseaseSelector = (state: RootState) =>
  state[name].selectedSubDisease;

const getDiseasesList = createSelector(reportContentSelector, (diseases) =>
  diseases?.map(({ id, diseaseName, subDiseases }) => ({
    id,
    label: diseaseName,
    isWarn: subDiseases.filter((f) => matchWarn(f.summary)).length > 0,
  })),
);
const getSubDiseaseList = createSelector(
  reportContentSelector,
  selectedDiseaseSelector,
  (disease, selectedDisease) =>
    disease
      ?.filter((f) => String(f.id) === selectedDisease)[0]
      .subDiseases.map(({ subDisease, summary }) => ({
        id: subDisease.id,
        label: subDisease.name,
        isWarn: matchWarn(summary),
      })),
);
const getDiseaseReport = createSelector(
  reportContentSelector,
  selectedDiseaseSelector,
  selectedSubDiseaseSelector,
  (diseases, sDisease, sSubdisease) =>
    diseases
      ?.filter((d) => String(d.id) === sDisease)[0]
      .subDiseases?.filter((sb) => String(sb.subDisease.id) === sSubdisease)[0],
);
const getHistogram = createSelector(getDiseaseReport, (report) => {
  const histogram = report?.subDisease.histograms;
  const resultValue = histogram?.map((h) => h.resultValue) || [];
  const riskLevel = histogram?.map((h) => h.riskLevel) || [];

  return {
    height: 110,
    data: {
      labels: resultValue,
      datasets: [
        {
          label: '유전형 분포',
          data: riskLevel,
          backgroundColor: histogram?.map((h) =>
            h.resultValue === report?.score
              ? theme.palette.primary2
              : '#EEEEEE',
          ),
          borderColor: histogram?.map((h) =>
            h.resultValue === report?.subDisease.meanKorean
              ? '#FFA012'
              : h.resultValue === report?.score
              ? theme.palette.primary2
              : '#EEEEEE',
          ),
          borderWidth: 2,
          barPercentage: 0.5,
          datalabels: {
            display: histogram?.map((h) => h.resultValue === report?.score),
            anchor: 'end',
            align: 'top',
            color: function (context: any) {
              return context.dataset.backgroundColor;
            },
            formatter: function () {
              return '▼';
            },
            padding: 0,
            font: {
              size: 20,
              weight: 600,
              family: 'FontAwesome',
            },
          },
        },
        {
          label: '한국인 평균 값',
          data: riskLevel.map((h) => (h === report?.score ? h : 0)),
          // backgroundColor: ['#FFDC61'],
          pointStyle: 'rect',
          borderColor: '#FFA012',
          borderWidth: 2,
          datalabels: {
            display: false,
          },
        },
        {
          label: '나의 값',
          data: riskLevel.map((h) => (h === report?.score ? h : 0)),
          backgroundColor: ['#68AACF'],
          pointStyle: 'rect',
          datalabels: {
            display: false,
          },
        },
      ],
    },
    options: {
      scales: {
        y: {
          stacked: false,
          ticks: {
            beginAtZero: true,
          },
          max: Math.max(...riskLevel) + 20,
        },
        x: { stacked: true },
      },
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            usePointStyle: true,
            filter: function (item: any) {
              return !item.text.includes('유전형 분포');
            },
          },
        },
        tooltip: {
          enabled: false,
        },
        annotation: {
          annotations: {
            box1: {
              type: 'box',
              xMin: (report?.subDisease.top5 || 0) - 0.4,
              xMax: (report?.subDisease.maxAllele || 0) + 0.4,
              yMin: 0,
              yMax: (Math.max(...riskLevel) + 5) / 2,
              backgroundColor: 'rgba(104, 170, 207, 0.2)',
              cornerRadius: 4,
              borderWidth: 1,
              borderColor: '#68AACF',
            },
            line1: {
              xMin: report?.subDisease.top5,
              xMax: report?.subDisease.maxAllele,
              yMin: (Math.max(...riskLevel) + 5) / 2,
              yMax: (Math.max(...riskLevel) + 5) / 2,
              borderColor: 'rgba(104, 170, 207, 0)',
              borderWidth: 0,
              label: {
                position: 'center',
                enabled: true,
                content: '위험인자 상위 5%',
                yAdjust: -10,
                color: '#000',
                backgroundColor: 'rgba(104, 170, 207, 0)',
              },
            },
          },
        },
      },
    },
  };
});

const getGenotypeCount = createSelector(
  reportContentSelector,
  selectedDiseaseSelector,
  selectedSubDiseaseSelector,
  (diseases, sDisease, sSubdisease) => {
    const selected = diseases
      ?.filter((d) => String(d.id) === sDisease)[0]
      .subDiseases?.filter((sb) => String(sb.subDisease.id) === sSubdisease)[0];

    let isPvCnt = 0,
      // typeCnt = 0,
      isPvGenes: string[] = [];

    selected?.subDisease.genotypes.map(({ geneName, isPv }) => {
      // const find = selected.genotypes.find(
      //   (value) => value.geneName === geneName,
      // );

      // if (find && find.type === '증가형') typeCnt++;
      if (isPv) {
        isPvCnt++;
        if (!isPvGenes.includes(geneName)) isPvGenes.push(geneName);
      }
    });

    return [isPvCnt, isPvGenes];
  },
);

const matchWarn = (summary: string) => {
  return ['집중관리', '관심관리'].includes(summary);
};

//export
export const reportContentActions = {
  fetchReportByPublicKey,
  getDiseasesList,
  getSubDiseaseList,
  getDiseaseReport,
  getHistogram,
  getGenotypeCount,
  reportContentSelector,
  selectedDiseaseSelector,
  selectedSubDiseaseSelector,
  ...reportSlice.actions,
};
export default reportSlice.reducer;
