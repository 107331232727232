import apiModule, {
  ApiRequest,
  ApiRequestConfig,
} from '../../common/wrapper/axios';
import { name } from './diseaseSlice';
import { SubDiseaseMenuEntity } from './subDiseaseApi';

export interface DiseaseEntity {
  id: number;
  name: string;
  priority: number;
  subDisease: SubDiseaseMenuEntity[];
}

export interface DiseaseDto {
  name: string;
  priority: number;
}

export interface DiseaseRequestDto {
  method: ApiRequestConfig['method'];
  id?: number;
  data?: DiseaseDto;
}

export interface DiseaseMenuDto {
  disease: DiseaseEntity[];
}

export const changeOrder: ApiRequest<any, any> = (params) => {
  return apiModule.put(`/${name}/change-order`, params);
};

export const requestDiseases: ApiRequest<DiseaseRequestDto, DiseaseEntity[]> = (
  params = { method: 'get' },
) => {
  const { method, id, data } = params;

  let url = `${name}`;
  if (id) {
    url = `${url}/${id}`;
  }

  return apiModule.request({
    method,
    url,
    data,
  });
};
