import map from 'lodash/map';
import { Switch, Route } from 'react-router-dom';
import { RouteConfigWithSubRoute } from './routeConfig';
import RouteWithSubRoute from './RouteWithSubRoute';
// import { home as Home } from '../../pages';

interface MyRouteProps {
  routes: RouteConfigWithSubRoute[] | undefined;
}

function MyRoute({ routes }: MyRouteProps) {
  return (
    <Switch>
      {map(routes, (route, idx) => (
        <RouteWithSubRoute key={idx} {...route} />
      ))}
      {/* <Route exact path="/" component={Home} /> */}
      <Route component={undefined} />
    </Switch>
  );
}

export default MyRoute;
