import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import {
  getAccessLog,
  historyActions,
} from '../../features/history/historySlice';
import {
  Button1,
  CheckBoxInput,
  Container,
  Input1,
  LowerContainer,
  SearchContainer,
  UpperContainer,
} from '../member';
import useTableRoutePath from '../../common/hooks/useTableRoutePath';
import {
  columns,
  initialAccessQuery,
} from '../../features/history/staticData/access';
import Table from '../../components/CustomTable';
import { parseQuery } from '../../common/utils/routeUtils';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { popupType } from './Report';
import { AccessLogType } from '../../features/history/staticData/history';
import PopUp from '../../components/PopUp';
import CalendarPicker from '../../components/CalendarPicker';

function Access() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { accessLog, loading } = useSelector(
    (state: RootState) => state.history.accessLogState,
  );
  const access = useAppSelector((state) =>
    historyActions.accessLogSelector(state),
  );
  const [fail, setFail] = useState(false);
  const {
    query,
    handleChangePage,
    keyword,
    onSubmit,
    setKeyword,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    routePath,
  } = useTableRoutePath(initialAccessQuery);

  const [open, setOpen] = useState(false);
  const [inputList, setInputList] = useState<popupType[]>([]);

  useEffect(() => {
    if (access) {
      setInputList([
        {
          label: '요청 API',
          state: access.requestUrl,
          type: 'text',
        },

        {
          label: '메서드',
          state: access.method,
          type: 'text',
        },
        {
          label: '응답상태',
          state: access.responseStatus,
          type: 'text',
        },
        {
          label: '상태',
          state: access.status,
          type: 'text',
        },
        {
          label: '실패원인',
          state: access.errorMessage,
          type: 'text',
        },
      ]);
    }
  }, [access, setInputList]);

  useEffect(() => {
    dispatch(getAccessLog({ ...query }));
  }, [dispatch, query]);

  const handleClickFail = useCallback(
    (row: AccessLogType) => {
      dispatch(historyActions.setSelected(String(row.id)));
      setOpen(true);
    },
    [setOpen, dispatch],
  );
  const handelClosePopup = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  useEffect(() => {
    routePath(0, { status: fail ? 'FAIL' : null });
  }, [fail]);

  return (
    <Container>
      <UpperContainer>
        <SearchContainer onSubmit={onSubmit}>
          <Input1
            type={'text'}
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            placeholder={'요청 API 입력'}
          />
          <Button1 type="submit">검색</Button1>
          <CheckBoxInput>
            <input
              type="checkbox"
              name="fail"
              onChange={(e) => setFail(e.target.checked ? true : false)}
            />
            <label>FAIL 보기</label>
          </CheckBoxInput>
        </SearchContainer>
        <CalendarPicker
          isOnlyCalender
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      </UpperContainer>
      <LowerContainer>
        {open ? (
          <PopUp
            onClose={handelClosePopup}
            inputList={inputList}
            title={'메세지'}
            buttonTitle={'닫기'}
            onClick={handelClosePopup}
          />
        ) : null}
        <Table
          list={accessLog}
          fetching={loading}
          isPaging
          pageState={{
            page: Number(parseQuery(location.search).page || 0),
            rowsPerPage: 10,
          }}
          actions={{
            handleChangePage,
          }}
          columns={columns.map((value) => {
            if (value.property === 'status') {
              return {
                ...value,
                onClick: (row: AccessLogType) => {
                  handleClickFail(row);
                },
                condition: (value: any) => {
                  return value === 'FAIL';
                },
              };
            }
            return value;
          })}
        />
      </LowerContainer>
    </Container>
  );
}

export default Access;
