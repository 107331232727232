import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as fileApi from './fileApi';

export const name = 'files';

interface FileState {
  files: fileApi.FileEntity[];
  selected: string;
  loading: boolean;
  error: string | undefined;
}

const initialState: FileState = {
  files: [],
  selected: '',
  loading: false,
  error: '',
};

export const requestFiles = createAsyncThunk<
  fileApi.FileEntity,
  fileApi.FileRequestDto
>(`${name}/requestFiles`, async ({ hash, ...params }, thunkApi) => {
  // FIXME: delete 시 return 타입..

  try {
    const { data } = await fileApi.requestFiles(params);
    return {
      ...data,
      hash,
    };
  } catch (e) {
    return thunkApi.rejectWithValue(e.message);
  }
});

// TODO: extra reducer 작성 file 배열로 수정 후...?
const fileSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(requestFiles.pending, (state) => {
        state.loading = true;
      })
      .addCase(requestFiles.fulfilled, (state, action) => {
        state.loading = false;
        state.files.push(action.payload);
      })
      .addCase(requestFiles.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const fileActions = {
  requestFiles,
  ...fileSlice.actions,
};

export default fileSlice.reducer;
