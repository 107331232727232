import apiModule, { ApiRequest } from '../../common/wrapper/axios';
import {
  AccessLogType,
  PushLogType,
  ReportDownloadLogType,
  ReportSyncLogType,
} from './staticData/history';
import { TableEntity, TableQuery } from '../table/staticData/table';
import { stringify } from '../../common/utils/routeUtils';
import qs from 'query-string';

export type ReportSyncLogEntity = TableEntity<ReportSyncLogType>;
export type ReportDownloadLogEntity = TableEntity<ReportDownloadLogType>;
export type AccessLogEntity = TableEntity<AccessLogType>;
export type PushLogEntity = TableEntity<PushLogType>;

export const getReportSyncLog: ApiRequest<TableQuery, ReportSyncLogEntity> = (
  params,
) => {
  return apiModule.get(`/history/report-sync?${stringify(params)}`);
};

export const getReportDownloadLog: ApiRequest<
  TableQuery,
  ReportDownloadLogEntity
> = (params) => {
  return apiModule.get(`/history/report-download?${stringify(params)}`);
};

export const getAccessLog: ApiRequest<TableQuery, AccessLogEntity> = (
  params,
) => {
  return apiModule.get(`/history/access?${stringify(params)}`);
};

export const getPushLog: ApiRequest<TableQuery, PushLogEntity> = (params) => {
  return apiModule.get(`/history/push?${stringify(params)}`);
};

export const getReportCheckApi = () => {
  return apiModule.get(`/reports/checkup`);
};

export const requestReportCheckApi = (params: any) => {
  const { method, id, data, query } = params;

  let url = `/reports/checkup`;
  if (id) {
    url = `${url}/${id}`;
  }
  // FIXME: query가 string일수도..
  if (query) {
    url = `${url}?${qs.stringify(query)}`;
  }

  return apiModule.request({
    method,
    url,
    data,
  });
};

export const getServiceCodeApi = () => {
  return apiModule.get(`/service-code`);
};

export const requestServiceCodeApi = (params: any) => {
  const { method, id, data } = params;

  let url = `/service-code`;

  if (id) {
    url = `${url}/${id}`;
  }

  return apiModule.request({
    method,
    url,
    data,
  });
};
