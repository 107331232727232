import apiModule, { ApiRequest } from '../../common/wrapper/axios';
import { name } from './pushSlice';
import {
  ITrigger,
  ITriggerCreateReq,
  PushRequestDto,
  PushRequestEntity,
} from './type';

export const requestPush: ApiRequest<PushRequestDto, PushRequestEntity> = (
  params,
) => {
  return apiModule.post(`${name}/notifications`, params);
};

export const fetchPushTrigger: ApiRequest<void, ITrigger[]> = () => {
  return apiModule.get(`${name}/trigger`);
};

export const createPushTrigger: ApiRequest<ITriggerCreateReq, ITrigger> = (
  params,
) => {
  return apiModule.post(`${name}/trigger`, params);
};
