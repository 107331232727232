import React, { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { histogramActions } from './histogramSlice';
import { Bar } from 'react-chartjs-2';
import { FormLabel, FormContainer } from '../../common/styles/FormStyles';
import { NoneButton } from './Tree';
import DataGrid from 'react-data-grid';
import { histogramField } from './staticData/subDisease';
import styled from '@emotion/styled';
import { EmptyRowsRenderer } from './GenoTypeForm';

function Histogram() {
  const dispatch = useAppDispatch();
  const [data, histograms] = useAppSelector((state) => [
    histogramActions.getHistogram(state),
    state.histogram.histograms,
  ]);

  const chart = React.useMemo(() => {
    return data && <Bar type="bar" {...data} />;
  }, [data]);

  const handleSave = useCallback(() => {
    dispatch(
      histogramActions.requestHistogram({
        method: 'put',
        data: histograms,
      }),
    );
  }, [dispatch, histograms]);

  const handleRowChange = useCallback(
    (v: any, v2: any) => {
      dispatch(
        histogramActions.setRow(
          v.map((rowValue: any) => {
            const isMean =
              v2.column && v2.column.key === 'isMean'
                ? histograms[v2.indexes[0]].id === rowValue.id
                : rowValue.isMean;

            return {
              ...rowValue,
              isMean,
            };
          }),
        ),
      );
    },
    [dispatch, histograms],
  );

  return (
    <FormContainer>
      <FormLabel>유전형 분포</FormLabel>
      {chart}
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: 20,
          marginBottom: 10,
        }}
      >
        <NoneButton onClick={() => dispatch(histogramActions.addRow())}>
          행추가
        </NoneButton>
        <NoneButton onClick={handleSave}>저장</NoneButton>
      </div>
      <DataGridContainter>
        <DataGrid
          columns={histogramField}
          rows={histograms}
          onRowsChange={handleRowChange}
          emptyRowsRenderer={EmptyRowsRenderer}
          className="rdg-light"
        />
      </DataGridContainter>
    </FormContainer>
  );
}

export default Histogram;

export function RemoveButton({
  id,
  actions,
}: {
  id: number | undefined;
  actions: any;
}) {
  const dispatch = useAppDispatch();
  const handleClick = useCallback(() => {
    dispatch(actions.removeRow(id));
  }, [id, dispatch, actions]);
  return <NoneButton onClick={handleClick}>삭제</NoneButton>;
}

export const DataGridContainter = styled.div`
  .cell-center {
    text-align: center;
  }
  .grid {
    height: 212px;
  }
`;
