import React, { useEffect, useState } from 'react';
import { SubDiseaseActions } from './subDiseaseSlice';
import { informationActions } from './informationSlice';
import { genotypeActions } from './genotypeSlice';
import { histogramActions } from './histogramSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import SubDiseaseForm from './SubDiseaseForm';
import InformationForm from './InformationForm';
import GenotypeForm from './GenoTypeForm';
import SubDiseaseControlTab from './ControlTab';

interface SubDiseaseProps {
  selected: string;
  setSelected?: any;
}

function SubDisease({ selected }: SubDiseaseProps) {
  const dispatch = useAppDispatch();
  const [tab, setTab] = useState(0);
  const [subDisease, loading] = useAppSelector((state) => [
    state['sub-diseases'].subDisease,
    state['sub-diseases'].loading,
  ]);

  const [links] = useAppSelector((state) => [state.information.links]);

  useEffect(() => {
    if (selected) {
      dispatch(
        SubDiseaseActions.requestSubDiseases({
          method: 'get',
          id: Number(selected),
        }),
      );
      dispatch(
        informationActions.requestInformation({
          method: 'get',
          id: Number(selected),
        }),
      );
      dispatch(
        genotypeActions.requestGenoType({
          method: 'get',
          id: Number(selected),
        }),
      );
      dispatch(
        histogramActions.requestHistogram({
          method: 'get',
          id: Number(selected),
        }),
      );
    } else dispatch(SubDiseaseActions.resetSubDisease());

    return () => {
      dispatch(histogramActions.resetHistogram());
    };
  }, [dispatch, selected]);

  return (
    <div>
      {!subDisease ? (
        <div
          style={{
            border: '1px solid #DDDDDD',
            color: '#dddddd',
            height: 124,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          질환을 선택하세요
        </div>
      ) : (
        <>
          <div style={{ marginBottom: 10 }}>
            <SubDiseaseControlTab value={tab} setValue={setTab} />
          </div>
          {!loading && (
            <div
              style={{
                // height: 680,
                // overflowY: 'auto',
                // overflowX: 'hidden',
                padding: 10,
              }}
            >
              {tab === 0 && <SubDiseaseForm data={subDisease} />}
              {tab === 1 && <InformationForm data={links} />}
              {tab === 2 && <GenotypeForm />}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default React.memo(SubDisease);
