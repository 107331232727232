import { Column } from '../../../components/CustomTable';
import { TableQuery } from '../../table/staticData/table';
import { ApiTokeEntity } from '../apiTokenApi';

export type ApiTokenType = {
  id: number;
  apiToken: string;
  ipAddress: string;
  purpose: string;
  expiredDate: string;
  createdBy: string;
  createdDate: string;
};
export const initialApiTokenResponse = {
  id: -1,
  apiToken: ' string',
  ipAddress: ' string',
  purpose: ' string',
  expiredDate: ' string',
  createdBy: ' string',
  createdDate: ' string',
};

export const initialApiToken: ApiTokeEntity = {
  content: [],
  empty: true,
  first: true,
  last: true,
  number: 0,
  numberOfElements: 0,
  pageable: {
    offset: 0,
    pageNumber: 0,
    pageSize: 0,
    paged: true,
    sort: {
      empty: true,
      sorted: true,
      unsorted: true,
    },
    unpaged: true,
  },
  size: 0,
  sort: {
    empty: true,
    sorted: true,
    unsorted: true,
  },
  totalElements: 0,
  totalPages: 0,
};
export const initialServerQuery: TableQuery = {
  sort: 'createdDate,desc',
  page: '0',
  search: '',
  size: '10',
  type: 'ipAddress',
};
export const columns: Column[] = [
  {
    name: 'IP 주소',
    property: 'ipAddress',
  },
  {
    name: 'API 토큰',
    property: 'apiToken',
  },
  {
    name: '토큰복사',
    property: 'copy',
  },
  {
    name: '사용 목적',
    property: 'purpose',
  },
  {
    name: '생성일',
    property: 'createdDate',
  },
  {
    name: '생성자',
    property: 'createdBy',
  },
  {
    name: '삭제',
    property: 'delete',
  },
];
