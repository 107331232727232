import React from 'react';
import { ReportContainer } from '../../common/styles/ReportStyles';
import { FormLabel, FormContainer } from '../../common/styles/FormStyles';
import { NoneButton } from './Tree';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import DataGrid from 'react-data-grid';
import { genotypeField } from './staticData/subDisease';
import { DataGridContainter } from './Histogram';
import { genotypeActions } from './genotypeSlice';
import { EmptyRowsRenderer } from './GenoTypeForm';

function Genotype() {
  const dispatch = useAppDispatch();
  const genotypes = useAppSelector((state) => state.genotype.genotypes);

  const handleSave = React.useCallback(() => {
    dispatch(
      genotypeActions.requestGenoType({
        method: 'put',
        data: genotypes,
      }),
    );
  }, [dispatch, genotypes]);

  const handleRowChange = React.useCallback(
    (v: any) => {
      dispatch(genotypeActions.setRow(v));
    },
    [dispatch],
  );

  return (
    <ReportContainer>
      <FormContainer>
        <FormLabel>유전형 분석결과</FormLabel>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: 20,
          }}
        >
          <NoneButton onClick={() => dispatch(genotypeActions.addRow())}>
            행추가
          </NoneButton>
          <NoneButton onClick={handleSave}>저장</NoneButton>
        </div>

        <DataGridContainter>
          <DataGrid
            columns={genotypeField}
            rows={genotypes}
            onRowsChange={handleRowChange}
            className="grid rdg-light"
            emptyRowsRenderer={EmptyRowsRenderer}
          />
        </DataGridContainter>
      </FormContainer>
    </ReportContainer>
  );
}

export default Genotype;
