import React from 'react';
import { RoutePropsTypes } from '../../app/share/routeConfig';
import MyRoute from '../../app/share/MyRoute';

import access from './Access';
import download from './Download';
import report from './Report';
import push from './Push';
import server from './Server';
import checkup from './Checkup';
import sercode from './Sercode';

function History({ routes }: RoutePropsTypes) {
  return (
    <>
      <MyRoute routes={routes} />
    </>
  );
}

export default History;

export const history = {
  access,
  download,
  report,
  push,
  server,
  checkup,
  sercode,
};
