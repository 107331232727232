import React, { useEffect, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  getServiceCode,
  historyActions,
} from '../../features/history/historySlice';
import DataGrid from 'react-data-grid';
import { columns } from '../../features/history/staticData/serCode';

import SercodeModal from '../../features/history/SercodeModal';
import { Button2 } from '../member';
import { DataGridContainter } from '../../features/disease/Histogram';
function Checkup() {
  const dispatch = useAppDispatch();
  const serCode = useAppSelector((state) =>
    historyActions.serCodeSelector(state),
  );

  useEffect(() => {
    dispatch(getServiceCode());
  }, [dispatch]);

  const handleClick = useCallback(() => {
    dispatch(historyActions.setSercodeSelect('-1'));
  }, [dispatch]);

  return (
    <>
      <div
        style={{ margin: `10px 0px`, display: 'flex', justifyContent: 'right' }}
      >
        <Button2 onClick={handleClick}>서비스 코드 추가</Button2>
      </div>
      <DataGridContainter>
        <DataGrid
          columns={columns}
          rows={serCode}
          // onRowsChange={handleRowChange}
          className="rdg-light"
          // emptyRowsRenderer={EmptyRowsRenderer}
        />
      </DataGridContainter>
      <SercodeModal />
    </>
  );
}

export default Checkup;
