import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import GnbMenu from './GnbMenu';
import SubMenu from './SubMenu';
import { menuActions } from './menuSlice';
import { useAppDispatch } from '../../app/hooks';
import ProfilePopup from './ProfilePopup';

function Menu({ user }: any) {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();

  const [open, setOpen] = useState(false);

  React.useEffect(() => {
    if (location.pathname === '/') {
      if (user.authority === 'DOCTOR') {
        history.replace('/reports');
      } else {
        history.replace('/dashboard');
      }
      return;
    }

    dispatch(menuActions.setCurrentPath(location.pathname));
  }, [dispatch, history, location.pathname, user.authority]);

  return (
    <>
      {open ? <ProfilePopup setOpen={setOpen} /> : null}
      <GnbMenu selected={location.pathname} user={user} setOpen={setOpen} />
      <SubMenu selected={location.pathname} />
    </>
  );
}

export default Menu;
