import React from 'react';
import NewsForm from './NewsForm';
import { useAppDispatch } from '../../app/hooks';
import { newsActions } from './newsSlice';
import { NewsButton } from './NewsDetail';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';

function NewsAdd({ query }: any) {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleSubmit = React.useCallback(
    (v) => {
      dispatch(
        newsActions.requestNews({
          method: 'post',
          data: v,
          query,
        }),
      );

      delete query.m;
      history.replace(`/service/news?${qs.stringify(query)}`);
    },
    [dispatch, query, history],
  );

  return (
    <div>
      <div style={{ marginBottom: 20, float: 'right' }}>
        <NewsButton onClick={() => history.goBack()}>목록가기</NewsButton>
      </div>
      <NewsForm onSubmit={handleSubmit} />
    </div>
  );
}

export default NewsAdd;
