import apiModule, { ApiRequest } from '../../common/wrapper/axios';
import { name } from './informationSlice';
import { InformationType } from './subDiseaseApi';
import { RequestDto } from '../news/newsApi';

export interface InformationDto {
  title: string;
  type: string;
  url: string | null;
  videoId: number | null;
}

export type InformationRequestDto = RequestDto<InformationDto>;
export type InformationEntity = InformationType[];

export const requestInformation: ApiRequest<
  InformationRequestDto,
  InformationEntity
> = (params = { method: 'get' }) => {
  const { method, id, data } = params;

  return apiModule.request({
    url: `${name}/${id}`,
    method,
    data,
  });
};
