import { RootState } from '../../app/store';
import {
  initialAccessLog,
  initialPushLog,
  initialReportDownloadLog,
  initialReportSyncLog,
} from './staticData/history';
import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import * as historyApi from './historyApi';
import {
  AccessLogEntity,
  PushLogEntity,
  ReportDownloadLogEntity,
  ReportSyncLogEntity,
} from './historyApi';
import * as apiTokenApi from './apiTokenApi';
import { ApiTokeEntity } from './apiTokenApi';
import { TableQuery } from '../table/staticData/table';
import {
  ApiTokenType,
  initialApiToken,
  initialApiTokenResponse,
} from './staticData/server';

const name = 'history';

interface ApiTokenState {
  apiToken: ApiTokeEntity;
  loading: boolean;
  error: string | undefined;
}

interface CreateApiTokenState {
  apiToken: ApiTokenType;
  loading: boolean;
  error: string | undefined;
}

interface RemoveApiTokenState {
  loading: boolean;
  error: string | undefined;
}

interface reportSyncLogState {
  reportSyncLog: ReportSyncLogEntity;
  loading: boolean;
  error: string | undefined;
}

interface reportDownloadLogState {
  reportDownloadLog: ReportDownloadLogEntity;
  loading: boolean;
  error: string | undefined;
}

interface accessLogState {
  accessLog: AccessLogEntity;
  loading: boolean;
  error: string | undefined;
}

interface pushLogState {
  pushLog: PushLogEntity;
  loading: boolean;
  error: string | undefined;
}

interface CheckupState {
  checkups: any[];
  loading: boolean;
  error?: string;
  selected: string;
  selectedSercode: string;
  serviceCodes: any[];
}

interface HistoryState {
  apiTokenState: ApiTokenState;
  createApiTokenState: CreateApiTokenState;
  removeApiTokenState: RemoveApiTokenState;
  reportSyncLogState: reportSyncLogState;
  reportDownloadLogState: reportDownloadLogState;
  accessLogState: accessLogState;
  pushLogState: pushLogState;
  checkupState: CheckupState;
  currentPath: string;
  selected: string | string[];
}

const initialState: HistoryState = {
  apiTokenState: {
    apiToken: initialApiToken,
    loading: false,
    error: '',
  },
  createApiTokenState: {
    apiToken: initialApiTokenResponse,
    loading: false,
    error: '',
  },
  removeApiTokenState: {
    loading: false,
    error: '',
  },
  reportSyncLogState: {
    reportSyncLog: initialReportSyncLog,
    loading: false,
    error: '',
  },
  reportDownloadLogState: {
    reportDownloadLog: initialReportDownloadLog,
    loading: false,
    error: '',
  },
  accessLogState: {
    accessLog: initialAccessLog,
    loading: false,
    error: '',
  },
  pushLogState: {
    pushLog: initialPushLog,
    loading: false,
    error: '',
  },
  checkupState: {
    checkups: [],
    loading: false,
    error: '',
    selected: '',
    selectedSercode: '',
    serviceCodes: [],
  },
  currentPath: '/member',
  selected: '',
};
// async
export const getApiKey = createAsyncThunk(
  `${name}/getApiKey`,
  async (params: TableQuery, { rejectWithValue }) => {
    try {
      const { data } = await apiTokenApi.getApiToken(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const createApiToken = createAsyncThunk<
  ApiTokenType,
  apiTokenApi.ApiTokenCreateRequest
>(`${name}/createApiToken`, async (request, { rejectWithValue }) => {
  try {
    const { data } = await apiTokenApi.createApiToken(request);
    return data;
  } catch (e) {
    return rejectWithValue(e);
  }
});
export const removeToken = createAsyncThunk<void, number>(
  `${name}/removeToken`,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await apiTokenApi.removeApiToken(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const getReportSyncLog = createAsyncThunk(
  `${name}/getReportSyncLog`,
  async (params: TableQuery, { rejectWithValue }) => {
    try {
      const { data } = await historyApi.getReportSyncLog(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const getReportDownloadLog = createAsyncThunk(
  `${name}/getReportDownloadLog`,
  async (params: TableQuery, { rejectWithValue }) => {
    try {
      const { data } = await historyApi.getReportDownloadLog(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);
export const getAccessLog = createAsyncThunk(
  `${name}/getAccessLog`,
  async (params: TableQuery, { rejectWithValue }) => {
    try {
      const { data } = await historyApi.getAccessLog(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const getPushLog = createAsyncThunk(
  `${name}/getPushLog`,
  async (params: TableQuery, { rejectWithValue }) => {
    try {
      const { data } = await historyApi.getPushLog(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const getReportCheckup = createAsyncThunk(
  `${name}/getReportCheckup`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await historyApi.getReportCheckApi();
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const requestReportCheckup = createAsyncThunk<any, any, any>(
  `${name}/requestReportCheckup`,
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await historyApi.requestReportCheckApi(params);
      dispatch(getReportCheckup());
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const getServiceCode = createAsyncThunk(
  `${name}/getServiceCode`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await historyApi.getServiceCodeApi();
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const requestServiceCode = createAsyncThunk<any, any, any>(
  `${name}/requestServiceCode`,
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await historyApi.requestServiceCodeApi(params);
      dispatch(getServiceCode());
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const historySlice = createSlice({
  name,
  initialState,
  reducers: {
    setCurrentPath(state, action: PayloadAction<string>) {
      state.currentPath = action.payload;
    },
    setSelected(state, action: PayloadAction<string>) {
      state.selected = action.payload;
    },
    resetSelected(state) {
      state.selected = '';
    },
    setCheckupSelect(state, action: PayloadAction<string>) {
      state.checkupState.selected = action.payload;
    },
    resetCheckupSelected(state) {
      state.checkupState.selected = '';
    },
    setSercodeSelect(state, action: PayloadAction<string>) {
      state.checkupState.selectedSercode = action.payload;
    },
    resetSercodeSelected(state) {
      state.checkupState.selectedSercode = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getApiKey.pending, (state) => {
        state.apiTokenState.loading = true;
      })
      .addCase(getApiKey.fulfilled, (state, action) => {
        state.apiTokenState.loading = false;
        state.apiTokenState.apiToken = action.payload;
      })
      .addCase(getApiKey.rejected, (state, action) => {
        state.apiTokenState.loading = false;
        state.apiTokenState.error = action.error.message;
      });

    builder
      .addCase(getReportSyncLog.pending, (state) => {
        state.reportSyncLogState.loading = true;
      })
      .addCase(getReportSyncLog.fulfilled, (state, action) => {
        state.reportSyncLogState.loading = false;
        state.reportSyncLogState.reportSyncLog = action.payload;
      })
      .addCase(getReportSyncLog.rejected, (state, action) => {
        state.reportSyncLogState.loading = false;
        state.reportSyncLogState.error = action.error.message;
      });
    builder
      .addCase(getReportDownloadLog.pending, (state) => {
        state.reportDownloadLogState.loading = true;
      })
      .addCase(getReportDownloadLog.fulfilled, (state, action) => {
        state.reportDownloadLogState.loading = false;
        state.reportDownloadLogState.reportDownloadLog = action.payload;
      })
      .addCase(getReportDownloadLog.rejected, (state, action) => {
        state.reportDownloadLogState.loading = false;
        state.reportDownloadLogState.error = action.error.message;
      });
    builder
      .addCase(getAccessLog.pending, (state) => {
        state.accessLogState.loading = true;
      })
      .addCase(getAccessLog.fulfilled, (state, action) => {
        state.accessLogState.loading = false;
        state.accessLogState.accessLog = action.payload;
      })
      .addCase(getAccessLog.rejected, (state, action) => {
        state.accessLogState.loading = false;
        state.accessLogState.error = action.error.message;
      });
    builder
      .addCase(getPushLog.pending, (state) => {
        state.pushLogState.loading = true;
      })
      .addCase(getPushLog.fulfilled, (state, action) => {
        state.pushLogState.loading = false;
        state.pushLogState.pushLog = action.payload;
      })
      .addCase(getPushLog.rejected, (state, action) => {
        state.pushLogState.loading = false;
        state.pushLogState.error = action.error.message;
      });

    builder
      .addCase(createApiToken.pending, (state) => {
        state.createApiTokenState.loading = true;
      })
      .addCase(createApiToken.fulfilled, (state, action) => {
        state.createApiTokenState.loading = false;
        state.createApiTokenState.apiToken = action.payload;
      })
      .addCase(createApiToken.rejected, (state, action) => {
        state.createApiTokenState.loading = false;
        state.createApiTokenState.error = action.error.message;
      });
    builder
      .addCase(removeToken.pending, (state) => {
        state.removeApiTokenState.loading = true;
      })
      .addCase(removeToken.fulfilled, (state, action) => {
        state.removeApiTokenState.loading = false;
      })
      .addCase(removeToken.rejected, (state, action) => {
        state.removeApiTokenState.loading = false;
        state.removeApiTokenState.error = action.error.message;
      });

    builder
      .addCase(getReportCheckup.pending, (state) => {
        state.checkupState.loading = true;
      })
      .addCase(getReportCheckup.fulfilled, (state, action) => {
        state.checkupState.checkups = action.payload;
        state.checkupState.loading = false;
      })
      .addCase(getReportCheckup.rejected, (state, action) => {
        state.checkupState.loading = false;
        state.checkupState.error = action.error.message;
      });

    builder
      .addCase(getServiceCode.pending, (state) => {})
      .addCase(getServiceCode.fulfilled, (state, action) => {
        state.checkupState.serviceCodes = action.payload;
      })
      .addCase(getServiceCode.rejected, (state, action) => {
        state.checkupState.error = action.error.message;
      });
  },
});

const currentPathSelector = (state: RootState) => state.history.currentPath;

const selectSelector = (state: RootState) => state.history.selected;
const reportListSelector = (state: RootState) =>
  state.history.reportSyncLogState.reportSyncLog.content;
const accessLogListSelector = (state: RootState) =>
  state.history.accessLogState.accessLog.content;
const checkupListSelector = (state: RootState) =>
  state.history.checkupState.checkups;
const serCodeListSelector = (state: RootState) =>
  state.history.checkupState.serviceCodes;

const reportSelector = createSelector(
  selectSelector,
  reportListSelector,
  (id, reportListSelector) => {
    return reportListSelector.filter((r) => r.id === Number(id))[0];
  },
);

const accessLogSelector = createSelector(
  selectSelector,
  accessLogListSelector,
  (id, accessLogListSelector) => {
    return accessLogListSelector.filter((a) => a.id === Number(id))[0];
  },
);

const checkupSelector = createSelector(
  checkupListSelector,
  (checkupListSelector) => {
    return checkupListSelector.map(({ id, title, hasDetail, hasPdf }) => {
      return {
        id,
        title,
        hasDetail,
        hasPdf,
      };
    });
  },
);

const getChecksupById = createSelector(
  checkupListSelector,
  (state: RootState) => state.history.checkupState.selected,
  (checkupListSelector, id) =>
    checkupListSelector.filter((f) => f.id === id).pop(),
);

const serCodeSelector = createSelector(
  serCodeListSelector,
  (serCodeListSelector) => {
    return serCodeListSelector.map(({ id, code, description }) => {
      return {
        code,
        description,
        id,
      };
    });
  },
);

const getSerCodeById = createSelector(
  serCodeListSelector,
  (state: RootState) => state.history.checkupState.selectedSercode,
  (serCodeListSelector, id) =>
    serCodeListSelector.filter((f) => f.id === id).pop(),
);

export const historyActions = {
  currentPathSelector,
  reportSelector,
  accessLogSelector,
  checkupSelector,
  getChecksupById,
  requestReportCheckup,
  requestServiceCode,
  serCodeSelector,
  getSerCodeById,
  ...historySlice.actions,
};
export default historySlice.reducer;
