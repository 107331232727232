import React, { useCallback } from 'react';

import { useAppDispatch } from '../../app/hooks';
import { historyActions } from './historySlice';

function EditSercodeButton({ id }: any) {
  const dispatch = useAppDispatch();
  const onClick = useCallback(() => {
    dispatch(historyActions.setSercodeSelect(id));
  }, [dispatch, id]);

  return (
    <button
      onClick={onClick}
      style={{
        border: '1px solid #68AACF',
        borderRadius: 4,
        background: 'none',
        color: '#68AACF',
      }}
    >
      수정
    </button>
  );
}

export default EditSercodeButton;
