import { Theme, css } from '@emotion/react';

export const defaultTree = (theme: Theme) => css`
  height: 750px;
  width: 260px;
  cursor: pointer;
  .rstcustom__nodeContent {
    width: 100%;
  }
  .rstcustom__collapseButton,
  .rstcustom__expandButton {
    padding-left: 30px;
    color: #c4c4c4;
  }
  .rstcustom__parent .rstcustom__rowContents {
    background-color: ${theme.palette.grayscale[3]};
  }
  .rstcustom__rowTitle {
    ${theme.font.title2}
  }
  .selected {
    background-color: ${theme.palette.secondary};
  }
  .rstcustom__rowToolbar {
    z-index: 10;
    button {
      cursor: pointer;
      border: none;
      background: none;
      font-size: 12px;
    }
  }
`;
