import styled from '@emotion/styled';

export const DashboardTitle = styled.h4`
  margin: 0;
  padding: 0;
  line-height: 150%;
  ${(props) => props.theme.font.title1};
`;

export const CalendarBtn = styled.button`
  box-sizing: border-box;
  width: 70px;
  border: 1px solid
    ${(props) => {
      return props.theme.palette.grayscale[2];
    }};
  border-radius: 4px;
  background-color: ${(props) => {
    return props.theme.palette.light;
  }};
  padding: 6px 16px;
  ${(props) => props.theme.font.body};
  :hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.palette.grayscale[3]};
  }
  margin-right: 8px;
`;
export const DownBtn = styled.a`
  box-sizing: border-box;
  text-decoration: none;
  color: black;
  border: 1px solid
    ${(props) => {
      return props.theme.palette.grayscale[2];
    }};
  border-radius: 4px;
  background-color: ${(props) => {
    return props.theme.palette.light;
  }};
  padding: 6px 16px;
  ${(props) => props.theme.font.body};
  :hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.palette.grayscale[3]};
  }
  margin-right: 8px;
`;

export const CalendarSelector = styled.div`
  box-sizing: border-box;
  width: 320px;
  border: 1px solid
    ${(props) => {
      return props.theme.palette.grayscale[2];
    }};
  border-radius: 4px;
  background-color: ${(props) => {
    return props.theme.palette.light;
  }};
  padding: 6px 16px;
  display: flex;
  align-items: center;
  ${(props) => props.theme.font.body};
  margin-right: 8px;
`;

export const CalendarTitle = styled.span`
  ${(props) => props.theme.font.body};
  color: ${(props) => props.theme.palette.grayscale[0]};
  margin-right: 16px;
`;

export const CalendarRange = styled.span`
  ${(props) => props.theme.font.body};
  margin-right: 8px;
`;

export const CalendarIconDiv = styled.div`
  display: flex;
  align-items: flex-start;
  padding-bottom: 3px;
`;

export const DownloadText = styled.span`
  margin-right: 8px;
`;
