import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import {
  getMembers,
  getAuthorities,
  getInstitutions,
  memberActions,
} from '../../features/member/memberSlice';

import { initialNewsQuery } from '../../features/news/staticData/news';
import useTableRoutePath from '../../common/hooks/useTableRoutePath';
import {
  columns,
  Member as MemberType,
} from '../../features/member/staticData/member';
import Table from '../../components/CustomTable';
import { parseQuery } from '../../common/utils/routeUtils';
import { useLocation } from 'react-router-dom';
import MemberPopup from './MemberPopup';
import ResetPasswordPopup from '../../features/auth/ResetPasswordPopup';

import {
  Container,
  UpperContainer,
  SearchContainer,
  Button1,
  Input1,
  CreateContainer,
  Button2,
  LowerContainer,
} from '.';

function User() {
  const location = useLocation();
  const dispatch = useDispatch();

  const { members, authorities, institutions, selected, loading } = useSelector(
    (state: RootState) => state.member,
  );
  const [open, setOpen] = useState(false);
  const [passwordPopupOpen, setPasswordPopupOpen] = useState(false);
  const {
    query,
    handleChangePage,
    keyword,
    setKeyword,
    onSubmit,
  } = useTableRoutePath(initialNewsQuery);

  useEffect(() => {
    dispatch(getMembers(query));
    dispatch(getAuthorities());
    dispatch(getInstitutions());
  }, [dispatch, query]);

  const handleClickModifyButton = useCallback(
    (row: MemberType) => {
      dispatch(memberActions.setSelected(String(row.id)));

      setOpen(true);
    },
    [dispatch, setOpen],
  );

  const handleClickResetPassword = useCallback(
    (row: MemberType) => {
      dispatch(memberActions.setSelected(String(row.id)));
      setPasswordPopupOpen(true);
    },
    [dispatch, setPasswordPopupOpen],
  );

  useEffect(() => {
    setOpen(false);
  }, [members]);

  return (
    <Container>
      {passwordPopupOpen ? (
        <ResetPasswordPopup setOpen={setPasswordPopupOpen} readonly={true} />
      ) : null}
      {open ? (
        <MemberPopup
          institutions={institutions}
          authorities={authorities}
          selected={selected}
          query={query}
          open={open}
          setOpen={setOpen}
          loading={loading}
        />
      ) : null}
      <UpperContainer>
        <SearchContainer onSubmit={onSubmit}>
          <Input1
            type={'text'}
            value={keyword}
            placeholder="이메일 입력"
            onChange={(e) => setKeyword(e.target.value)}
          />
          <Button1 type="submit">검색</Button1>
        </SearchContainer>
        <CreateContainer>
          <Button2 onClick={() => setOpen(true)}>사용자 추가하기</Button2>
        </CreateContainer>
      </UpperContainer>
      <LowerContainer>
        <Table
          columns={columns.map((value) => {
            if (value.property === 'modify') {
              return {
                ...value,
                onClick: (row: MemberType) => {
                  handleClickModifyButton(row);
                },
              };
            } else if (value.property === 'resend') {
              return {
                ...value,
                onClick: (row: MemberType) => {
                  handleClickResetPassword(row);
                },
              };
            }
            return value;
          })}
          list={members}
          fetching={false}
          isPaging
          pageState={{
            page: Number(parseQuery(location.search).page || 0),
            rowsPerPage: 10,
          }}
          actions={{
            handleChangePage,
          }}
        />
      </LowerContainer>
    </Container>
  );
}

export default User;
