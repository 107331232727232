import apiModule, { ApiRequest } from '../../common/wrapper/axios';
import { RequestDto } from '../news/newsApi';
import { name } from './fileSlice';

export interface FileEntity {
  id: number;
  category: string;
  fileName: string;
  filePath: string;
  originalFileName: string;
  hash?: string;
}

export type FileRequestDto = RequestDto<any>;
export type FileRequestEntity = FileEntity[];

export const requestFiles: ApiRequest<FileRequestDto, FileEntity> = (
  params = { method: 'post' },
) => {
  const { method, id, data, headers } = params;

  let url = `${name}`;
  if (id) {
    url = `${url}/${id}`;
  }

  return apiModule.request({
    method,
    url,
    data,
    headers,
  });
};
