import React from 'react';

import ReportSearchForm from './ReportSearchForm';
import { reportActions } from './reportSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import MenuList from '../../components/MenuList';
import { RoutePathReturn } from '../../common/hooks/useRoutePath';
import Skeleton from '@material-ui/lab/Skeleton';
import { searchForm } from '../../common/styles/FormStyles';

function ReportList({ selected, routePath, currentPath }: RoutePathReturn) {
  const dispatch = useAppDispatch();
  const [reportList, loading] = useAppSelector((state) => [
    reportActions.searchReportSelector(state),
    state.reports.loading,
  ]);

  React.useEffect(() => {
    dispatch(reportActions.fetchReports());
  }, [dispatch]);

  const handleClick = React.useCallback(
    (publicKey) => {
      routePath(`${currentPath}?publicKey=${publicKey}`);
    },
    [routePath, currentPath],
  );

  return (
    <div style={{ width: '20%', paddingRight: 20 }}>
      <ReportSearchForm css={searchForm} />
      <div
        style={{
          padding: '16px 8px',
          textAlign: 'center',
          backgroundColor: '#EEEEEE',
          marginTop: 10,
          borderBottom: '1px solid rgb(221, 221, 221)',
        }}
      >
        확인코드
      </div>
      {loading ? (
        <div style={{ backgroundColor: '#F6F6F6', padding: 10, height: 360 }}>
          <Skeleton animation="wave" height={24} />
          <Skeleton animation="wave" height={24} />
          <Skeleton animation="wave" height={24} />
          <Skeleton animation="wave" height={24} />
          <Skeleton animation="wave" height={24} />
        </div>
      ) : (
        <MenuList
          menus={reportList}
          onClick={handleClick}
          selected={selected}
        />
      )}
    </div>
  );
}

export default React.memo(ReportList);
