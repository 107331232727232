import {
  CalendarBtn,
  CalendarIconDiv,
  CalendarSelector,
  CalendarTitle,
  DownBtn,
  DownloadText,
} from '../common/styles/dashboardStyles';
import {
  getBeforeDate,
  getDateReqFormat,
  getNowDate,
} from '../common/utils/dateUtils';
import { ReactComponent as CalendarIcon } from '../assets/calander.svg';
import { ReactComponent as DownloadIcon } from '../assets/icon.svg';
import React, { FC } from 'react';
import { RangeDatePicker } from '@y0c/react-datepicker';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
//TODO: SCSS 관련 수정 필요
// import '@y0c/react-datepicker/assets/styles/calendar.scss';
import './CalendarPicker.css';

interface CalendarPickerProps {
  startDate: Date;
  endDate: Date;
  statType?: any;
  setStartDate: any;
  setEndDate: any;
  chartRef?: any;
  isOnlyCalender?: boolean;
}

const CalendarPicker: FC<CalendarPickerProps> = ({
  statType,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  chartRef,
  isOnlyCalender = false,
}) => {
  const download = (e: any) => {
    if (chartRef.current != null) {
      var element = e.target;
      let current = chartRef.current;
      element.href = current.toBase64Image();
      element.download =
        statType + '_download_stat_' + getDateReqFormat(new Date()) + '.jpg';
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <CalendarSelector>
        <CalendarTitle>조회기간</CalendarTitle>
        <div>
          <RangeDatePicker
            key={startDate.toString()}
            locale={'ko'}
            initialStartDate={dayjs(startDate)}
            initialEndDate={dayjs(endDate)}
            dateFormat={'YYYY.MM.DD'}
            disableDay={(date: any) => dayjs(date) > dayjs()}
            onChange={(start: any, end: any) => {
              if (start !== undefined && end !== undefined) {
                setStartDate(start);
                setEndDate(end);
              }
            }}
          />
        </div>
        <CalendarIconDiv>
          <CalendarIcon width={18} height={18} />
        </CalendarIconDiv>
      </CalendarSelector>

      {!isOnlyCalender && (
        <>
          <CalendarBtn
            onClick={() => {
              setStartDate(getBeforeDate(0, 7));
              setEndDate(getNowDate());
            }}
          >
            1주일
          </CalendarBtn>
          <CalendarBtn
            onClick={() => {
              setStartDate(getBeforeDate(1, 0));
              setEndDate(getNowDate());
            }}
          >
            1개월
          </CalendarBtn>
          <CalendarBtn
            onClick={() => {
              setStartDate(getBeforeDate(3, 0));
              setEndDate(getNowDate());
            }}
          >
            3개월
          </CalendarBtn>
          <CalendarBtn
            onClick={() => {
              setStartDate(getBeforeDate(12, 0));
              setEndDate(getNowDate());
            }}
          >
            1년
          </CalendarBtn>
          <DownBtn onClick={download}>
            <DownloadText>다운로드</DownloadText>
            <DownloadIcon />
          </DownBtn>
        </>
      )}
    </div>
  );
};

export default CalendarPicker;
