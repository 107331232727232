import React, { useCallback } from 'react';
import AutoComplete from '../../components/AutoComplete';
import { useAppSelector } from '../../app/hooks';

function CheckupAutoComplete({ formik, valueType, defaultValue }: any) {
  // api data selector
  const serviceCodes = useAppSelector(
    (state) => state.history.checkupState.serviceCodes,
  );

  const handleChange = useCallback(
    (e, value) => {
      formik.setFieldValue(
        valueType,
        value.map((m: any) => m.code),
      );
    },
    [formik, valueType],
  );

  return (
    <AutoComplete
      optionLabel="code"
      label="서비스 코드"
      onChange={handleChange}
      defaultValue={defaultValue.map((m: string) => ({ code: m }))}
      options={serviceCodes}
    />
  );
}

export default CheckupAutoComplete;
