import React, { useEffect, useRef } from 'react';

export default function PDFViewer({ src, backend }: any) {
  const viewerRef = useRef(null);
  const backendRef = useRef(new backend());

  useEffect(() => {
    const element = viewerRef.current;
    backendRef.current.init(src, element);
  }, [src]);

  return (
    <div
      ref={viewerRef}
      id="viewer"
      style={{ width: '100%', height: '100%' }}
    ></div>
  );
}
