import React from 'react';
import InstitutionTable from '../../features/member/InstitutionTable';

function Institution() {
  return (
    <>
      <InstitutionTable />
    </>
  );
}

export default Institution;
