import React from 'react';
import Styled from '@emotion/styled';

interface NotiRenderComponentProps {
  imgPath: any;
  deviceName: string;
  title?: string;
  body?: string;
}

function NotiRenderComponent({
  imgPath,
  deviceName,
  title = TITLE_TEXT,
  body = BODY_TEXT,
}: NotiRenderComponentProps) {
  return (
    <NotiRendererContainer>
      <img src={imgPath} alt="" />
      <div className="message">
        <div className="title">{title.length > 0 ? title : TITLE_TEXT}</div>
        <div className="body">{body.length > 0 ? body : BODY_TEXT}</div>
      </div>
      <div className="device">
        <span>{deviceName}</span>
      </div>
    </NotiRendererContainer>
  );
}

export default NotiRenderComponent;

export const NotiRendererContainer = Styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  span {
    color: #A0A0A0;
  }
  .message {
    position: absolute;
    top: 50%;
    left: 18%;
    transform: translateY(-5px);
    text-align: left;
    width: 263px;
    .title {
      font-size: 14px;
      color: #212121;
      font-weight: 500;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .body {
      font-size: 11px;
      color: #828282;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
`;

const TITLE_TEXT = '알림 제목이 출력되는 위치입니다.';
const BODY_TEXT = '알림 메시지 내용이 출력되는 위치입니다.';
