/** @jsxImportSource @emotion/react */
import React from 'react';
import map from 'lodash/map';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { defaultTab, subTab } from '../../common/styles/TabStyles';
import { tabList } from './staticData/subDisease';

interface ControlTabProps {
  value: number;
  setValue: any;
}

function ControlTab({ value, setValue }: ControlTabProps) {
  return (
    <section css={[defaultTab, subTab]}>
      <Tabs value={value} onChange={(e, v) => setValue(v)}>
        {map(tabList, ({ id, label }) => {
          return <Tab key={id} label={label} value={id} />;
        })}
      </Tabs>
    </section>
  );
}

export default ControlTab;
