import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import menuReducer from '../features/menu/menuSlice';
import reportReducer, { name as report } from '../features/report/reportSlice';
import reportContentReducer from '../features/report/reportContetSlice';
import memberReducer from '../features/member/memberSlice';
import historyReducer from '../features/history/historySlice';
import pushReducer from '../features/push/pushSlice';
import diseaseReducer, {
  name as diseases,
} from '../features/disease/diseaseSlice';
import newsReducer, { name as news } from '../features/news/newsSlice';
import subDiseaseReducer, {
  name as subDisease,
} from '../features/disease/subDiseaseSlice';
import dashboardReducer, {
  name as dashboard,
} from '../features/dashboard/dashboardSlice';
import fileReducer, { name as file } from '../features/file/fileSlice';
import userReducer, { name as user } from '../features/user/userSlice';
import informationReducer from '../features/disease/informationSlice';
import genotypeReducer from '../features/disease/genotypeSlice';
import histogramReducer from '../features/disease/histogramSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    menu: menuReducer,
    [report]: reportReducer,
    reportContent: reportContentReducer,
    member: memberReducer,
    history: historyReducer,
    push: pushReducer,
    [diseases]: diseaseReducer,
    [news]: newsReducer,
    [subDisease]: subDiseaseReducer,
    [dashboard]: dashboardReducer,
    [file]: fileReducer,
    [user]: userReducer,
    information: informationReducer,
    genotype: genotypeReducer,
    histogram: histogramReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
