import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import {
  Button1,
  Button2,
  Container,
  CreateContainer,
  Input1,
  LowerContainer,
  SearchContainer,
  UpperContainer,
} from '../member';
import { getApiKey, removeToken } from '../../features/history/historySlice';
import PopUp from '../../components/PopUp';
import Table from '../../components/CustomTable';
import {
  ApiTokenType,
  columns,
  initialServerQuery,
} from '../../features/history/staticData/server';
import useTableRoutePath from '../../common/hooks/useTableRoutePath';
import { parseQuery } from '../../common/utils/routeUtils';
import { useLocation } from 'react-router-dom';
import { createApiToken } from '../../features/history/historySlice';

function Server() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { apiToken } = useSelector(
    (state: RootState) => state.history.apiTokenState,
  );
  const [isCreate, create] = useState(false);
  const [ipAddress, setIpAddress] = useState('');
  const [purpose, setPurpose] = useState('');
  const inputList = [
    {
      label: 'IP 주소',
      state: ipAddress,
      setState: (v: string) => setIpAddress(v),
    },
    {
      label: '사용목적',
      state: purpose,
      setState: (v: string) => setPurpose(v),
    },
  ];
  const {
    handleChangePage,
    query,
    onSubmit,
    keyword,
    setKeyword,
  } = useTableRoutePath(initialServerQuery);

  useEffect(() => {
    dispatch(getApiKey(query));
  }, [dispatch, query]);

  return (
    <Container>
      {isCreate ? (
        <PopUp
          inputList={inputList}
          title={'서버 추가하기'}
          onClose={() => {
            create(false);
          }}
          onClick={() => {
            create(false);
            dispatch(
              createApiToken({
                ipAddress: ipAddress,
                purpose: purpose,
              }),
            );
            dispatch(getApiKey(query));
          }}
        />
      ) : null}
      <UpperContainer>
        <SearchContainer onSubmit={onSubmit}>
          <Input1
            type={'text'}
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            placeholder={'IP 주소 입력'}
          />
          <Button1 type="submit">검색</Button1>
        </SearchContainer>
        <CreateContainer>
          <Button2 onClick={() => create(true)}>서버 추가하기</Button2>
        </CreateContainer>
      </UpperContainer>
      <LowerContainer>
        <Table
          columns={columns.map((value) => {
            if (value.property === 'delete') {
              return {
                ...value,
                onClick: (row: ApiTokenType) => {
                  dispatch(removeToken(row.id));
                  dispatch(getApiKey(query));
                },
              };
            }
            if (value.property === 'copy') {
              return {
                ...value,
                onClick: (row: ApiTokenType) => {
                  const element = document.createElement('textarea');
                  element.value = row.apiToken;
                  element.setAttribute('readonly', '');
                  element.style.position = 'absolute';
                  element.style.left = '-9999px';
                  document.body.appendChild(element);
                  element.select();
                  document.execCommand('copy');
                },
              };
            }
            return value;
          })}
          list={apiToken}
          fetching={false}
          isPaging
          pageState={{
            page: Number(parseQuery(location.search).page || 0),
            rowsPerPage: 10,
          }}
          actions={{
            handleChangePage,
          }}
        />
      </LowerContainer>
    </Container>
  );
}

export default Server;
