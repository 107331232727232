import { Theme, css } from '@emotion/react';
import styled from '@emotion/styled';
import { HEADER_HEIGHT } from './CommonStyles';

export const NavContainer = styled.nav`
  position: relative;
  height: ${HEADER_HEIGHT}px;
`;

export const defaultNav = () => css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  list-style: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  li {
    float: left;
  }
  a {
    text-decoration: none;
  }
`;

export const gnbNav = (theme: Theme) => css`
  ${theme.font.title1}
  left: 256px;
  li {
    margin-right: 40px;
  }
  a {
    color: ${theme.palette.light};
    opacity: ${theme.palette.opacityscale[0]};
    padding: 5px 0px;
  }
  .selected {
    opacity: ${theme.palette.opacityscale[1]};
    border-bottom: 2px solid ${theme.palette.primary2};
  }
`;

export const subNav = (theme: Theme) => css`
  ${theme.font.title2}
  li {
    margin-right: 16px;
  }
  a {
    color: ${theme.palette.dark};
    padding: 5px 0px;
  }
  .selected {
    ${theme.font.title1}
    border-bottom: 2px solid ${theme.palette.primary1};
  }
`;

export const menuStyle = (theme: Theme) => css`
  .MuiList-padding {
    padding: 0;
  }
  .selected {
    background-color: ${theme.palette.secondary};
  }
`;
