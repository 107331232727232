import React, { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { newsActions } from './newsSlice';
import Table from '../../components/CustomTable';
import { columns } from './staticData/news';

import { useLocation, useHistory } from 'react-router-dom';
import { parseQuery } from '../../common/utils/routeUtils';
import useTableRoutePath from '../../common/hooks/useTableRoutePath';
import { initialNewsQuery } from './staticData/news';
import {
  Button1,
  Container,
  Input1,
  LowerContainer,
  SearchContainer,
  UpperContainer,
  CreateContainer,
  Button2,
} from '../../pages/member';
import NewsDetail from './NewsDetail';
import NewsAdd from './NewsAdd';

function NewsList() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();
  const newsList = useAppSelector((state) => state.news.newsList);

  const {
    currentPath,
    query,
    queryToString,
    keyword,
    setKeyword,
    handleChangePage,
    onSubmit,
  } = useTableRoutePath(initialNewsQuery);

  useEffect(() => {
    dispatch(newsActions.setSelected(parseQuery(location.search).id));
  }, [dispatch, location.search]);

  useEffect(() => {
    //call api
    dispatch(
      newsActions.requestNews({
        method: 'get',
        query: {
          page: query.page,
          sort: query.sort,
          type: query.type,
          search: query.search,
          size: query.size,
        },
      }),
    );
  }, [dispatch, query.page, query.sort, query.search, query.size]);

  const handleRowClick = useCallback(
    (li) => {
      history.push(`${currentPath}?${queryToString}&id=${li.id}`);
    },
    [history, queryToString, currentPath],
  );

  const handleAddNews = useCallback(() => {
    history.push(`${currentPath}?m=add`);
  }, [history, currentPath]);

  if (parseQuery(location.search).m === 'add') {
    return <NewsAdd query={query} />;
  }

  return (
    <div>
      {parseQuery(location.search).id ? (
        <NewsDetail query={query} />
      ) : (
        <Container>
          <UpperContainer>
            <SearchContainer onSubmit={onSubmit}>
              <Input1
                type={'text'}
                value={keyword}
                placeholder="글 제목 입력"
                onChange={(e) => setKeyword(e.target.value)}
              />
              <Button1 type="submit">검색</Button1>
            </SearchContainer>
            <CreateContainer>
              <Button2 onClick={handleAddNews}>새 글 등록하기</Button2>
            </CreateContainer>
          </UpperContainer>
          <LowerContainer>
            <Table
              columns={columns}
              list={newsList}
              fetching={false}
              isPaging
              pageState={{
                page: Number(parseQuery(location.search).page || 0),
                rowsPerPage: 10,
              }}
              actions={{
                handleChangePage,
              }}
              handleClick={handleRowClick}
            />
          </LowerContainer>
        </Container>
      )}
    </div>
  );
}

export default React.memo(NewsList);
