import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { dashboardActions } from './dashboardSlice';
import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

interface LineChartProps {
  statType: 'reports' | 'app';
  startDate?: Date;
  endDate?: Date;
  options?: Chart.ChartOptions;
  chartRef?: any;
}

function LineChart({
  statType,
  startDate,
  endDate,
  options,
  chartRef,
}: LineChartProps) {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.dashboard[statType]);

  useEffect(() => {
    dispatch(
      dashboardActions.fetchStat({
        statType,
        startDate,
        endDate,
      }),
    );
  }, [dispatch, statType, startDate, endDate]);
  let customOptions: Chart.ChartOptions = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        display: false,
      },
    },
    backgroundColor: 'rgba(104,170,207,0.3)',
    scales: {
      y: {
        ticks: {
          stepSize: 1,
        },
      },
    },
    fill: true,
    ...options,
  };
  const chart = React.useMemo(() => {
    return (
      <div>
        <Line
          data={state?.data}
          ref={(ref) => {
            chartRef.current = ref;
          }}
          type="line"
          height={70}
          options={customOptions}
        />
      </div>
    );
  }, [state]);

  return <>{chart}</>;
}

export default React.memo(LineChart);
