import React from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { newsActions } from './newsSlice';
import NewsForm from './NewsForm';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';

function NewsDetail({ query }: any) {
  const dispatch = useAppDispatch();
  const news = useAppSelector((state) => newsActions.newsSelector(state));
  const history = useHistory();

  const handleRemove = React.useCallback(() => {
    dispatch(
      newsActions.requestNews({
        method: 'delete',
        id: news.id,
        query: query,
      }),
    );

    history.replace(`/service/news?${qs.stringify(query)}`);
  }, [news, dispatch, history, query]);

  const handleSubmit = React.useCallback(
    (v) => {
      dispatch(
        newsActions.requestNews({
          method: 'put',
          data: v,
          id: news.id,
          query,
        }),
      );
    },
    [dispatch, news, query],
  );

  return (
    <>
      {news && (
        <div>
          <div style={{ marginBottom: 20, float: 'right' }}>
            <NewsButton onClick={handleRemove}>삭제</NewsButton>
            <NewsButton onClick={() => history.goBack()}>목록가기</NewsButton>
          </div>
          <NewsForm data={news} onSubmit={handleSubmit} />
        </div>
      )}
    </>
  );
}

export default NewsDetail;

export const NewsButton = styled.button`
  margin-left: 10px;
  border: none;
  background: none;
  cursor: pointer;
`;
