import { login } from '../../../common/text/constants';
import { FieldProps } from '../../../components/SubmitFormWithFormik';

export const loginField: FieldProps[] = [
  {
    type: 'text',
    name: 'email',
    label: 'Email',
    placeholder: login.EMAIL_PLACEHOLDER,
    variant: 'outlined',
    autoFocus: true,
  },
  {
    type: 'password',
    name: 'password',
    label: 'Password',
    placeholder: login.PW_PLACEHOLDER,
    variant: 'outlined',
  },
];
