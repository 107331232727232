import apiModule, { ApiRequest } from '../../common/wrapper/axios';
import { Member } from './staticData/member';
import { stringify } from '../../common/utils/routeUtils';
import { TableEntity, TableQuery } from '../table/staticData/table';

export interface MemberSearchRequest {
  type?: string;
  search?: string;
  query?: TableQuery;
}

export interface Authority {
  role: string;
  id: string;
}

export interface Institution {
  id: string;
  institutionName: string;
}

export interface MemberDto {
  email: string;
  nickname: string;
  institutionId: string;
  phoneNumber: string;
  authorities: number[];
}

export interface ProfileDto {
  phoneNumber: string;
  password?: string;
}

export interface emailDto {
  email: string;
}

export interface RequestDto<D> {
  id?: number;
  data?: D;
  query?: TableQuery;
}

export type MemberTableEntity = TableEntity<Member>;
export type MemberRequestDto = RequestDto<MemberDto>;

export const getMembers: ApiRequest<{}, MemberTableEntity> = (params) => {
  return apiModule.get(
    `/members?${stringify({
      ...params,
      type: 'email',
    })}`,
  );
};

export const getAuthorities: ApiRequest<{}, Authority[]> = () => {
  return apiModule.get('/authorities');
};

export const getInstitutions: ApiRequest<{}, Institution[]> = () => {
  return apiModule.get('/institutions');
};

export const requestInstitutions: ApiRequest<any, Institution[]> = (params) => {
  const { data } = params;

  // FIXME: query가 string일수도..

  return apiModule.request({
    method: 'put',
    url: '/institutions',
    data,
  });
};

export const postMember: ApiRequest<MemberRequestDto, Member> = (params) => {
  return apiModule.post(`/members`, params!.data);
};

export const updateMember: ApiRequest<MemberRequestDto, Member> = (params) => {
  return apiModule.post(`/members/${params?.id}`, params!.data);
};

export const updateProfile: ApiRequest<RequestDto<ProfileDto>, Member> = (
  params,
) => {
  return apiModule.post(`/profiles/${params?.id}`, params!.data);
};

export const InitPassword: ApiRequest<emailDto, void> = (params) => {
  return apiModule.post(`/members/init-password`, params);
};

export const deleteMember: ApiRequest<MemberRequestDto, Member> = (params) => {
  return apiModule.post(`/members/${params?.id}/delete-account`);
};
