import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as genotypeApi from './genotypeApi';
import { RootState } from '../../app/store';

export const name = 'genotype';

interface HistogramState {
  genotypes: genotypeApi.GenotypeEntity;
  last: any;
  selected: string | string[];
  loading: boolean;
  error: string | undefined;
}
const initialState: HistogramState = {
  genotypes: [],
  last: null,
  selected: '',
  loading: false,
  error: '',
};

const requestGenoType = createAsyncThunk<
  genotypeApi.GenotypeEntity,
  genotypeApi.GenotypeReqestDto,
  { state: RootState }
>(`${name}/requestGenotype`, async (params, { rejectWithValue, getState }) => {
  const id = Number(getState()['sub-diseases'].selected);
  try {
    const { data } = await genotypeApi.requestGenotype({
      ...params,
      id,
    });
    return data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

const genotypeSlice = createSlice({
  name,
  initialState,
  reducers: {
    addRow(state) {
      let newVar = {
        id: state.genotypes.length === 0 ? 0 : ++state.last.id,
        geneName: '',
        pos: 0,
        isPv: false,
        alleleInfo: '',
        snpId: '',
      };
      state.last = newVar;
      state.genotypes.push(newVar);
    },
    setRow(state, action) {
      state.genotypes = action.payload;
    },
    removeRow(state, action) {
      state.genotypes = state.genotypes.filter((g) => {
        return g.id !== action.payload;
      });
    },
    resetHistogram(state) {
      state.genotypes = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestGenoType.pending, (state) => {
        state.loading = true;
      })
      .addCase(requestGenoType.fulfilled, (state, action) => {
        state.loading = false;
        state.genotypes = action.payload;
        state.last = action.payload[action.payload.length - 1];
      })
      .addCase(requestGenoType.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const genotypeActions = {
  requestGenoType,
  ...genotypeSlice.actions,
};
export default genotypeSlice.reducer;
