/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect } from 'react';
import SortableTree, {
  addNodeUnderParent,
  changeNodeAtPath,
  removeNodeAtPath,
} from 'react-sortable-tree';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';

import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import FileExplorerTheme from 'react-sortable-tree-theme-minimal';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { SubDiseaseActions } from '../subDiseaseSlice';
import { defaultTree } from '../../../common/styles/TreeStyles';
import { diseaseActions } from '../diseaseSlice';
import styled from '@emotion/styled';

function Tree({ selected, setSelected }) {
  const dispatch = useAppDispatch();
  const [treeData, isDiff] = useAppSelector((state) => [
    state.diseases.treeData,
    diseaseActions.compareTreeData(state),
  ]);
  const { user } = useAppSelector((state) => state.user);
  const getNodeKey = ({ treeIndex }) => treeIndex;
  useEffect(() => {
    dispatch(diseaseActions.requestDiseases());
  }, [dispatch]);

  const handleChange = useCallback(
    (treeData) => {
      dispatch(diseaseActions.setTreeData(treeData));
    },
    [dispatch],
  );
  const handleSetSelected = useCallback(
    (subDiseaseId) => {
      dispatch(
        SubDiseaseActions.setSubDisease(
          selected === subDiseaseId ? '' : subDiseaseId,
        ),
      );
      setSelected(selected === subDiseaseId ? '' : subDiseaseId);
    },
    [selected, setSelected, dispatch],
  );

  const handleChangeNode = useCallback(
    (node, path, params) => {
      dispatch(
        diseaseActions.setTreeData(
          changeNodeAtPath({
            treeData: treeData,
            path,
            getNodeKey,
            newNode: { ...node, ...params },
          }),
        ),
      );
    },
    [dispatch, treeData],
  );
  const handleAddChildren = useCallback(
    (path) => {
      dispatch(
        diseaseActions.setTreeData(
          addNodeUnderParent({
            treeData,
            parentKey: path[path.length - 1],
            expandParent: true,
            getNodeKey,
            newNode: {
              title: '',
              isEdit: true,
            },
            addAsFirstChild: false,
          }).treeData,
        ),
      );
    },
    [dispatch, treeData],
  );
  const handleRemoveNode = useCallback(
    (path) => {
      dispatch(
        diseaseActions.setTreeData(
          removeNodeAtPath({
            treeData,
            path,
            getNodeKey,
          }),
        ),
      );
    },
    [dispatch, treeData],
  );
  const handleGenerateNodeProps = useCallback(
    ({ node, parentNode, path }) => {
      const title = node.isEdit ? (
        <div style={{ display: 'flex' }}>
          <NoneInput
            value={node.title}
            onChange={(e) => {
              const title = e.target.value;
              handleChangeNode(node, path, { title });
            }}
          />
          <NoneButton
            onClick={(e) => {
              // e.preventDefault();
              e.stopPropagation();
              handleChangeNode(node, path, { isEdit: false });
            }}
          >
            <DoneIcon />
          </NoneButton>
        </div>
      ) : parentNode ? (
        node.title
      ) : (
        `${node.title}`
      );

      return {
        title: parentNode ? (
          <TreeNode
            onClick={() => handleSetSelected(node.subDiseaseId)}
            className={selected === node.subDiseaseId ? 'selected' : ''}
          >
            {title}
          </TreeNode>
        ) : (
          title
        ),
        buttons:
          user.authority === 'ADMIN'
            ? parentNode
              ? !node.isEdit
                ? [
                    <button
                      onClick={() =>
                        handleChangeNode(node, path, { isEdit: true })
                      }
                    >
                      <EditIcon />
                    </button>,
                    <button onClick={() => handleRemoveNode(path)}>
                      <HighlightOffIcon />
                    </button>,
                  ]
                : []
              : [
                  <button onClick={() => handleAddChildren(path)}>
                    <AddIcon />
                  </button>,
                  <button
                    onClick={() =>
                      handleChangeNode(node, path, { isEdit: true })
                    }
                  >
                    <EditIcon />
                  </button>,
                  <button onClick={() => handleRemoveNode(path)}>
                    <HighlightOffIcon />
                  </button>,
                ]
            : [],
        className: parentNode ? 'rstcustom__child' : 'rstcustom__parent',
      };
    },
    [
      selected,
      handleSetSelected,
      handleChangeNode,
      handleAddChildren,
      handleRemoveNode,
      user.authority,
    ],
  );
  const handleCanDrop = useCallback(() => {
    return false;
    // return node.nextPath.length >= node.prevPath.length
    //   ? (node.node.children || []).length <= 0 && !node.node.isSub
    //     ? node.nextPath.length <= node.prevPath.length
    //     : true
    //   : false;
  }, []);

  const handleSave = useCallback(() => {
    dispatch(diseaseActions.changeOrder(treeData));
  }, [dispatch, treeData]);

  const handleAddMore = useCallback(() => {
    dispatch(diseaseActions.addTreeData());
  }, [dispatch]);

  return (
    <div>
      {user.authority === 'ADMIN' && (
        <>
          <TButton onClick={handleAddMore}>대분류 추가</TButton>
          {isDiff && (
            <TButton onClick={handleSave} style={{ float: 'right' }}>
              변경사항 저장
            </TButton>
          )}
        </>
      )}
      <div css={[defaultTree]}>
        {treeData.length > 0 && (
          <SortableTree
            treeData={treeData}
            onChange={handleChange}
            theme={FileExplorerTheme}
            maxDepth={2}
            rowHeight={40}
            scaffoldBlockPxWidth={1}
            canDrop={handleCanDrop}
            generateNodeProps={handleGenerateNodeProps}
          />
        )}
      </div>
    </div>
  );
}

export default React.memo(Tree);

const TreeNode = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding-left: 20px;
`;

const TButton = styled.button`
  border: 1px solid #68aacf;
  box-sizing: border-box;
  border-radius: 4px;
  background: none;
  color: #68aacf;
  height: 36px;
  margin-bottom: 10px;
  cursor: pointer;
`;

export const NoneButton = styled.button`
  background: none;
  border: none;
  font-size: 12px;
  cursor: pointer;
  padding: 4px 10px;
  border-radius: 4px;
  margin-left: 5px;
`;

const NoneInput = styled.input`
  border: 2px solid #000;
  line-height: 20px;
`;
