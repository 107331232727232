import DashBoard from './dashboard';
import Reports from './reports';
import Service, { service } from './service';
import Member, { member } from './member';
import History, { history } from './history';

export const home = DashBoard;
const pages = [DashBoard, Reports, Service, Member, History];
export const subPages = {
  history,
  service,
  member,
};
export default pages;
