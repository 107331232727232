import styled from '@emotion/styled';

export const HEADER_HEIGHT = 64;

export const Header = styled.div`
  width: 100%;
  height: ${HEADER_HEIGHT}px;
  background-color: ${(props) => props.theme.palette.primary1};
`;

export const AbsoluteContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  .loginContainer {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Footer = styled.div`
  ${(props) => props.theme.font.body}
  width: 100%;
  height: 32px;
  background-color: ${(props) => props.theme.palette.light};
  bottom: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
`;
