import apiModule, { ApiRequest } from '../../common/wrapper/axios';
import { name } from './reportSlice';

export interface ReportDto {
  publicKey: string | string[];
}

export interface genotypes {
  id: number;
  geneName: string;
  type: '표준형' | '증가형';
  value: string;
  alleleCount: number;
  pos: number;
}

export interface histogramType {
  id?: number;
  riskLevel: number;
  resultValue: number;
  isMean: boolean;
}
export interface genotypeType {
  id?: number;
  geneName: string;
  pos: number;
  snpId: string;
  isPv: boolean;
  alleleInfo: any;
}

export interface SubDiseaseEntity {
  id: number;
  name: string;
  summary: string;
  risk: string;
  advice: string;
  target: string;
  period: string;
  source: string;
  test: string;
  genotypes: genotypeType[];
  histograms: histogramType[];
  detail: string;
  maxAllele: number;
  top5: number;
  meanKorean: number;
}

export interface SubDiseaseMetaEntity {
  id: number;
  summary: string;
  score: number;
  serviceCode: string;
  subDisease: SubDiseaseEntity;
  genotypes: genotypes[];
}

export interface DiseaseEntity {
  id: number;
  diseaseName: string;
  subDiseases: SubDiseaseMetaEntity[];
}

export interface CheckupEntity {
  description: string;
  hasDetail: boolean;
  hasPdf: boolean;
  id: number;
  summary: string;
  title: string;
  serviceCodes: string[];
}
export interface ReportEntity {
  reportId: number;
  publicKey: string;
  sex: 'M' | 'F';
  birthDate: string;
  requestNumber: string; // FIXME: 검사명 대체
  collectionDate: string;
  reportDate: string;
  receiptDate: string;
  medicalInstitution: string;
  diseases?: DiseaseEntity[];
  patientName: string;
  checkup: CheckupEntity;
}

export const fetchReports: ApiRequest<null, ReportEntity[]> = () => {
  return apiModule.get(`${name}`);
};

export const fetchReportByPublicKey: ApiRequest<ReportDto, ReportEntity> = (
  params,
) => {
  return apiModule.get(`${name}/${params?.publicKey}`);
};
