import React from 'react';
import styled from '@emotion/styled';
import SelectBox from './SelectBox';
import { SelectBox as SelectBoxType } from '../features/member/staticData/member';
import CloseIcon from '@material-ui/icons/Close';
interface PopUpProps {
  onClick?: any;
  onClose: any;
  title: string;
  inputList?: InputProps[];
  children?: any;
  buttonTitle?: string;
  component?: any;
  bottomComponent?: any;
  loading?: boolean;
}

export interface InputProps {
  label?: string;
  state?: string;
  setState?: any;
  selectValues?: SelectBoxType[];
  property?: string;
  type?: 'input' | 'text' | 'selectBox' | 'password' | string;
  inputType?: 'email' | 'password' | 'number' | string;
  placeholder?: string;
  explain?: string;
  readonly?: boolean;
}

export function PopupHorizontalText({ title, defaultValue }: any) {
  return (
    <>
      <PopupInputContainer>
        <PopupInputLabel>{title}</PopupInputLabel>
        <div
          style={{
            width: 320,
            textAlign: 'left',
            overflow: 'hidden',
            wordBreak: 'break-word',
          }}
        >
          {defaultValue}
        </div>
      </PopupInputContainer>
      <div style={{ height: 2 }}></div>
    </>
  );
}

export function PopupHorizontalSelectBox({ name, value }: any) {
  return (
    <PopupInputContainer>
      <PopupInputLabel>{value.label}</PopupInputLabel>
      <PopupSelectBoxContainer>
        <SelectBox
          name={name}
          values={value.selectValues}
          onChange={(e) => value.setState(e.target.value)}
          defaultValue={value.state}
          maxLength={50}
        />
      </PopupSelectBoxContainer>
    </PopupInputContainer>
  );
}

export function PopupHorizontalInput({
  title,
  onChange,
  defaultValue,
  placeholder,
  readonly,
  type,
}: any) {
  return (
    <PopupInputContainer>
      <PopupInputLabel>{title}</PopupInputLabel>
      <div>
        <PopupInput
          placeholder={placeholder}
          onChange={(v) => {
            onChange(v.target.value);
          }}
          defaultValue={defaultValue}
          readOnly={readonly}
          type={type}
        ></PopupInput>
      </div>
    </PopupInputContainer>
  );
}

function PopUp({
  onClick,
  title,
  inputList,
  onClose,
  buttonTitle,
  children,
  component,
  loading,
  bottomComponent,
}: PopUpProps) {
  const PopUpBodyInputs = (value: InputProps, index: number) => {
    if (value.type === 'text') {
      return (
        <PopupHorizontalText
          key={index}
          title={value.label}
          defaultValue={value.state}
        />
      );
    }

    if (value.selectValues) {
      return (
        <PopupHorizontalSelectBox key={index} name={'auth'} value={value} />
      );
    }
    return (
      <div key={index}>
        <PopupHorizontalInput
          onChange={(state: string) => value.setState(state)}
          // key={index}
          title={value.label}
          defaultValue={value.state}
          placeholder={value.placeholder}
          readonly={value.readonly}
          type={value.inputType!}
        />
        <div>{value.explain}</div>
      </div>
    );
  };

  return (
    <PopupContainer>
      <Popup>
        <PopupHeader>
          <PopupHeaderTitle>{title}</PopupHeaderTitle>
          <PopupClose
            onClick={() => {
              onClose();
            }}
          >
            <CloseIcon />
          </PopupClose>
        </PopupHeader>
        <PopupBody>
          {inputList ? (
            <>
              {component}
              <form onSubmit={onClick}>
                {inputList.map((value, index) => PopUpBodyInputs(value, index))}
                <PopupButton type="submit">
                  {loading ? 'Loading...' : buttonTitle ? buttonTitle : title}
                </PopupButton>
              </form>
            </>
          ) : (
            <>{children}</>
          )}
          {bottomComponent}
        </PopupBody>
      </Popup>
    </PopupContainer>
  );
}

export default PopUp;

const PopupContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;
const Popup = styled.div`
  width: 628px;
  background-color: white;
  max-height: 800px;
  overflow-y: auto;
  overflow-x: hidden;
`;
const PopupHeader = styled.div`
  width: 100%;
  height: 40px;
  background-color: ${(props) => props.theme.palette.primary1};
  color: ${(props) => props.theme.palette.light};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const PopupClose = styled.div`
  :hover {
    cursor: pointer;
  }
  margin-right: 10px;
`;
const PopupBody = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.palette.light};
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: space-between;
  flex-direction: column;
  margin: 30px 0px;
`;
const PopupInputContainer = styled.div`
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  width: 100%;
`;
const PopupInput = styled.input`
  width: 304px;
  height: 40px;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 4px;
  padding-left: 16px;
  ::placeholder {
    color: #c4c4c4;
    font-weight: 400;
  }
`;

const PopupInputLabel = styled.div`
  ${(props) => {
    return props.theme.font.subtitle;
  }}
  width: 112px;
`;

const PopupSelectBoxContainer = styled.div`
  width: 304px;
  height: 40px;
  //background-color: red;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
`;

const PopupHeaderTitle = styled.div`
  ${(props) => {
    return props.theme.font.title2;
  }}
  margin-left: 20px;
`;
const PopupButton = styled.button`
  all: unset;
  width: 412px;
  height: 56px;
  background-color: ${(props) => props.theme.palette.primary2};
  color: ${(props) => props.theme.palette.light};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.82vh 0;
  :hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.palette.primary2};
    opacity: 0.9;
  }
  border-radius: 4px;
  ${(props) => props.theme.font.button}
`;
