import { FieldProps } from '../../../components/SubmitFormWithFormik';
import { Column } from 'react-data-grid';

export const pushField: FieldProps[] = [
  {
    type: 'text',
    name: 'targetToken',
    label: '타겟 ID',
    placeholder: '타겟 ID를 입력하세요',
    variant: 'outlined',
    required: true,
  },
  {
    type: 'text',
    name: 'title',
    label: '제목',
    placeholder: '알림제목을 입력하세요',
    variant: 'outlined',
    required: true,
  },
  {
    type: 'text',
    name: 'body',
    label: '메시지',
    placeholder: '알림메시지를 입력하세요',
    variant: 'outlined',
    multiline: true,
    rows: 5,
    required: true,
  },
];

export const pushColumns: Column<any>[] = [
  {
    name: '타겟 ID',
    key: 'targetToken',
    headerCellClass: 'cell-center',
  },
  {
    name: '제목',
    key: 'title',
    headerCellClass: 'cell-center',
  },
  {
    name: '메시지',
    key: 'body',
    headerCellClass: 'cell-center',
  },
  {
    name: '트리거 액션',
    key: 'action',
    headerCellClass: 'cell-center',
  },
  {
    name: '트리거 타입',
    key: 'type',
    headerCellClass: 'cell-center',
  },
];
