import { Column } from 'react-data-grid';
import EditSercodeButton from '../EditSercodeButton';
import { FieldProps } from '../../../components/SubmitFormWithFormik';

export const columns: Column<any>[] = [
  {
    name: '서비스 코드',
    key: 'code',
    headerCellClass: 'cell-center',
    width: 200,
  },
  {
    name: '설명',
    key: 'description',
    headerCellClass: 'cell-center',
  },
  {
    name: '수정',
    key: 'action',
    headerCellClass: 'cell-center',
    cellClass: 'cell-center',
    formatter({ row }) {
      return <EditSercodeButton id={row.id} />;
    },
    width: 50,
  },
];

export const serCodeFields: FieldProps[] = [
  {
    type: 'text',
    name: 'code',
    variant: 'outlined',
    label: '서비스 코드',
    placeholder: '서비스 코드를 입력하세요',
  },
  {
    type: 'text',
    name: 'description',
    label: '설명',
    placeholder: '설명을 입력하세요',
    variant: 'outlined',
    multiline: true,
    rows: 4,
  },
];
