import map from 'lodash/map';
import { RouteProps } from 'react-router-dom';
import { originMenu } from '../../features/menu/staticData/menu';
import pages, { subPages } from '../../pages';

type SubPageType = keyof typeof subPages;

type SubPathType<T extends SubPageType> = T extends 'service'
  ? keyof typeof subPages['service']
  : T extends 'member'
  ? keyof typeof subPages['member']
  : keyof typeof subPages['history'];

export interface RoutePropsTypes extends RouteProps {
  routes?: RouteConfigWithSubRoute[];
}

export interface RouteConfig {
  path: string;
  component: (props: RoutePropsTypes) => React.ReactElement;
}

export interface RouteConfigWithSubRoute extends RouteConfig {
  routes?: RouteConfig[];
}

export const routes: RouteConfigWithSubRoute[] = map(
  originMenu,
  ({ l, s }, i) => ({
    path: `/${l}`,
    component: pages[i],
    routes: map(s, (subPath) => {
      if (l === 'service')
        return {
          path: `/${l}/${subPath}`,
          component: subPages['service'][subPath as SubPathType<'service'>],
        };
      if (l === 'member')
        return {
          path: `/${l}/${subPath}`,
          component: subPages['member'][subPath as SubPathType<'member'>],
        };
      else
        return {
          path: `/${l}/${subPath}`,
          component: subPages['history'][subPath as SubPathType<'history'>],
        };
    }),
  }),
);
