import React from 'react';
import DiseaseConatiner from '../../features/disease/Disease';

function Disease() {
  return (
    <div style={{}}>
      <DiseaseConatiner />
    </div>
  );
}

export default Disease;
