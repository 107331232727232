import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import * as histogramApi from './histogramApi';
import { RootState } from '../../app/store';

export const name = 'histogram';

interface HistogramState {
  histograms: histogramApi.HistogramEntity;
  last: any;
  selected: string | string[];
  loading: boolean;
  error: string | undefined;
}

const initialState: HistogramState = {
  histograms: [],
  last: null,
  selected: '',
  loading: false,
  error: '',
};

const requestHistogram = createAsyncThunk<
  histogramApi.HistogramEntity,
  histogramApi.HistogramReqestDto,
  { state: RootState }
>(`${name}/requestHistogram`, async (params, { rejectWithValue, getState }) => {
  const id = Number(getState()['sub-diseases'].selected);
  try {
    const { data } = await histogramApi.requestHistogram({
      ...params,
      id,
    });
    return data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

const histogramSlice = createSlice({
  name,
  initialState,
  reducers: {
    addRow(state) {
      let newVar = {
        id: state.histograms.length == 0 ? 0 : ++state.last.id,
        resultValue: 0,
        riskLevel: 0,
        isMean: false,
      };
      state.last = newVar;
      state.histograms.push(newVar);
    },
    setRow(state, action) {
      state.histograms = action.payload;
    },
    removeRow(state, action) {
      state.histograms = state.histograms.filter((h) => {
        return h.id !== action.payload;
      });
    },
    resetHistogram(state) {
      state.histograms = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestHistogram.pending, (state) => {
        state.loading = true;
      })
      .addCase(requestHistogram.fulfilled, (state, action) => {
        state.loading = false;
        state.histograms = action.payload;
        state.last = action.payload[action.payload.length - 1];
      })
      .addCase(requestHistogram.rejected, (state) => {
        state.loading = false;
      });
  },
});

const histogramSelector = (state: RootState) => state.histogram.histograms;
const getHistogram = createSelector(histogramSelector, (histogram) => {
  const resultValue = histogram?.map((h) => h.resultValue) || [];
  const riskLevel = histogram?.map((h) => h.riskLevel) || [];

  return {
    height: 100,
    data: {
      labels: resultValue,
      datasets: [
        {
          label: '유전형 분포',
          data: histogram?.map((h) => h.riskLevel),
          borderWidth: 2,
          barPercentage: 0.5,
        },
        // {
        //   label: '한국인 평균 값',
        //   data: histogram?.map((h) => (h.isMean ? h.riskLevel : 0)) || [],
        //   backgroundColor: ['#FFDC61'],
        //   pointStyle: 'rect',
        //   borderColor: '#FFA012',
        //   borderWidth: 2,
        //   barPercentage: 0.5,
        // },
      ],
    },
    options: {
      scales: {
        y: {
          stacked: false,
          ticks: {
            beginAtZero: true,
          },
          max: Math.max(...riskLevel) + 5,
        },
        x: { stacked: true },
      },
      plugins: {
        legend: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
        datalabels: {
          display: false,
        },
      },
    },
  };
});

export const histogramActions = {
  requestHistogram,
  getHistogram,
  ...histogramSlice.actions,
};
export default histogramSlice.reducer;
