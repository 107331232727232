/** @jsxImportSource @emotion/react */
import React from 'react';
import TextField from '@material-ui/core/TextField';

import { reportActions } from './reportSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

interface ReportsearchFormProps {
  css?: any;
}

function ReportSearchForm({ css }: ReportsearchFormProps) {
  const dispatch = useAppDispatch();
  const [keyword] = useAppSelector((state) => [
    reportActions.keywordSelector(state),
  ]);
  return (
    <TextField
      label="Search"
      variant="outlined"
      placeholder="확인코드 입력"
      value={keyword}
      css={[css]}
      onChange={(e) =>
        dispatch(reportActions.handleKeywordChange(e.target.value))
      }
    />
  );
}

export default React.memo(ReportSearchForm);
