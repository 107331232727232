import { useEffect, useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import localStorage from '../wrapper/localStorage';
import { setAuthTokenAndHeader } from '../../features/auth/authService';

function useLocalStorageToken() {
  const { isAuth } = useAppSelector((state) => state.auth);
  const [state, setState] = useState(isAuth);

  useEffect(() => {
    const token = localStorage.get('auth');

    // FIXME: 좀 더 수정..
    if (token) {
      setAuthTokenAndHeader(token);
    }

    setState(isAuth ? isAuth : !!token);
  }, [isAuth]);

  return [state];
}

export default useLocalStorageToken;
