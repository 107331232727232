import * as React from 'react';
import MuiAutoCompelete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

function AutoComplete({
  options = [],
  optionLabel,
  label,
  defaultValue = [],
  onChange,
}: any) {
  return (
    <MuiAutoCompelete
      multiple
      limitTags={2}
      options={options}
      getOptionLabel={(option) => option[optionLabel]}
      defaultValue={defaultValue}
      renderInput={(params) => (
        <TextField {...params} label={label} variant="outlined" />
      )}
      onChange={onChange}
      getOptionSelected={(option, value) =>
        option[optionLabel] === value[optionLabel]
      }
    />
  );
}

export default AutoComplete;
