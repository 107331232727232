import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';

import { authActions } from './authSlice';
import { loginField } from './staticData/loginField';

import { login } from '../../common/text/constants';
import LoginForm from '../../components/SubmitFormWithFormik';

import logo from '../../assets/gc_logo_color.png';
import ResetPasswordPopup from './ResetPasswordPopup';

function Login() {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required('required'),
      password: Yup.string().required('required'),
    }),
    onSubmit: (v) => {
      dispatch(authActions.doLogin(v));
    },
  });

  return (
    <div>
      <div
        style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}
      >
        <img src={logo} alt="" />
      </div>
      <LoginForm formik={formik} formData={loginField}>
        <button type="submit">{login.SUBMIT_TITLE}</button>
      </LoginForm>
      <div
        style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}
      >
        <button
          type="button"
          onClick={() => setOpen(true)}
          style={{ all: 'unset', marginTop: 20, cursor: 'pointer' }}
        >
          <span
            style={{
              fontSize: 14,
              textDecoration: 'underline',
              fontWeight: 600,
            }}
          >
            비밀번호 찾기
          </span>
        </button>
      </div>
      {open ? <ResetPasswordPopup setOpen={setOpen} /> : null}
    </div>
  );
}

export default Login;
