import React, { useCallback, useEffect, useState } from 'react';
import PopUp from '../../components/PopUp';
import { SelectBox } from '../../features/member/staticData/member';
import { useAppSelector } from '../../app/hooks';
import {
  postMember,
  memberActions,
  updateMember,
  deleteMember,
} from '../../features/member/memberSlice';
import { useDispatch } from 'react-redux';
import { TableQuery } from '../../features/table/staticData/table';

type MemberPopType = {
  institutions: SelectBox[];
  authorities: SelectBox[];
  selected: string | string[];
  query: TableQuery;
  open: boolean;
  loading: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

function MemberPopup({
  selected,
  institutions,
  authorities,
  query,
  open,
  loading,
  setOpen,
}: MemberPopType) {
  const dispatch = useDispatch();

  const member = useAppSelector((state) => memberActions.memberSelector(state));

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [institution, setInstitution] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [authority, setAuthority] = useState('');

  const memberInputList = [
    {
      label: '이메일',
      state: email,
      setState: setEmail,
      property: 'email',
      inputType: 'email',
      placeholder: '메일 입력',
    },
    {
      label: '이름',
      state: name,
      setState: setName,
      property: 'nickname',
      placeholder: '이름 입력',
    },
    {
      label: '소속기관',
      state: institution,
      setState: setInstitution,
      selectValues: institutions,
      property: 'institution',
    },
    {
      label: '전화번호',
      state: phoneNumber,
      setState: setPhoneNumber,
      property: 'phoneNumber',
      inputType: 'number',
      placeholder: '번호 입력',
    },
    {
      label: '권한',
      state: authority,
      setState: setAuthority,
      selectValues: authorities,
      property: 'authority',
    },
  ];

  useEffect(() => {
    if (selected === '' && authorities.length > 0) {
      setAuthority(authorities[0].value);
    }
  }, [selected, authorities]);

  useEffect(() => {
    if (selected === '' && institutions.length > 0) {
      setInstitution(institutions[0].value);
    }
  }, [selected, institutions]);

  useEffect(() => {
    if (selected !== '') {
      const authValue: SelectBox = authorities.filter((v: SelectBox) => {
        return v.label === member.authority;
      })[0];

      const institutionValue: SelectBox = institutions.filter(
        (v: SelectBox) => {
          return v.label === member.institution;
        },
      )[0];

      setEmail(member.email);
      setName(member.nickname);
      setAuthority(authValue.value);
      setInstitution(institutionValue.value);
      setPhoneNumber(member.phoneNumber);
    }
  }, [
    setEmail,
    setName,
    setInstitution,
    setPhoneNumber,
    setAuthority,
    member,
    institutions,
    authorities,
    selected,
  ]);

  const handleClickPopupButton = useCallback(
    (e) => {
      const requestDto = {
        data: {
          email: email,
          nickname: name,
          institutionId: institution,
          phoneNumber: phoneNumber,
          authorities: [Number(authority)],
        },
        query: query,
      };

      e.preventDefault();

      if (selected === '') {
        dispatch(
          postMember({
            ...requestDto,
          }),
        );
      } else {
        dispatch(
          updateMember({
            ...requestDto,
            id: Number(selected),
          }),
        );
      }
    },
    [
      email,
      name,
      institution,
      phoneNumber,
      authority,
      dispatch,
      query,
      selected,
    ],
  );

  const handelClosePopup = useCallback(() => {
    dispatch(memberActions.resetSelected());
    setOpen(false);
  }, [dispatch, setOpen]);

  const handleDeleteMember = useCallback(() => {
    if (selected !== '') dispatch(deleteMember({ id: Number(selected) }));
    setOpen(false);
  }, [dispatch, selected, setOpen]);

  return (
    <PopUp
      onClose={handelClosePopup}
      inputList={memberInputList}
      title={selected === '' ? '사용자 추가하기' : '사용자 수정하기'}
      onClick={handleClickPopupButton}
      loading={loading}
      bottomComponent={
        selected !== '' && (
          <button
            type="button"
            onClick={handleDeleteMember}
            style={{ all: 'unset', marginTop: 20, cursor: 'pointer' }}
          >
            <span
              style={{
                fontSize: 16,
                textDecoration: 'underline',
                color: '#cfcfcf',
              }}
            >
              사용자 삭제하기
            </span>
          </button>
        )
      }
    />
  );
}

export default MemberPopup;
