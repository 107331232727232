import apiModule, { ApiRequest } from '../../common/wrapper/axios';
import { RequestDto } from '../news/newsApi';
import { name } from './subDiseaseSlice';
import { histogramType } from '../report/reportApi';
import { FileEntity } from '../file/fileApi';

export interface InformationType {
  id?: number;
  title: string;
  type: string;
  url: string;
  video: FileEntity | null;
}

export interface SubDiseaseDto {
  explanation?: string;
  advice: string;
  iconId?: number;
  name?: string;
  period?: string;
  priority?: number;
  risk: string;
  source?: string;
  summary: string;
  target?: string;
  test?: string;
}

export interface SubDiseaseEntity {
  id: number;
  advice: string;
  explanation: string;
  histograms: histogramType[];
  icon: any;
  name: string;
  detail: string;
  period: string;
  priority: number;
  risk: string;
  source: string;
  summary: string;
  target: string;
  test: string;
  top5: number;
  max: number;
  maxAllele: number;
  meanKorean: number;
  information: InformationType[];
}

export interface SubDiseaseMenuEntity {
  diseaseId: number;
  priority: number;
  subDiseaseId: number;
  subDiseaseName: string;
}

export type SubDieaseRequestDto = RequestDto<SubDiseaseDto>;

export const requestSubDiseases: ApiRequest<
  SubDieaseRequestDto,
  SubDiseaseEntity
> = (params = { method: 'get' }) => {
  const { method, id, data } = params;

  let url = `${name}`;
  if (id) {
    url = `${url}/${id}`;
  }

  return apiModule.request({
    method,
    url,
    data,
  });
};
