import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as dashboardApi from './dashboardApi';

export const name = 'dashboard';

interface DashboardState {
  reports: dashboardApi.StatEntity | null;
  app: dashboardApi.StatEntity | null;
  selected: string;
  loading: boolean;
  error: string | undefined;
  appCnt: number | null;
  reportsCnt: dashboardApi.StatCntEntity | null;
}

const initialState: DashboardState = {
  reports: null,
  app: null,
  selected: '',
  loading: false,
  error: '',
  appCnt: 0,
  reportsCnt: { reportCnt: 0, reportDownload: 0 },
};

const fetchStat = createAsyncThunk(
  `${name}/reportStat`,
  async (params: dashboardApi.StatQuery, thunkApi) => {
    try {
      const { data } = await dashboardApi.fetchReportStat(params);
      return data;
    } catch (e) {
      return thunkApi.rejectWithValue(e.message);
    }
  },
);

const fetchStatCnt = createAsyncThunk(
  `${name}/reportCnt`,
  async (params: dashboardApi.StatQuery, thunkApi) => {
    try {
      const { data } = await dashboardApi.fetchReportStatCnt(params);
      return data;
    } catch (e) {
      return thunkApi.rejectWithValue(e.message);
    }
  },
);

const fetchAppDownloadCnt = createAsyncThunk(
  `${name}/appCnt`,
  async (params: dashboardApi.StatQuery, thunkApi) => {
    try {
      const { data } = await dashboardApi.fetchAppStatCnt();
      return data;
    } catch (e) {
      return thunkApi.rejectWithValue(e.message);
    }
  },
);

const dashboardSlice = createSlice({
  name,
  initialState,
  reducers: {
    resetChart(state) {
      state.reports = null;
      state.app = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStat.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchStat.fulfilled, (state, action) => {
        state.loading = false;
        state[action.meta.arg.statType] = action.payload;
      })
      .addCase(fetchStat.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(fetchStatCnt.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchStatCnt.fulfilled, (state, action) => {
        state.loading = false;
        state.reportsCnt = action.payload;
      })
      .addCase(fetchStatCnt.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(fetchAppDownloadCnt.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAppDownloadCnt.fulfilled, (state, action) => {
        state.loading = false;
        state.appCnt = action.payload;
      })
      .addCase(fetchAppDownloadCnt.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const dashboardActions = {
  fetchStat,
  fetchStatCnt,
  fetchAppDownloadCnt,
  ...dashboardSlice.actions,
};

export default dashboardSlice.reducer;
