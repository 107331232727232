import React, { useCallback, useMemo } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { historyActions } from './historySlice';
import PopUp from '../../components/PopUp';
import CheckupForm from './CheckupForm';

function CheckupModal() {
  const dispatch = useAppDispatch();
  const [id, data] = useAppSelector((state) => [
    state.history.checkupState.selected,
    historyActions.getChecksupById(state),
  ]);

  const onClose = useCallback(() => {
    dispatch(historyActions.resetCheckupSelected());
  }, [dispatch]);

  const isPut = useMemo(() => {
    return Number(id) > 0;
  }, [id]);

  const onSubmit = useCallback(
    (data) => {
      dispatch(
        historyActions.requestReportCheckup({
          ...(isPut && { id }),
          method: isPut ? 'put' : 'post',
          data,
        }),
      );
      onClose();
    },
    [dispatch, id, onClose, isPut],
  );

  const onClickBottomButton = useCallback(() => {
    dispatch(
      historyActions.requestReportCheckup({
        id,
        method: 'delete',
      }),
    );
    onClose();
  }, [dispatch, id, onClose]);

  return (
    <>
      {id && (
        <PopUp
          title="보고서 관리"
          onClose={onClose}
          bottomComponent={
            isPut && (
              <button
                type="button"
                onClick={onClickBottomButton}
                style={{ all: 'unset', marginTop: 20, cursor: 'pointer' }}
              >
                <span
                  style={{
                    fontSize: 16,
                    textDecoration: 'underline',
                    color: '#cfcfcf',
                  }}
                >
                  보고서 삭제하기
                </span>
              </button>
            )
          }
        >
          <div
            style={{
              width: '90%',
            }}
          >
            <CheckupForm id={id} data={data} onSubmit={onSubmit} />
          </div>
        </PopUp>
      )}
    </>
  );
}

export default CheckupModal;
