import React from 'react';
import { RoutePropsTypes } from '../../app/share/routeConfig';
import MyRoute from '../../app/share/MyRoute';

import styled from '@emotion/styled';
import user from './User';
import institution from './Institution';

function Member({ routes }: RoutePropsTypes) {
  return (
    <>
      <MyRoute routes={routes} />
    </>
  );
}

export default Member;

export const member = {
  user,
  institution,
};

export const Container = styled.div`
  box-sizing: border-box;
`;
export const UpperContainer = styled.div`
  margin-top: 4.49vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const SearchContainer = styled.form`
  width: 538px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
export const CreateContainer = styled.div`
  width: 196px;
  height: 36px;
`;
export const Button1 = styled.button`
  width: 88px;
  height: 36px;
  background-color: ${(props) => props.theme.palette.primary2};
  color: ${(props) => props.theme.palette.light};
  border: none;
  border-radius: 5px;
  margin-left: 16px;
  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
  :active {
    background-color: #dddddd;
  }
`;

export const CheckBoxInput = styled.div`
  margin-left: 16px;
`;
export const Input1 = styled.input`
  width: 56.5%;
  height: 36px;
  border-radius: 5px;
  border: 1px solid #dddddd;
  padding-left: 8px;
`;
export const Button2 = styled.button`
  width: 196px;
  height: 36px;
  background-color: ${(props) => props.theme.palette.light};
  color: ${(props) => props.theme.palette.primary2};
  border: 1px solid ${(props) => props.theme.palette.primary2};
  border-radius: 5px;
  :hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.2);
  }
  :active {
    background-color: #dddddd;
  }
`;
export const LowerContainer = styled.div`
  margin-top: 1.94vh;
`;
