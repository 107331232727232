import { TableQuery } from '../../table/staticData/table';
import { NewsTableEntity } from '../newsApi';
import { Column } from '../../../components/CustomTable';
import { FieldProps } from '../../../components/SubmitFormWithFormik';

export const initialNews: NewsTableEntity = {
  content: [],
  empty: true,
  first: true,
  last: true,
  number: 0,
  numberOfElements: 0,
  pageable: {
    offset: 0,
    pageNumber: 0,
    pageSize: 0,
    paged: true,
    sort: {
      empty: true,
      sorted: true,
      unsorted: true,
    },
    unpaged: true,
  },
  size: 0,
  sort: {
    empty: true,
    sorted: true,
    unsorted: true,
  },
  totalElements: 0,
  totalPages: 0,
};

export const initialNewsQuery: TableQuery = {
  sort: 'createdDate,desc',
  page: '0',
  search: '',
  size: '10',
  type: '',
};

export const columns: Column[] = [
  {
    name: '글 번호',
    property: 'id',
    align: 'right',
  },
  {
    name: '제목',
    property: 'title',
  },
  {
    name: '작성자',
    property: 'createdBy',
  },
  {
    name: '최종등록일',
    property: 'createdDate',
    align: 'center',
  },
];

export const newsField: FieldProps[] = [
  {
    type: 'text',
    name: 'title',
    label: '제목',
    placeholder: '제목을 입력하세요',
    variant: 'outlined',
  },
  {
    type: 'text',
    name: 'contents',
    label: '본문',
    placeholder: '본문을 입력하세요',
    variant: 'outlined',
    multiline: true,
    rows: 12,
  },
];
