import { RootState } from '../../app/store';
import { initialMembers, SelectBox } from './staticData/member';
import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import * as memberApi from './memberApi';
import { TableQuery } from '../table/staticData/table';
const name = 'member';

interface MemberState {
  members: memberApi.MemberTableEntity;
  currentPath: string;
  selected: string | string[];
  authorities: SelectBox[];
  institutions: SelectBox[];
  institutionList: memberApi.Institution[];
  loading: boolean;
  error: string | undefined;
  instLast: any;
}
const initialState: MemberState = {
  members: initialMembers,
  selected: '',
  authorities: [],
  institutions: [],
  institutionList: [],
  instLast: null,
  currentPath: '/member',
  loading: false,
  error: '',
};
// async
export const getMembers = createAsyncThunk(
  `${name}/getMembers`,
  async (params: TableQuery, { rejectWithValue }) => {
    try {
      const { data } = await memberApi.getMembers(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const getAuthorities = createAsyncThunk(
  `${name}/getAuthorities`,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await memberApi.getAuthorities();
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const getInstitutions = createAsyncThunk(
  `${name}/getInstitutions`,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await memberApi.getInstitutions();
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const requestInstitutions = createAsyncThunk<any, any, any>(
  `${name}/requestInstitutions`,
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await memberApi.requestInstitutions(params);
      dispatch(getInstitutions());
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const postMember = createAsyncThunk(
  `${name}/postMember`,
  async (params: memberApi.MemberRequestDto, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await memberApi.postMember(params);
      dispatch(getMembers(params.query!));
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const updateMember = createAsyncThunk(
  `${name}/updateMember`,
  async (params: memberApi.MemberRequestDto, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await memberApi.updateMember(params);
      dispatch(getMembers(params.query!));
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const updateProfile = createAsyncThunk(
  `${name}/updateProfile`,
  async (
    params: memberApi.RequestDto<memberApi.ProfileDto>,
    { rejectWithValue },
  ) => {
    try {
      const { data } = await memberApi.updateProfile(params);

      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const initPassword = createAsyncThunk(
  `${name}/initPassword`,
  async (params: memberApi.emailDto, { rejectWithValue }) => {
    try {
      await memberApi.InitPassword(params);
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const deleteMember = createAsyncThunk(
  `${name}/deleteMember`,
  async (params: memberApi.MemberRequestDto, { rejectWithValue, dispatch }) => {
    try {
      await memberApi.deleteMember(params);
      dispatch(getMembers(params.query!));
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const memberSlice = createSlice({
  name,
  initialState,
  reducers: {
    setCurrentPath(state, action: PayloadAction<string>) {
      state.currentPath = action.payload;
    },
    setSelected(state, action: PayloadAction<string>) {
      state.selected = action.payload;
    },
    resetSelected(state) {
      state.selected = '';
    },
    addInsRow(state) {
      let newVar = {
        id: String(state.institutionList.length <= 0 ? 0 : ++state.instLast.id),
        institutionName: '',
      };
      state.instLast = newVar;
      state.institutionList.push(newVar);
    },
    setInsRow(state, action) {
      state.institutionList = action.payload;
    },
    removeInsRow(state, action) {
      state.institutionList = state.institutionList.filter((g) => {
        return g.id !== action.payload;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMembers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMembers.fulfilled, (state, action) => {
        state.loading = false;
        state.members = action.payload;
      })
      .addCase(getMembers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getAuthorities.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAuthorities.fulfilled, (state, action) => {
        state.loading = false;

        const auth: SelectBox[] = action.payload.map((value) => {
          return {
            value: value.id,
            label: value.role,
          };
        });

        state.authorities = auth;
      })
      .addCase(getAuthorities.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getInstitutions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInstitutions.fulfilled, (state, action) => {
        state.loading = false;

        const institution: SelectBox[] = action.payload.map((value) => {
          return {
            value: value.id,
            label: value.institutionName,
          };
        });

        state.institutions = institution;
        state.institutionList = action.payload;
        state.instLast = action.payload[action.payload.length - 1];
      })
      .addCase(getInstitutions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(postMember.pending, (state) => {
        state.loading = true;
      })
      .addCase(postMember.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(postMember.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateMember.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateMember.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateMember.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(initPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(initPassword.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(initPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteMember.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteMember.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteMember.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

const currentPathSelector = (state: RootState) => state.member.currentPath;

const selectSelector = (state: RootState) => state.member.selected;
const memberListSelector = (state: RootState) => state.member.members.content;

const memberSelector = createSelector(
  selectSelector,
  memberListSelector,
  (id, memberListSelector) => {
    if (id === '') {
      return {
        id: -1,
        email: '',
        nickname: '',
        phoneNumber: '',
        institution: '',
        authority: '',
        lastLoginDate: '',
        accountDeleted: false,
        accountLock: false,
        accountExpired: false,
      };
    }

    return memberListSelector.filter((m) => m.id === Number(id))[0];
  },
);

export const memberActions = {
  currentPathSelector,
  memberSelector,
  ...memberSlice.actions,
};

export default memberSlice.reducer;
