/** @jsxImportSource @emotion/react */
import React from 'react';

import { Bar, Chart } from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { reportContentActions } from './reportContetSlice';
import { ReportContainer } from '../../common/styles/ReportStyles';
import { newLineParser } from '../../common/wrapper/htmlReactParser';
import hard from '../../assets/hard_icon.png';
import normal from '../../assets/normal_icon.png';
import warn from '../../assets/warn_icon.png';

Chart.register(annotationPlugin);
Chart.register(ChartDataLabels);

const getTypeBySumamry = (summary: string) => {
  switch (summary) {
    case '관심관리':
      return '다소 높음';
    case '집중관리':
      return '높음';
    case '일반관리':
      return '보통';
  }
};

const getWarning = (summary: string) => {
  switch (summary) {
    case '관심관리':
      return '질환 발생 위험도가 다소 높으므로, 질환 예방을 위한 적극적인 관심과 관리가 필요합니다.';
    case '집중관리':
      return '질환 관련도가 높은 위험인자를 포함하고 있습니다. 이러한 경우 질환 발생 위험도가 높으므로 의료진과의 상담을 권장합니다.';
    case '일반관리':
      return '질환 발생 위험도는 보통입니다.';
  }
};

function getStyle(isSingle: any) {
  return {
    display: 'inline-block',
    width: 12,
    height: 12,
    border: '2px solid #DDDDDD',
    backgroundColor: isSingle ? '#FF6161' : '',
  };
}

const DUPLICATION_GENENAME = ['APOE'];

function getRiskAlleleRedBox(
  genotypeValue: string,
  alleleInfo: string,
  alleleCount: number,
) {
  if (alleleInfo !== 'AR') {
    return (
      <div
        style={{
          transform: 'translateY(15%)',
        }}
      >
        <div
          style={{
            marginRight: 10,
            ...getStyle(alleleCount > 1),
          }}
        />
        <div style={getStyle(alleleCount > 0)} />
      </div>
    );
  }
  return (
    <div
      style={{
        transform: 'translateY(15%)',
      }}
    >
      <div style={getStyle(alleleCount > 0)} />
    </div>
  );
}

function ReportContent() {
  const dispatch = useAppDispatch();
  const [report, data, [isPvCnt, isPvGenes]] = useAppSelector((state) => [
    reportContentActions.getDiseaseReport(state),
    reportContentActions.getHistogram(state),
    reportContentActions.getGenotypeCount(state),
  ]);

  React.useEffect(() => {
    dispatch(reportContentActions.resetReportContent());
  }, [dispatch]);

  const chart = React.useMemo(() => {
    return data && <Bar type="bar" {...data} />;
  }, [data]);

  function getSummaryIcon(summary: any) {
    let src = normal;
    if (summary === '관심관리') src = warn;
    if (summary === '집중관리') src = hard;

    return <img src={src} alt="" />;
  }

  function getSummaryColor(summary: any) {
    let color = 'green';
    if (summary === '관심관리') color = 'yellow';
    if (summary === '집중관리') color = 'red';

    return color;
  }

  return (
    <ReportContainer>
      {report && (
        <div>
          <div className="title">질환 설명</div>
          <div className="summary">
            {newLineParser(report.subDisease.detail)}
          </div>
          <div className="container">
            <div className="title">종합 결과</div>
            <div className="description">
              <div style={{ fontSize: 16 }}>
                {/*{`${summary.patientName}님은 `}*/}
                {`수검자님은 `}
                <span
                  className={getSummaryColor(report.summary)}
                  style={{ color: 'white' }}
                >{`${report.summary} 대상자`}</span>
                입니다.
              </div>
              <div style={{ display: 'flex', margin: 10 }}>
                <div>{getSummaryIcon(report.summary)}</div>
                <div
                  style={{
                    float: 'right',
                    marginLeft: 20,
                    padding: '20px 0px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      position: 'relative',
                      borderLeft: '1px solid #C4C4C4',
                      borderRight: '1px solid #C4C4C4',
                    }}
                  >
                    <div
                      className="header"
                      style={{
                        textAlign: 'center',
                        display: 'inline-block',
                        padding: 6,
                        position: 'relative',
                        width: 196,
                      }}
                    >
                      <span
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: 0,
                          right: 0,
                          transform: 'translateY(-50%)',
                        }}
                      >
                        질환 발생 위험도
                      </span>
                    </div>
                    <div
                      style={{
                        width: 30,
                        borderLeft: '1px solid #C4C4C4',
                        borderRight: '1px solid #C4C4C4',
                      }}
                    />

                    <div
                      className="header"
                      style={{
                        textAlign: 'center',
                        display: 'inline-block',
                        paddingTop: 6,
                      }}
                    >
                      검사 위험인자 분석 결과
                      <div
                        style={{
                          display: 'flex',
                          borderTop: '1px solid #C4C4C4',
                          marginTop: 6,
                          width: 300,
                        }}
                      >
                        <div
                          style={{
                            width: '50%',
                            padding: `6px 0px`,
                            textAlign: 'center',
                            display: 'inline-block',
                          }}
                        >
                          검사 수
                        </div>
                        <div
                          style={{ borderRight: '1px solid #C4C4C4', width: 1 }}
                        ></div>
                        <div
                          style={{
                            width: '50%',
                            padding: `6px 0px`,
                            textAlign: 'center',
                            display: 'inline-block',
                          }}
                        >
                          검출 수
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      borderLeft: '1px solid #C4C4C4',
                      borderRight: '1px solid #C4C4C4',
                    }}
                  >
                    <div
                      className={`color-` + getSummaryColor(report.summary)}
                      style={{
                        width: 196,
                        padding: 6,
                        textAlign: 'center',
                        height: 89,
                        position: 'relative',
                        borderTop: '1px solid #C4C4C4',
                        borderBottom: '1px solid #C4C4C4',
                      }}
                    >
                      <span
                        style={{
                          position: 'absolute',
                          top: '60%',
                          left: 0,
                          right: 0,
                          bottom: 0,
                          transform: 'translateY(-50%)',
                        }}
                      >
                        {getTypeBySumamry(report.summary)}
                      </span>
                    </div>
                    <div
                      style={{
                        width: 30,
                        borderLeft: '1px solid #C4C4C4',
                        borderRight: '1px solid #C4C4C4',
                      }}
                    />
                    <div
                      style={{
                        display: 'flex',
                        width: 300,
                        alignContent: 'center',
                        borderTop: '1px solid #C4C4C4',
                        borderBottom: '1px solid #C4C4C4',
                      }}
                    >
                      <div
                        style={{
                          width: '50%',
                          textAlign: 'center',
                          padding: 6,
                          height: 89,
                          position: 'relative',
                        }}
                      >
                        <span
                          style={{
                            position: 'absolute',
                            top: '60%',
                            left: 0,
                            right: 0,
                            bottom: 0,
                            transform: 'translateY(-50%)',
                          }}
                        >
                          {report.subDisease.maxAllele}
                        </span>
                      </div>
                      <div
                        style={{ borderRight: '1px solid #C4C4C4', width: 1 }}
                      ></div>
                      <div
                        style={{
                          width: '50%',
                          textAlign: 'center',
                          padding: 6,
                          height: 89,
                          position: 'relative',
                        }}
                      >
                        <span
                          style={{
                            position: 'absolute',
                            top: '60%',
                            left: 0,
                            right: 0,
                            bottom: 0,
                            transform: 'translateY(-50%)',
                          }}
                        >
                          {report.score}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {`수검자님을 검사한 결과, ${
                  report.subDisease.name
                } 발생에 영향을 주는 총 ${
                  report.subDisease.maxAllele
                }개의 위험인자 중 ${report.score}개가 
                  ${
                    report.summary == '집중관리'
                      ? `검출 되었으며 질환 관련도가 높은 위험인자를 포함하고 있습니다. 이러한 경우 질환 발생 위험도가 높으므로 의료진과의 상담을 권장합니다. 유전적 위험인자가 많을수록 질환 예방을 위해 환경적 요인에 대한 관심과 관리가 필요합니다.`
                      : `검출되었습니다. 이는 한국인 ${
                          report.subDisease.name
                        } 위험인자 분포에서 ${
                          report.score >= report.subDisease.top5
                            ? '상위 5%'
                            : '하위 95%'
                        }인 ${report.subDisease.top5}개 ${
                          report.score >= report.subDisease.top5
                            ? '이상'
                            : '미만'
                        }에 해당되며 질환 발생 위험도가 ${getWarning(
                          report.summary,
                        )}
                  유전적 위험인자가 많을수록 질환 예방을 위해 환경적 요인에 대한 관심과 관리가 필요합니다.`
                  }
                  `}
              </div>
            </div>
          </div>
          <div className="container">
            <div className="title">
              위험인자 분포 그래프
              <span className="sub-title">
                자사가 보유한 한국인 위험인자 data를 기반하여 분포도 상의 평균값
                및 수검자 결과값을 확인합니다.
              </span>
            </div>
            <div className="description">
              <div style={{ marginBottom: 10 }}>
                {`한국인의 ${report.subDisease.name} 위험인자 수는 평균 ${report.subDisease.meanKorean}개 이며 수검자님은 위험인자 ${report.score}개가 검출되었습니다.`}
              </div>
              {chart}
            </div>
          </div>
          <div className="container">
            <div className="title">
              유전형 분석결과
              <span className="sub-title">
                질환 발생과 관련된 여러 개 유전자 변이 검사 후, 분석한 수검자의
                유전형과 위험인자 검출여부를 판단합니다.
              </span>
            </div>
            <div className="description">
              <div style={{ marginBottom: 10 }}>
                {`${report.subDisease.name} 발생에 영향을 주는 위험인자 ${report.subDisease.maxAllele}개 중 ${report.score}개가 검출되었습니다.`}
              </div>
              <div>
                <div className="table table1 header">유전자 (변이 정보)</div>
                <div className="table header">나의 유전형</div>
                <div className="table header">위험인자 검출 수 발생 위험도</div>
              </div>
              {report.subDisease.genotypes
                .reduce((acc: any[], cur: any) => {
                  let r: any[] = [];

                  if (
                    DUPLICATION_GENENAME.includes(cur.geneName.toUpperCase())
                  ) {
                    const not = acc.filter((f: any) => {
                      return !DUPLICATION_GENENAME.includes(
                        f.geneName.toUpperCase(),
                      );
                    });

                    const exist = acc.filter((f: any) => {
                      return DUPLICATION_GENENAME.includes(
                        f.geneName.toUpperCase(),
                      );
                    })[0];

                    r = [
                      ...not,
                      {
                        ...cur,
                        pos: exist ? [...exist.pos, cur.pos] : [cur.pos],
                        alleleCount: exist
                          ? exist.alleleCount + cur.alleleCount
                          : cur.alleleCount,
                      },
                    ];
                  } else {
                    r = [...acc, cur];
                  }

                  return r;
                }, [])
                .map(({ id, geneName, pos, alleleInfo, isPv }) => {
                  let find = report.genotypes.find((value) => {
                    return value.geneName === geneName && Array.isArray(pos)
                      ? pos.includes(value.pos)
                      : value.pos === pos;
                  });

                  return (
                    <div key={id} style={{ position: 'relative' }}>
                      <div className="table table1">
                        {isPv && (
                          <span
                            style={{
                              color: '#FF6161',
                              fontSize: 15,
                              marginRight: 3,
                            }}
                          >
                            *
                          </span>
                        )}
                        {`${geneName} (${pos})`}
                      </div>
                      <div className="table" style={{ position: 'relative' }}>
                        {find ? find.value : '-'}
                        {find?.type === '증가형' && (
                          <div
                            style={{
                              border: '1px solid red',
                              height: 22,
                              width: 22,
                              borderRadius: '50%',
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translateX(-50%) translateY(-50%)',
                            }}
                          />
                        )}
                      </div>
                      <div
                        className="table"
                        style={{
                          height: 21,
                          position: 'relative',
                          display: 'inline-block',
                        }}
                      >
                        <div style={{ display: 'inline-block' }}>
                          {getRiskAlleleRedBox(
                            find ? find!.value : '',
                            alleleInfo,
                            find ? find!.alleleCount : 0,
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              <div
                style={{ width: '100%', textAlign: 'center', marginTop: 10 }}
              >
                <div
                  style={{
                    marginRight: 20,
                    display: 'inline-block',
                    fontSize: 12,
                    fontWeight: 400,
                  }}
                >
                  <div
                    style={{
                      width: 8,
                      height: 8,
                      marginRight: 5,
                      borderRadius: '50%',
                      border: '2px solid #FF6161',
                      display: 'inline-block',
                    }}
                  />
                  위험이 증가된 유전형
                </div>
                <div
                  style={{
                    marginRight: 20,
                    display: 'inline-block',
                    fontSize: 12,
                    fontWeight: 400,
                  }}
                >
                  <div
                    style={{
                      width: 8,
                      height: 8,
                      marginRight: 5,
                      border: '2px solid #DDDDDD',
                      backgroundColor: '#FF6161',
                      display: 'inline-block',
                    }}
                  />
                  검출된 위험인자
                </div>
                <div
                  style={{
                    display: 'inline-block',
                    fontSize: 12,
                    fontWeight: 400,
                  }}
                >
                  <div
                    style={{
                      width: 8,
                      height: 8,
                      marginRight: 5,
                      border: '2px solid #DDDDDD',
                      display: 'inline-block',
                    }}
                  />
                  검출되지 않은 위험인자
                </div>
                {isPvCnt > 0 ? (
                  <div>
                    <div
                      style={{
                        display: 'inline-block',
                        fontSize: 12,
                        fontWeight: 400,
                      }}
                    >
                      <span
                        style={{
                          color: '#FF6161',
                          fontSize: 15,
                          marginRight: 3,
                        }}
                      >
                        *
                      </span>
                      집중관리 대상 유전자(PV): 단 하나의 변이가 발견되어도 질환
                      발생률이 크게 상승합니다.
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="container">
            <div className="title">
              위험요인
              <span className="sub-title">
                질환 예방을 위해 주의해야 할 위험인자를 제시합니다.
              </span>
            </div>
            <div className="description">
              {newLineParser(report.subDisease.risk)}
            </div>
          </div>
          <div className="container">
            <div className="title">
              권고사항
              <span className="sub-title">
                질환을 예방하기 위해 실천해야하는 권장사항과 질환 관련 건강검진
                정보를 전달합니다.
              </span>
            </div>
            <div className="description">
              {newLineParser(report.subDisease.advice)}
            </div>
          </div>
          {isPvCnt > 0 && (
            <div className="container">
              <div className="title">주의사항</div>
              <div className="description">
                {`본 검사에서 위험인자 유전형이 발견되지 않더라도 ${isPvGenes} 및 기타 ${report.subDisease.name} 관련 유전자의 위험인자가 검출될 수 있습니다.`}
              </div>
            </div>
          )}
          <div className="container">
            <div className="description">
              <div>
                <div className="table2 table1 header">대상</div>
                <div
                  className="table header"
                  style={{ width: 'calc(25% - 3px)' }}
                >
                  주기
                </div>
                <div
                  className="table header"
                  style={{ width: 'calc(25% - 3px)' }}
                >
                  검진
                </div>
              </div>
              <div className="row">
                <div className="table2 table1">
                  {newLineParser(report.subDisease.target)}
                </div>
                <div className="table" style={{ width: 'calc(25% - 3px)' }}>
                  {newLineParser(report.subDisease.period)}
                </div>
                <div className="table" style={{ width: 'calc(25% - 3px)' }}>
                  {newLineParser(report.subDisease.test)}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </ReportContainer>
  );
}

export default React.memo(ReportContent);
