import {
  createAsyncThunk,
  createSlice,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit';
import * as reportApi from './reportApi';
import { RootState } from '../../app/store';

export const name = 'reports';

//state
interface ReportState {
  reports: reportApi.ReportEntity[];
  content: reportApi.ReportEntity | null;
  keyword: string;
  loading: boolean;
  selected: string | string[];
  error: string | undefined;
}
const initialState: ReportState = {
  reports: [],
  content: null,
  keyword: '',
  selected: '',
  loading: false,
  error: '',
};

//async
const fetchReports = createAsyncThunk(
  `${name}/fetchReports`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await reportApi.fetchReports();
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

//slice
const reportSlice = createSlice({
  name,
  initialState,
  reducers: {
    handleKeywordChange(state, action: PayloadAction<string>) {
      state.keyword = action.payload;
    },
    setSelectedKey(state, action: PayloadAction<any>) {
      state.selected = action.payload;
    },
    resetSelectedKey(state) {
      state.selected = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReports.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchReports.fulfilled, (state, action) => {
        state.loading = false;
        state.reports = action.payload;
        if (!state.selected && action.payload.length > 0)
          state.selected = action.payload[0].publicKey;
      })
      .addCase(fetchReports.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

//selector
const reportsSelector = (state: RootState) => state[name].reports;
const selectedKeySelector = (state: RootState) => state[name].selected;
const keywordSelector = (state: RootState) => state[name].keyword;

const publicKeyOfReportsSelector = createSelector(reportsSelector, (reports) =>
  reports.map((r) => r.publicKey),
);
const searchReportSelector = createSelector(
  keywordSelector,
  publicKeyOfReportsSelector,
  (keyword, reports) => reports.filter((title) => title.includes(keyword)),
);
const reportByPublicKey = createSelector(
  reportsSelector,
  selectedKeySelector,
  (reports, publicKey) => reports.filter((f) => f.publicKey === publicKey)[0],
);
const checkupSelector = createSelector(
  reportByPublicKey,
  (report) => report && report.checkup,
);

//export
export const reportActions = {
  fetchReports,
  publicKeyOfReportsSelector,
  reportByPublicKey,
  keywordSelector,
  searchReportSelector,
  checkupSelector,
  ...reportSlice.actions,
};
export default reportSlice.reducer;
