import React from 'react';
import { Link } from 'react-router-dom';
import { menuActions } from './menuSlice';
import NavList from '../../components/NavList';

import { NavContainer, gnbNav } from '../../common/styles/NavStyles';
import OptionMenu from './OptionMenu';
import { optionMenus } from './staticData/menu';
import { useAppSelector } from '../../app/hooks';
import logo from '../../assets/gc_logo_white.png';

export interface MenuProps {
  selected: string;
  user?: any;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

function GnbMenu({ selected, user, setOpen }: MenuProps) {
  const [menus] = useAppSelector((state) => [menuActions.menuSelector(state)]);

  return (
    <NavContainer>
      <OptionMenu options={optionMenus} setOpen={setOpen} />
      {user && (
        <div>
          {user.authority !== 'DOCTOR' ? (
            <Link to="/dashboard">
              <img
                src={logo}
                alt=""
                style={{
                  top: '50%',
                  position: 'absolute',
                  transform: 'translateY(-50%)',
                }}
              />
            </Link>
          ) : (
            <img
              src={logo}
              alt=""
              style={{
                top: '50%',
                position: 'absolute',
                transform: 'translateY(-50%)',
              }}
            />
          )}

          <NavList
            menus={menus}
            css={gnbNav}
            selected={selected}
            authority={user.authority}
          />
        </div>
      )}
    </NavContainer>
  );
}

export default React.memo(GnbMenu);
