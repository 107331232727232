import { Theme, css } from '@emotion/react';

export const defaultTab = (theme: Theme) => css`
  @media (min-width: 600px) {
    .MuiTab-root {
      min-width: 0;
    }
  }
  .MuiTab-root {
    ${theme.font.title2}
  }
  .MuiTabScrollButton-root {
    width: 10px;
  }
  .MuiTabs-indicator {
    background-color: ${theme.palette.primary1};
  }
`;

export const subTab = (theme: Theme) => css`
  .MuiTabs-indicator {
    display: none;
  }
  .MuiTab-root {
    color: ${theme.palette.grayscale[0]};
  }
  .Mui-selected {
    font-weight: 600;
    color: ${theme.palette.primary2};
  }
`;
