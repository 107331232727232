import React, { useEffect, useState, useCallback } from 'react';
import {
  Button1,
  CheckBoxInput,
  Container,
  Input1,
  LowerContainer,
  SearchContainer,
  UpperContainer,
} from '../member';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import {
  getReportSyncLog,
  historyActions,
} from '../../features/history/historySlice';
import useTableRoutePath from '../../common/hooks/useTableRoutePath';
import {
  columns,
  initialReportQuery,
} from '../../features/history/staticData/report';
import Table from '../../components/CustomTable';
import { parseQuery } from '../../common/utils/routeUtils';
import { useLocation } from 'react-router-dom';
import { ReportSyncLogType } from '../../features/history/staticData/history';
import { useAppSelector } from '../../app/hooks';
import PopUp from '../../components/PopUp';

import CalendarPicker from '../../components/CalendarPicker';

export type popupType = {
  label: string;
  state: string;
  type: string;
};

function Report() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { reportSyncLog } = useSelector(
    (state: RootState) => state.history.reportSyncLogState,
  );
  const report = useAppSelector((state) =>
    historyActions.reportSelector(state),
  );

  const {
    query,
    handleChangePage,
    onSubmit,
    keyword,
    setKeyword,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    routePath,
  } = useTableRoutePath(initialReportQuery);

  const [fail, setFail] = useState(false);
  const [open, setOpen] = useState(false);
  const [inputList, setInputList] = useState<popupType[]>([]);

  useEffect(() => {
    if (report) {
      setInputList([
        {
          label: '확인코드',
          state: report.publicKey,
          type: 'text',
        },

        {
          label: '상태',
          state: report.status,
          type: 'text',
        },
        {
          label: '실패원인',
          state: report.message,
          type: 'text',
        },
      ]);
    }
  }, [report, setInputList]);

  useEffect(() => {
    dispatch(getReportSyncLog({ ...query }));
  }, [dispatch, query]);

  const handleClickFail = useCallback(
    (row: ReportSyncLogType) => {
      dispatch(historyActions.setSelected(String(row.id)));
      setOpen(true);
    },
    [setOpen, dispatch],
  );
  const handelClosePopup = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  useEffect(() => {
    routePath(0, { status: fail ? 'FAIL' : null });
  }, [fail]);

  return (
    <Container>
      <UpperContainer>
        <SearchContainer onSubmit={onSubmit}>
          <Input1
            type={'text'}
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            placeholder={'확인 코드 입력'}
          />
          <Button1 type="submit">검색</Button1>
          <CheckBoxInput>
            <input
              type="checkbox"
              name="fail"
              onChange={(e) => setFail(e.target.checked ? true : false)}
            />
            <label>FAIL 보기</label>
          </CheckBoxInput>
        </SearchContainer>
        <CalendarPicker
          isOnlyCalender
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      </UpperContainer>
      <LowerContainer>
        {open ? (
          <PopUp
            onClose={handelClosePopup}
            inputList={inputList}
            title={'메세지'}
            buttonTitle={'닫기'}
            onClick={handelClosePopup}
          />
        ) : null}
        <Table
          columns={columns.map((value) => {
            if (value.property === 'status') {
              return {
                ...value,
                onClick: (row: ReportSyncLogType) => {
                  handleClickFail(row);
                },
                condition: (value: any) => {
                  return value === 'FAIL';
                },
              };
            }
            return value;
          })}
          list={reportSyncLog}
          fetching={false}
          isPaging
          pageState={{
            page: Number(parseQuery(location.search).page || 0),
            rowsPerPage: 10,
          }}
          actions={{
            handleChangePage,
          }}
        />
      </LowerContainer>
    </Container>
  );
}

export default Report;
