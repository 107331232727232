import React from 'react';
import NewsList from '../../features/news/NewsList';

function News() {
  return (
    <>
      <NewsList />
    </>
  );
}

export default News;
