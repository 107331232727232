import { Column } from '../../../components/CustomTable';
import { TableQuery } from '../../table/staticData/table';

export const initialPushQuery: TableQuery = {
  sort: 'createdDate,desc',
  page: '0',
  search: '',
  size: '10',
  type: 'title',
};

export const columns: Column[] = [
  {
    name: 'No',
    property: 'id',
  },
  {
    name: '타겟토큰',
    property: 'targetToken',
    style: {
      head: {
        width: 700,
      },
    },
  },
  {
    name: '제목',
    property: 'title',
  },
  {
    name: '본문',
    property: 'body',
    // align: 'center',
  },
  {
    name: '요청 일시',
    property: 'createdDate',
  },
];
