import * as React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@emotion/react';
import { theme, GlobalStyle } from './common/styles';
// import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

// import { ThemeProvider } from "@mui/material/styles";
// import { CssBaseline, GlobalStyles } from "@mui/material";

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      {/* <MuiThemeProvider theme={theme}> */}
      <GlobalStyle />
      <App />
      {/* </MuiThemeProvider> */}
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
);
