import React, { useEffect } from 'react';
import ImageUploading, {
  ImageType,
  ImageListType,
} from 'react-images-uploading';
import { FileEntity } from '../features/file/fileApi';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { fileActions } from '../features/file/fileSlice';
import TextField from '@material-ui/core/TextField';
import { getHash } from '../common/utils/stringUtils';

interface ImageUploadProps {
  formik: any;
  defaultImage: FileEntity | null | undefined;
  title?: string;
  size?: string;
  valueType?: string;
}
interface ImageListState extends ImageType {
  hash?: string;
}

function PreviewImage({ url, size }: any) {
  return <img src={url} width={size || '300'} alt="" />;
}

function ImageUpload({
  formik,
  defaultImage,
  title,
  size,
  valueType,
}: ImageUploadProps) {
  const dispatch = useAppDispatch();
  const file = useAppSelector((state) => state.files.files);

  const [image, setImages] = React.useState<ImageListState[]>([]);

  const handleChange = async (image: ImageListType) => {
    const hash = getHash();

    setImages(
      image.map((m) => {
        return {
          hash,
          ...m,
        };
      }),
    );

    const formData = new FormData();
    formData.append('file', image[0].file!);

    await dispatch(
      fileActions.requestFiles({
        method: 'post',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
        hash,
      }),
    );
  };

  useEffect(() => {
    if (file.length > 0 && image.length > 0) {
      const selectedFile = file.filter((f) => {
        return f.hash === image[0].hash;
      })[0];

      formik.setFieldValue(`${valueType || 'imageId'}`, selectedFile.id);
    }
  }, [file]);

  return (
    <ImageUploading value={image} onChange={handleChange}>
      {({ imageList, onImageUpload }) => (
        <div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              marginBottom: 16,
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'flex-start',
              }}
            >
              <TextField
                className="file_input"
                type="text"
                variant="outlined"
                label={`${title || '이미지'} 경로`}
                placeholder={`${title || '이미지'} 경로`}
                name="imagePath"
                disabled
                value={
                  image[0]
                    ? image[0].file?.name
                    : defaultImage?.originalFileName || ''
                }
                error={!!formik.errors[valueType || 'imageId']}
                helperText={formik.errors[valueType || 'imageId']}
              />
              <button
                className="file_btn"
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  e.preventDefault();
                  onImageUpload();
                }}
              >
                {`${title || '이미지'} 업로드`}
              </button>
            </div>
          </div>
          {image[0] ? (
            imageList.map((image, i) => {
              return <PreviewImage key={i} url={image.dataURL} size={size} />;
            })
          ) : (
            <PreviewImage url={defaultImage?.filePath} size={size} />
          )}
        </div>
      )}
    </ImageUploading>
  );
}

export default ImageUpload;
