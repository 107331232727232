import React, { useCallback } from 'react';
import { ApiRequestConfig } from '../../common/wrapper/axios';
import Information from './DiseaseInformation';
import { NoneButton } from './Tree';
import { informationField } from './staticData/subDisease';
import { InformationType } from './subDiseaseApi';
import { InformationEntity } from './informationApi';
import PopUp from '../../components/PopUp';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { informationActions } from './informationSlice';
import { useFormik } from 'formik';
import Form from '../../components/SubmitFormWithFormik';
import { boardForm } from '../../common/styles/FormStyles';
import Player from '../../components/Player';
import TextField from '@material-ui/core/TextField';
import * as Yup from 'yup';

interface InformationProps {
  data: InformationEntity;
}

function InformationForm({ data }: InformationProps) {
  const [open, setOpen] = React.useState(false);
  const [dispatchConfig, setDispatchConfig] = React.useState<{
    method: ApiRequestConfig['method'];
    id?: number;
  }>({ method: 'delete' });
  const link = useAppSelector((state) => informationActions.getLink(state));

  const dispatch = useAppDispatch();

  const dispatchRequest = useCallback(
    (id?, data?) => {
      dispatch(
        informationActions.requestInformation({
          method: dispatchConfig.method,
          id: id || dispatchConfig.id,
          data,
        }),
      );
    },
    [dispatch, dispatchConfig],
  );

  React.useEffect(() => {
    if (!open) {
      setDispatchConfig({ method: 'delete' });
    }
  }, [open]);

  const handlePop = useCallback(
    (method?, id?) => {
      switch (method) {
        case 'put':
        case 'post':
          setOpen(true);
          setDispatchConfig({ method, id });
          if (id) {
            dispatch(informationActions.setSelected(id));
          }
          break;
        default:
          setOpen(false);
      }
    },
    [dispatch],
  );

  const handleSubmit = useCallback(
    (v) => {
      dispatchRequest(undefined, {
        videoId: v.videoType === 'file' ? Number(v.videoId) : null,
        url: v.videoType === 'file' ? null : v.url,
        title: v.title,
        type: v.type,
      });
      setOpen(false);
    },
    [dispatchRequest],
  );

  return (
    <>
      <div
        style={{
          height: 680,
          position: 'relative',
          overflowY: 'auto',
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <button
            style={{
              background: 'none',
              border: '1px solid #68AACF',
              color: '#68AACF',
              padding: '5px 10px',
              borderRadius: 4,
              cursor: 'pointer',
            }}
            onClick={() => handlePop('post')}
          >
            관련 정보 추가
          </button>
        </div>
        {(data || []).map((info, i) => {
          return (
            <Information information={info} key={i}>
              <NoneButton onClick={() => handlePop('put', info.id)}>
                수정
              </NoneButton>
              <NoneButton onClick={() => dispatchRequest(info.id)}>
                삭제
              </NoneButton>
            </Information>
          );
        })}
        {data.length <= 0 && (
          <div style={{ textAlign: 'center', color: '#C4C4C4' }}>
            관련 정보가 없습니다.
          </div>
        )}
      </div>
      {open && (
        <PopUp title="관련 정보 추가" onClose={() => handlePop()}>
          <div style={{ width: '90%' }}>
            <InformationPopupForm
              data={link}
              dispatchConfig={dispatchConfig}
              onSubmit={handleSubmit}
            />
          </div>
        </PopUp>
      )}
    </>
  );
}

export default InformationForm;

function InformationPopupForm({
  data,
  dispatchConfig,
  onSubmit,
}: {
  data: InformationType;
  dispatchConfig: any;
  onSubmit: any;
}) {
  const loading = useAppSelector((state) => state.files.loading);

  const isData = React.useMemo(() => {
    return dispatchConfig.method === 'put';
  }, [dispatchConfig.method]);

  const formik = useFormik({
    initialValues: {
      title: isData ? data.title : '',
      url: isData ? data.url : '',
      type: isData ? data.type : 'NEWS',
      videoId: isData ? (data.video ? data.video.id : null) : null,
      videoType: isData ? (data.video ? 'file' : 'link') : 'link',
      method: dispatchConfig.method,
    },
    validationSchema: Yup.object({
      title: Yup.string().required('required'),
      url: Yup.string().url().required('required'),
    }),
    onSubmit: (v) => {
      onSubmit(v);
    },
  });

  const disableArr = React.useMemo(() => {
    return isData ? ['type'] : [''];
  }, [isData]);

  return (
    <Form
      formik={formik}
      formData={informationField}
      css={boardForm}
      disableArr={disableArr}
    >
      {formik.values.type === 'VIDEO' ? (
        <Player
          formik={formik}
          defaultVideo={isData ? data.video : null}
          valueType="url"
        />
      ) : (
        <>
          <TextField
            type="text"
            name="url"
            onChange={formik.handleChange}
            value={formik.values.url}
            placeholder="링크 주소를 입력하세요"
            variant="outlined"
            label="URL"
          />
          <Information information={formik.values} children={<></>} />
        </>
      )}

      <button type="submit" disabled={loading} style={{ marginBottom: 0 }}>
        수정하기
      </button>
    </Form>
  );
}
