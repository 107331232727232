import React from 'react';
import { menuActions } from './menuSlice';
import { MenuProps } from './GnbMenu';

import NavList from '../../components/NavList';
import { useAppSelector } from '../../app/hooks';
import { NavContainer, subNav } from '../../common/styles/NavStyles';

function SubMenu({ selected }: MenuProps) {
  const [subMenus] = useAppSelector((state) => [
    menuActions.subMenuSelector(state),
  ]);

  return (
    <>
      {subMenus.length > 0 && (
        <NavContainer>
          <NavList menus={subMenus} css={subNav} selected={selected} />
        </NavContainer>
      )}
    </>
  );
}

export default React.memo(SubMenu);
