import React from 'react';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import { withStyles } from '@material-ui/styles';
import { SerializedStyles } from '@emotion/serialize';
import { css } from '@emotion/react';

const Input = withStyles(() => ({
  input: {
    height: '80%',
    width: 250,
    paddingLeft: 16,
    position: 'relative',
    borderRadius: 4,
    fontWeight: 400,
    padding: '3px 3rem 3px 3px',
    fontFamily: 'Spoqa Han Sans Neo',
    '&:focus': {
      borderRadius: 4,
      backgroundColor: '#ffffff',
    },
  },
}))(InputBase);

type SelectBoxProps = {
  values?: {
    value: string;
    label: string;
  }[];
  maxLength?: number;
  name: string;
  defaultValue?: unknown;
  onChange?: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
  classes?: {
    select?: SerializedStyles;
  };
  placeholder?: string;
};

function SelectBox({
  values,
  onChange,
  defaultValue,
  maxLength,
  name,
  classes,
  placeholder,
}: SelectBoxProps): JSX.Element {
  return (
    <Select
      native
      name={name}
      onChange={onChange}
      value={defaultValue}
      input={<Input />}
      css={[classes?.select]}
    >
      {placeholder && (
        <option
          key={placeholder}
          value={''}
          title={placeholder}
          css={css`
            color: #000000;
          `}
        >
          {placeholder}
        </option>
      )}
      {values &&
        values.map((value, i) => {
          return (
            <option
              key={i}
              value={value.value}
              title={value.label}
              css={css`
                color: #000000;
              `}
            >
              {(maxLength || 0) < value.label.length
                ? `${value.label.slice(0, maxLength)}...`
                : value.label}
            </option>
          );
        })}
    </Select>
  );
}

export default SelectBox;
