import { FieldProps } from '../../../components/SubmitFormWithFormik';
import { Column, TextEditor, SelectCellFormatter } from 'react-data-grid';
import { histogramType, genotypeType } from '../../report/reportApi';
import { RemoveButton } from '../Histogram';
import { histogramActions } from '../histogramSlice';
import { genotypeActions } from '../genotypeSlice';
export const subDiseaseField: FieldProps[] = [
  {
    type: 'text',
    name: 'summary',
    label: '질환 간략 정보(수검자용 설명)',
    placeholder: '질환 정보를 입력하세요',
    variant: 'outlined',
    multiline: true,
    rows: 2,
  },
  {
    type: 'text',
    name: 'detail',
    label: '질환 상세 정보(의료진용 설명)',
    placeholder: '질환 정보를 입력하세요',
    variant: 'outlined',
    multiline: true,
    rows: 5,
  },
  {
    type: 'text',
    name: 'risk',
    label: '위험 요인',
    placeholder: '위험 요인을 입력하세요',
    variant: 'outlined',
    multiline: true,
    rows: 3,
  },
  {
    type: 'text',
    name: 'advice',
    label: '권고 사항',
    placeholder: '권고 사항을 입력하세요',
    variant: 'outlined',
    multiline: true,
    rows: 3,
  },
  {
    type: 'text',
    name: 'target',
    label: '대상',
    placeholder: '대상을 입력하세요',
    variant: 'outlined',
    multiline: true,
    rows: 3,
  },
  {
    type: 'text',
    name: 'period',
    label: '주기',
    placeholder: '주기 입력하세요',
    variant: 'outlined',
    multiline: true,
    rows: 1,
  },
  {
    type: 'text',
    name: 'test',
    label: '검진',
    placeholder: '관련된 검진을 입력하세요',
    variant: 'outlined',
    multiline: true,
    rows: 1,
  },
  {
    type: 'text',
    name: 'top5',
    label: '위험군 상위 5% 그룹이 포함되기 시작하는 위험인자 수',
    placeholder: 'top5 입력하세요',
    variant: 'outlined',
    multiline: false,
    rows: 1,
  },
  {
    type: 'text',
    name: 'maxAllele',
    label: '위험 인자 최대 개수',
    placeholder: 'maxAllele 입력하세요',
    variant: 'outlined',
    multiline: false,
    rows: 1,
  },
  {
    type: 'text',
    name: 'meanKorean',
    label: '한국인 평균 위험인자 수',
    placeholder: 'meanKorean 입력하세요',
    variant: 'outlined',
    multiline: false,
    rows: 1,
  },
  {
    type: 'text',
    name: 'max',
    label: '분포 그래프 그릴 때 Y축 최대값',
    placeholder: 'max 입력하세요',
    variant: 'outlined',
    multiline: false,
    rows: 1,
  },
  {
    type: 'text',
    name: 'source',
    label: '출처',
    placeholder: '출처 값을 입력하세요',
    variant: 'outlined',
    multiline: false,
    rows: 1,
  },
];

interface Tab {
  id: number;
  label: string;
}

export const tabList: Tab[] = [
  {
    id: 0,
    label: '기본 정보 관리',
  },
  {
    id: 1,
    label: '관련 정보 관리',
  },
  {
    id: 2,
    label: '유전형 관리',
  },
];

export const informationField: FieldProps[] = [
  {
    type: 'text',
    name: 'type',
    label: '타입',
    placeholder: '관련 링크의 타입을 입력하세요',
    variant: 'outlined',
    select: true,
    option: [
      {
        value: 'NEWS',
        label: '뉴스',
      },
      {
        value: 'VIDEO',
        label: '영상',
      },
    ],
  },
  {
    type: 'text',
    name: 'title',
    label: '제목',
    placeholder: '관련 링크의 제목을 입력하세요',
    variant: 'outlined',
  },
];

interface HistogramRow extends histogramType {}
export const histogramField: Column<HistogramRow>[] = [
  // {
  //   key: 'isMean',
  //   name: '한국인 평균값',
  //   headerCellClass: 'cell-center',
  //   formatter({ row, onRowChange, isCellSelected }) {
  //     return (
  //       <SelectCellFormatter
  //         tabIndex={-1}
  //         value={row.isMean}
  //         onChange={() => {
  //           onRowChange({ ...row, isMean: !row.isMean });
  //         }}
  //         onClick={(e) => e.stopPropagation()}
  //         isCellSelected={isCellSelected}
  //       />
  //     );
  //   },
  // },
  {
    key: 'riskLevel',
    name: '위험수치',
    headerCellClass: 'cell-center',
    editor: TextEditor,
  },
  {
    key: 'resultValue',
    name: '결과값',
    headerCellClass: 'cell-center',
    editor: TextEditor,
  },
  {
    key: 'action',
    name: '삭제',
    headerCellClass: 'cell-center',
    cellClass: 'cell-center',
    formatter({ row }) {
      return <RemoveButton id={row.id} actions={histogramActions} />;
    },
    width: 50,
  },
];

interface GenotypeRow extends genotypeType {}
export const genotypeField: Column<GenotypeRow>[] = [
  {
    key: 'geneName',
    name: '유전자',
    headerCellClass: 'cell-center',
    editor: TextEditor,
  },
  {
    key: 'pos',
    name: 'Position',
    headerCellClass: 'cell-center',
    editor: TextEditor,
  },
  {
    key: 'snpId',
    name: 'SNP ID',
    headerCellClass: 'cell-center',
    editor: TextEditor,
  },
  {
    key: 'isPv',
    name: 'PV 여부',
    headerCellClass: 'cell-center',
    formatter({ row, onRowChange, isCellSelected }) {
      return (
        <SelectCellFormatter
          tabIndex={-1}
          value={row.isPv ? row.isPv : false}
          onChange={() => {
            onRowChange({ ...row, isPv: !row.isPv });
          }}
          onClick={(e) => e.stopPropagation()}
          isCellSelected={isCellSelected}
        />
      );
    },
  },
  {
    key: 'action',
    name: '삭제',
    headerCellClass: 'cell-center',
    cellClass: 'cell-center',
    formatter({ row }) {
      return <RemoveButton id={row.id} actions={genotypeActions} />;
    },
    width: 50,
  },
];
