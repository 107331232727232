import React, { useCallback } from 'react';
import { NoneButton } from '../disease/Tree';

import { useAppDispatch } from '../../app/hooks';
import { memberActions } from './memberSlice';

function DeleteInstButton({ id }: any) {
  const dispatch = useAppDispatch();
  const onClick = useCallback(() => {
    dispatch(memberActions.removeInsRow(id));
  }, [dispatch, id]);

  return <NoneButton onClick={onClick}>삭제</NoneButton>;
}

export default DeleteInstButton;
