import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';
import * as diseaseApi from './diseaseApi';
import { RootState } from '../../app/store';
import isEqual from 'lodash/isEqual';

export const name = 'diseases';

interface DiseaseState {
  diseaseList: diseaseApi.DiseaseEntity[];
  treeData: any[];
  originData: any[];
  selected: string | string[];
  loading: boolean;
  error: string | undefined;
}
const initialState: DiseaseState = {
  diseaseList: [],
  treeData: [],
  originData: [],
  selected: '',
  loading: false,
  error: '',
};

const requestDiseases = createAsyncThunk<
  diseaseApi.DiseaseEntity[],
  diseaseApi.DiseaseRequestDto
>(`${name}/requestDisease`, async (params, { rejectWithValue }) => {
  try {
    const { data } = await diseaseApi.requestDiseases(params);
    return data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

const changeOrder = createAsyncThunk(
  `${name}/changeOrder`,
  async (params: any[], { rejectWithValue, dispatch }) => {
    try {
      const { data } = await diseaseApi.changeOrder({
        diseases: params.map((m, i) => {
          return {
            id: m.id || null,
            name: m.title,
            priority: i,
            subDiseases:
              m.children &&
              m.children.map((sm: any, j: number) => {
                return {
                  id: sm.subDiseaseId || null,
                  name: sm.title,
                  priority: j,
                };
              }),
          };
        }),
      });
      await dispatch(requestDiseases({ method: 'get' }));
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const diseaseSlice = createSlice({
  name,
  initialState,
  reducers: {
    setTreeData(state, action) {
      state.treeData = action.payload;
    },
    addTreeData(state, action) {
      state.treeData.push({ title: '', isEdit: true });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestDiseases.pending, (state) => {
        state.loading = true;
      })
      .addCase(requestDiseases.fulfilled, (state, action) => {
        state.loading = false;
        state.diseaseList = action.payload;
        const payloadData = action.payload.map(
          ({ name: title, subDisease, ...rest }) => ({
            title,
            isSub: false,
            ...rest,
            children: subDisease.map(({ subDiseaseName, ...rest }) => ({
              title: subDiseaseName,
              isSub: true,
              ...rest,
            })),
          }),
        );
        state.treeData = payloadData;
        state.originData = payloadData;
      })
      .addCase(requestDiseases.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(changeOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(changeOrder.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(changeOrder.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

const treeDataSelector = (state: RootState) => state[name].treeData;
const originTreeDataSelector = (state: RootState) => state[name].originData;
const compareTreeData = createSelector(
  treeDataSelector,
  originTreeDataSelector,
  (tree, origin) => {
    return !isEqual(tree, origin);
  },
);

export const diseaseActions = {
  requestDiseases,
  compareTreeData,
  changeOrder,
  ...diseaseSlice.actions,
};
export default diseaseSlice.reducer;
