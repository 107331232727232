export const parseDate = (date: string) => {
  return date ? date.slice(0, 10) : '-';
};
export function getYearStartDate() {
  let now = new Date();
  return new Date(now.getFullYear(), 0);
}
export function getMonthStartDate() {
  let now = new Date();
  return new Date(now.getFullYear(), now.getMonth());
}
export function getMonthLastDate() {
  let now = new Date();
  return new Date(now.getFullYear(), now.getMonth() + 1, 0);
}
export function getNowDate() {
  let now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate());
}
export function getBeforeDate(month: number, day: number) {
  let now = new Date();
  return new Date(
    now.getFullYear(),
    now.getMonth() - month,
    now.getDate() - day,
  );
}
export function getDateFormat(date: Date) {
  return `${date.getFullYear()}. ${fillZero(date.getMonth() + 1)}. ${fillZero(
    date.getDate(),
  )} `;
}
export function getDateReqFormat(date: Date | undefined) {
  if (date === undefined) {
    return undefined;
  }
  return `${date.getFullYear()}${fillZero(date.getMonth() + 1)}${fillZero(
    date.getDate(),
  )}`;
}
function fillZero(str: number) {
  return str >= 10 ? str : new Array(2).join('0') + str; //남는 길이만큼 0으로 채움
}
