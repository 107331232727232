/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react';
import { ReactTinyLink } from 'react-tiny-link';
import { defalutInformation } from '../../common/styles/InformationStyles';
import PropTypes from 'prop-types';

const re = new RegExp(/(((https?:)|(www.))[^\s]+)/g);
function DiseaseInformation({ information, children }) {
  const url = useMemo(() => {
    if (information.url) {
      return information.url.match(re) ? information.url : '';
    }
  }, [information.url]);

  return (
    <div style={{ margin: '10px 0px' }} css={defalutInformation}>
      {url ? (
        <ReactTinyLink
          cardSize="small"
          showGraphic={true}
          maxLine={2}
          minLine={1}
          header={information.title}
          description={information.type}
          url={url || ''}
          proxyUrl="https://cors.bridged.cc"
          width="100%"
        />
      ) : (
        <>
          {information.video && (
            <a
              href={information.video.filePath}
              style={{
                textDecoration: 'none',
                color: '#000',
                height: '100%',
                width: '100%',
                display: 'block',
              }}
              download
            >
              <div
                style={{
                  border: '1px solid rgb(225, 232, 237)',
                  padding: 10,
                  boxShadow: 'rgb(0 0 0 / 8%) 0px 4px 8px 0px',
                }}
              >
                <div style={{ fontWeight: 600 }}>{information.title}</div>
                <div style={{ margin: '20px 0px' }}>{information.type}</div>
                <div style={{ fontSize: 12, marginBottom: 10 }}>
                  {information.video.originalFileName}
                </div>
                <video
                  controls
                  src={information.video.filePath}
                  style={{ width: '50%' }}
                />
              </div>
            </a>
          )}
        </>
      )}
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          margin: '5px 0px',
        }}
      >
        {children}
      </div>
    </div>
  );
}

DiseaseInformation.defalutProps = {
  information: {
    url: '',
  },
  children: undefined,
};

DiseaseInformation.propsTypes = {
  information: PropTypes.object.isRequired,
  children: PropTypes.element,
};

export default DiseaseInformation;
