import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as subDiseaseApi from './subDiseaseApi';

export const name = 'sub-diseases';

const requestSubDiseases = createAsyncThunk<
  subDiseaseApi.SubDiseaseEntity,
  subDiseaseApi.SubDieaseRequestDto
>(`${name}/requestSubDisease`, async (params, { rejectWithValue }) => {
  try {
    const { data } = await subDiseaseApi.requestSubDiseases(params);
    return data;
  } catch (e) {
    return rejectWithValue(e.message);
  }
});

interface SubDiseaseState {
  subDisease: subDiseaseApi.SubDiseaseEntity | null;
  selected: string;
  loading: boolean;
  error: string;
}

const initialState: SubDiseaseState = {
  subDisease: null,
  selected: '',
  loading: false,
  error: '',
};

export const subDiseaseSlice = createSlice({
  name,
  initialState,
  reducers: {
    resetSubDisease(state) {
      state.subDisease = null;
      state.selected = '';
    },
    setSubDisease(state, action) {
      state.selected = action.payload;
    },
  },
  extraReducers: (bulider) => {
    bulider
      .addCase(requestSubDiseases.pending, (state) => {
        state.loading = true;
      })
      .addCase(requestSubDiseases.fulfilled, (state, action) => {
        state.loading = false;
        state.subDisease = action.payload;
      })
      .addCase(requestSubDiseases.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const SubDiseaseActions = {
  requestSubDiseases,
  ...subDiseaseSlice.actions,
};
export default subDiseaseSlice.reducer;
