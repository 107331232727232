import { Column } from '../../../components/CustomTable';
import { TableQuery } from '../../table/staticData/table';
export const initialDownloadQuery: TableQuery = {
  sort: 'createdDate,desc',
  page: '0',
  search: '',
  size: '10',
  type: 'publicKey',
};
export const columns: Column[] = [
  {
    name: '확인코드',
    property: 'publicKey',
  },
  {
    name: '서비스코드',
    property: 'serviceCode',
  },
  {
    name: '아임포트 UID',
    property: 'impUid',
  },
  {
    name: '날짜',
    property: 'createdDate',
  },
];
