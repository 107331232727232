import apiModule, { ApiRequest } from '../../common/wrapper/axios';
import { name as histogram } from './histogramSlice';
import { histogramType } from '../report/reportApi';
import { RequestDto } from '../news/newsApi';

export type HistogramReqestDto = RequestDto<histogramType[]>;
export type HistogramEntity = histogramType[];

export const requestHistogram: ApiRequest<
  HistogramReqestDto,
  HistogramEntity
> = (params = { method: 'get' }) => {
  const { method, id, data } = params;

  return apiModule.request({
    url: `sub-disease/${histogram}/${id}`,
    method,
    data,
  });
};
