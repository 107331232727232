/** @jsxImportSource @emotion/react */
import React from 'react';

import { parseDate } from '../../common/utils/dateUtils';
import Grid from '@material-ui/core/Grid';
import { defalutGrid } from '../../common/styles/GridStyles';

function Summary({ summary }: any) {
  return (
    <div css={[defalutGrid]}>
      {summary && (
        <Grid container>
          <Grid container>
            <Grid item xs={4}>
              <Grid container>
                <Grid item xs={4} className="title">
                  성별
                </Grid>
                <Grid item xs={8}>
                  {summary.sex}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container>
                <Grid item xs={4} className="title">
                  출생일
                </Grid>
                <Grid item xs={8}>
                  {parseDate(summary.birthDate)}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container>
                <Grid item xs={4} className="title">
                  등록 번호
                </Grid>
                <Grid item xs={8}>
                  {summary.requestNumber}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={4}>
              <Grid container>
                <Grid item xs={4} className="title">
                  검체 채취일
                </Grid>
                <Grid item xs={8}>
                  {parseDate(summary.collectionDate)}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container>
                <Grid item xs={4} className="title">
                  보고일
                </Grid>
                <Grid item xs={8}>
                  {parseDate(summary.reportDate)}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container>
                <Grid item xs={4} className="title">
                  의뢰기관명
                </Grid>
                <Grid item xs={8}>
                  {summary.medicalInstitution}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default Summary;
