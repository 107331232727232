import { useCallback, useEffect } from 'react';
import { Location } from 'history';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

interface RoutePathProps {
  setPath: ActionCreatorWithPayload<string | string[] | null>;
  stateKey: keyof RootState;
  defaultParams?: string;
}

function useRoutePath(
  { setPath, stateKey, defaultParams }: RoutePathProps,
  callback: (location: Location) => string | string[] | null,
) {
  const dispatch = useAppDispatch();
  const selected = useAppSelector((state) => state[stateKey].selected);
  const currentPath = useAppSelector((state) => state.menu.selected);

  //set redux
  // FIXME: setpath, callback rendering..
  const location = useLocation<Location>();

  useEffect(() => {
    if (callback(location)) dispatch(setPath(callback(location)));
  }, [dispatch, location]);

  //route push
  const history = useHistory();
  const routePath = useCallback(
    (url: string) => {
      history.push(url);
    },
    [history],
  );

  return { selected, routePath, currentPath };
}

export type RoutePathReturn = ReturnType<typeof useRoutePath>;
export default useRoutePath;
