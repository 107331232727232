import { Column } from '../../../components/CustomTable';
import { TableQuery } from '../../table/staticData/table';

export const initialAccessQuery: TableQuery = {
  sort: 'createdDate,desc',
  page: '0',
  search: '',
  size: '10',
  type: 'requestUrl',
};

export const columns: Column[] = [
  {
    name: '아이디',
    property: 'principal',
  },
  {
    name: 'IP 주소',
    property: 'ipAddress',
  },
  {
    name: '요청 API',
    property: 'requestUrl',
  },
  {
    name: '메서드',
    property: 'method',
  },
  {
    name: '응답상태',
    property: 'responseStatus',
    align: 'right',
  },
  {
    name: '상태',
    property: 'status',
    align: 'center',
  },
  {
    name: '요청 일시',
    property: 'createdDate',
  },
];
