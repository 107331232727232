import React from 'react';

import PushForm from '../../features/push/PushForm';

function Push() {
  return (
    <>
      <PushForm />
    </>
  );
}

export default Push;
