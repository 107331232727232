import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import * as informationApi from './informationApi';
import { RootState } from '../../app/store';

export const name = `sub-disease/information`;

interface InformationState {
  links: informationApi.InformationEntity;
  selected: string | string[];
  loading: boolean;
  error: string | undefined;
}
const initialState: InformationState = {
  links: [],
  selected: '',
  loading: false,
  error: '',
};

const requestInformation = createAsyncThunk<
  informationApi.InformationEntity,
  informationApi.InformationRequestDto,
  { state: RootState }
>(
  `${name}/requestInformation`,
  async (params, { rejectWithValue, dispatch, getState }) => {
    const id = Number(getState()['sub-diseases'].selected);
    try {
      const { data } = await informationApi.requestInformation({
        ...params,
        id: params.id ? params.id : id,
      });
      if (!['get', 'GET'].includes(params.method!)) {
        dispatch(
          requestInformation({
            method: 'get',
            id,
          }),
        );
        return [];
      }
      return data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  },
);

const informationSlice = createSlice({
  name,
  initialState,
  reducers: {
    setSelected(state, action) {
      state.selected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestInformation.pending, (state) => {
        state.loading = true;
      })
      .addCase(requestInformation.fulfilled, (state, action) => {
        state.loading = false;
        state.links = action.payload;
      })
      .addCase(requestInformation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

const selectSelector = (state: RootState) => state.information.selected;
const relatedLinksSelector = (state: RootState) => state.information.links;
const getLink = createSelector(
  selectSelector,
  relatedLinksSelector,
  (id, relatedLinksSelector) => {
    return (relatedLinksSelector || []).filter(
      (link) => link.id === Number(id),
    )[0];
  },
);

export const informationActions = {
  requestInformation,
  getLink,
  ...informationSlice.actions,
};
export default informationSlice.reducer;
