import styled from '@emotion/styled';

export const ReportContainer = styled.div`
  ${(props) => props.theme.font.body}
  .summary {
    padding: 5px 10px;
  }
  .container {
    margin: 20px 0px;
  }
  .title {
    ${(props) => props.theme.font.title1}
    padding: 10px;
    .sub-title {
      margin-left: 10px;
      color: #c4c4c4;
      font-weight: 400;
      ${(props) => props.theme.font.body}
    }
  }
  .description {
    padding: 0px 10px;
  }
  .summary-icon {
    display: inline-block;
    padding: 4px 8px;
    color: white;
    border-radius: 20px;
    //background: #63d451;
    margin-right: 10px;
  }
  .green {
    background: #63d451;
  }
  .red {
    background: #ff6161;
  }
  .yellow {
    background: #ffdc61;
  }
  .color-green {
    color: #63d451;
  }
  .color-red {
    color: #ff6161;
  }
  .color-yellow {
    color: #ffdc61;
  }
  .summary-description {
    display: inline-block;
    //color: #63d451;
    font-weight: 600;
    span {
      font-weight: 400;
      color: ${(props) => props.theme.palette.grayscale[0]};
    }
  }
  .table {
    text-align: center;
    display: inline-block;
    width: calc(33% - 3px);
    padding: 6px 0px;
    border-bottom: 1px solid ${(props) => props.theme.palette.grayscale[0]};
    border-right: 1px solid ${(props) => props.theme.palette.grayscale[0]};
  }
  .table1 {
    border-left: 1px solid ${(props) => props.theme.palette.grayscale[0]};
  }
  .header {
    ${(props) => props.theme.font.title1}
    background-color: ${(props) => props.theme.palette.grayscale[3]};
    border-top: 1px solid ${(props) => props.theme.palette.grayscale[0]};
  }
  .table2 {
    text-align: center;
    display: inline-block;
    width: calc(50% - 3px);
    padding: 6px 0px;
    border-bottom: 1px solid ${(props) => props.theme.palette.grayscale[0]};
    border-right: 1px solid ${(props) => props.theme.palette.grayscale[0]};
  }
  .row {
    display: flex;
  }
`;
