import apiModule, { ApiRequest } from '../../common/wrapper/axios';
import { TableEntity, TableQuery } from '../table/staticData/table';
import { stringify } from '../../common/utils/routeUtils';

import { ApiTokenType } from './staticData/server';

export interface ApiTokenCreateRequest {
  ipAddress: string;
  purpose: string;
}

export type ApiTokeEntity = TableEntity<ApiTokenType>;

export const getApiToken: ApiRequest<TableQuery, ApiTokeEntity> = (params) => {
  return apiModule.get(`/history/api-token?${stringify(params)}`);
};

export const createApiToken: ApiRequest<ApiTokenCreateRequest, ApiTokenType> = (
  params,
) => {
  return apiModule.post('/api-token', params);
};

export const removeApiToken: ApiRequest<number, void> = (params) => {
  return apiModule.delete(`/api-token/${params}`);
};
