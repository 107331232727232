import {
  AccessLogEntity,
  PushLogEntity,
  ReportDownloadLogEntity,
  ReportSyncLogEntity,
} from '../historyApi';
import { TableQuery } from '../../table/staticData/table';

export const initialApiTokenQuery: TableQuery = {
  sort: 'createdDate,desc',
  page: '0',
  search: '',
  size: '10',
  type: 'publicKey',
};

export type ReportSyncLogType = {
  id: number;
  apiToken: string;
  ipAddress: string;
  status: string;
  message: string;
  publicKey: string;
  serviceCode: string;
  createdDate: string;
};

export type ReportDownloadLogType = {
  id: number;
  impUid: string;
  publicKey: string;
  serviceCode: string;
  createdDate: string;
};

export type AccessLogType = {
  errorMessage: string;
  id: number;
  ipAddress: string;
  method: string;
  principal: string;
  requestUrl: string;
  responseStatus: string;
  status: string;
  createdDate: string;
};
export type PushLogType = {
  id: number;
  body: string;
  targetToken: string;
  title: string;
  createdDate: string;
};

export const initialReportSyncLog: ReportSyncLogEntity = {
  content: [],
  empty: true,
  first: true,
  last: true,
  number: 0,
  numberOfElements: 0,
  pageable: {
    offset: 0,
    pageNumber: 0,
    pageSize: 0,
    paged: true,
    sort: {
      empty: true,
      sorted: true,
      unsorted: true,
    },
    unpaged: true,
  },
  size: 0,
  sort: {
    empty: true,
    sorted: true,
    unsorted: true,
  },
  totalElements: 0,
  totalPages: 0,
};

export const initialReportDownloadLog: ReportDownloadLogEntity = {
  content: [],
  empty: true,
  first: true,
  last: true,
  number: 0,
  numberOfElements: 0,
  pageable: {
    offset: 0,
    pageNumber: 0,
    pageSize: 0,
    paged: true,
    sort: {
      empty: true,
      sorted: true,
      unsorted: true,
    },
    unpaged: true,
  },
  size: 0,
  sort: {
    empty: true,
    sorted: true,
    unsorted: true,
  },
  totalElements: 0,
  totalPages: 0,
};

export const initialAccessLog: AccessLogEntity = {
  content: [],
  empty: true,
  first: true,
  last: true,
  number: 0,
  numberOfElements: 0,
  pageable: {
    offset: 0,
    pageNumber: 0,
    pageSize: 0,
    paged: true,
    sort: {
      empty: true,
      sorted: true,
      unsorted: true,
    },
    unpaged: true,
  },
  size: 0,
  sort: {
    empty: true,
    sorted: true,
    unsorted: true,
  },
  totalElements: 0,
  totalPages: 0,
};

export const initialPushLog: PushLogEntity = {
  content: [],
  empty: true,
  first: true,
  last: true,
  number: 0,
  numberOfElements: 0,
  pageable: {
    offset: 0,
    pageNumber: 0,
    pageSize: 0,
    paged: true,
    sort: {
      empty: true,
      sorted: true,
      unsorted: true,
    },
    unpaged: true,
  },
  size: 0,
  sort: {
    empty: true,
    sorted: true,
    unsorted: true,
  },
  totalElements: 0,
  totalPages: 0,
};
