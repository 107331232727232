import React from 'react';
import { useFormik } from 'formik';
import Form from '../../components/SubmitFormWithFormik';
import { serCodeFields } from './staticData/serCode';

import { boardForm } from '../../common/styles/FormStyles';

const initialServiceFormData = {
  code: '',
  description: '',
};

function CheckupForm({ id, data = initialServiceFormData, onSubmit }: any) {
  const formik = useFormik({
    initialValues: {
      code: data.code,
      description: data.description,
    },
    onSubmit: (v) => {
      onSubmit(v);
    },
  });

  return (
    <Form formik={formik} formData={serCodeFields} css={boardForm}>
      <button type="submit" style={{ marginBottom: 0 }}>
        {`${Number(id) > 0 ? '수정' : '저장'}하기`}
      </button>
    </Form>
  );
}

export default CheckupForm;
