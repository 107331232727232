import React from 'react';
import { RoutePropsTypes } from '../../app/share/routeConfig';

import MyRoute from '../../app/share/MyRoute';

import disease from './Disease';
import news from './News';
import push from './Push';
import trigger from './Trigger';

function Service({ routes }: RoutePropsTypes) {
  return (
    <>
      <MyRoute routes={routes} />
    </>
  );
}

export default Service;

export const service = {
  disease,
  news,
  push,
  trigger,
};
