import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import * as pushApi from './pushApi';
import {
  ITrigger,
  ITriggerCreateReq,
  PushRequestDto,
  PushRequestEntity,
} from './type';

export const name = 'fcm';

interface PushState {
  selected: string | string[];
  loading: boolean;
  error: string | undefined;
  trigger: ITriggerState;
}

interface ITriggerState {
  triggers: ITrigger[];
  loading: boolean;
  error: string | undefined;
}

const initialState: PushState = {
  selected: '',
  trigger: { triggers: [], loading: false, error: undefined },
  loading: false,
  error: '',
};

const requestPush = createAsyncThunk<PushRequestEntity, PushRequestDto>(
  `${name}/requestPush`,
  async (params, thunkApi) => {
    try {
      const { data } = await pushApi.requestPush(params);
      return data;
    } catch (e) {
      return thunkApi.rejectWithValue(e.message);
    }
  },
);

const fetchPushTriggers = createAsyncThunk<ITrigger[], void>(
  `${name}/push-trigger`,
  async (_, thunkApi) => {
    try {
      const { data } = await pushApi.fetchPushTrigger();
      return data;
    } catch (e) {
      return thunkApi.rejectWithValue(e.message);
    }
  },
);

const createPushTriggers = createAsyncThunk<ITrigger, ITriggerCreateReq>(
  `${name}/create-push-trigger`,
  async (params, thunkApi) => {
    try {
      const { data } = await pushApi.createPushTrigger(params);
      return data;
    } catch (e) {
      return thunkApi.rejectWithValue(e.message);
    }
  },
);

const pushSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(requestPush.pending, (state) => {
        state.loading = true;
      })
      .addCase(requestPush.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(requestPush.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(fetchPushTriggers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPushTriggers.fulfilled, (state, action) => {
        state.loading = false;
        state.trigger.triggers = action.payload;
      })
      .addCase(fetchPushTriggers.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const pushActions = {
  requestPush,
  fetchPushTriggers,
  createPushTriggers,
  ...pushSlice.actions,
};
export default pushSlice.reducer;
