import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as userApi from './userApi';
export const name = 'user';

interface UserState {
  user: userApi.UserEntity | null;
  selected: string | string[];
  loading: boolean;
  error: string | undefined;
}
const initialState: UserState = {
  user: null,
  selected: '',
  loading: false,
  error: '',
};

export const getUser = createAsyncThunk(
  `${name}/getUser`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await userApi.getUser();
      return data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  },
);

const userSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default userSlice.reducer;
