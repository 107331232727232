import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';

import * as newsApi from './newsApi';
import { initialNews } from './staticData/news';
import { RootState } from '../../app/store';

export const name = 'news';

interface NewsState {
  newsList: newsApi.NewsTableEntity;
  selected: string | string[];
  loading: boolean;
  error: string | undefined;
}

const initialState: NewsState = {
  newsList: initialNews,
  selected: '',
  loading: false,
  error: '',
};

const requestNews = createAsyncThunk<
  newsApi.NewsTableEntity | null,
  newsApi.NewsRequestDto,
  {
    state: RootState;
  }
>(`${name}/requestNews`, async ({ query, ...params }, thunkApi) => {
  try {
    const { data } = await newsApi.requestNews({
      ...params,
      query: ['get', 'GET'].includes(params.method!) ? query : undefined,
    });

    if (!['get', 'GET'].includes(params.method!)) {
      thunkApi.dispatch(
        requestNews({
          method: 'get',
          query: query,
        }),
      );
    }

    return data;
  } catch (e) {
    return thunkApi.rejectWithValue(e.message);
  }
});

const newsSlice = createSlice({
  name,
  initialState,
  reducers: {
    setSelected(state, action) {
      state.selected = action.payload;
    },
    resetSelected(state) {
      state.selected = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestNews.pending, (state) => {
        state.loading = true;
      })
      .addCase(requestNews.fulfilled, (state, action) => {
        state.loading = false;
        if (['get', 'GET'].includes(action.meta.arg.method!)) {
          state.newsList = action.payload as newsApi.NewsTableEntity;
        }
        if (['put'].includes(action.meta.arg.method!)) {
          alert('수정 완료');
        } else if (['post'].includes(action.meta.arg.method!)) {
          alert('등록 완료');
        }
      })
      .addCase(requestNews.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

const selectSelector = (state: RootState) => state.news.selected;
const newsListSelector = (state: RootState) => state.news.newsList.content;
const newsSelector = createSelector(
  selectSelector,
  newsListSelector,
  (id, newsListSelector) =>
    (newsListSelector || []).filter((news) => news.id === Number(id))[0],
);

export const newsActions = {
  requestNews,
  newsSelector,
  ...newsSlice.actions,
};
export default newsSlice.reducer;
