import React, { useEffect, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  getReportCheckup,
  getServiceCode,
  historyActions,
} from '../../features/history/historySlice';
import DataGrid from 'react-data-grid';
import { columns } from '../../features/history/staticData/checkup';
import { DataGridContainter } from '../../features/disease/Histogram';
import CheckupModal from '../../features/history/CheckupModal';
import { Button2 } from '../member';

function Checkup() {
  const dispatch = useAppDispatch();
  const checkups = useAppSelector((state) =>
    historyActions.checkupSelector(state),
  );

  useEffect(() => {
    dispatch(getReportCheckup());
    dispatch(getServiceCode());
  }, [dispatch]);

  const handleClick = useCallback(() => {
    dispatch(historyActions.setCheckupSelect('-1'));
  }, [dispatch]);

  return (
    <>
      <div
        style={{ margin: `10px 0px`, display: 'flex', justifyContent: 'right' }}
      >
        <Button2 onClick={handleClick}>보고서 추가</Button2>
      </div>
      <DataGridContainter>
        <DataGrid
          columns={columns}
          rows={checkups}
          // onRowsChange={handleRowChange}
          className="rdg-light"
          // emptyRowsRenderer={EmptyRowsRenderer}
        />
      </DataGridContainter>
      <CheckupModal />
    </>
  );
}

export default Checkup;
