import React from 'react';

import { useAppDispatch } from '../../app/hooks';

import { reportContentActions } from './reportContetSlice';
import { RoutePathReturn } from '../../common/hooks/useRoutePath';

import DiseaseTab from './DiseaseTab';
import SubDiseaseTab from './SubDiseaseTab';
import ReportContent from './ReportContent';

function Report({ selected }: RoutePathReturn) {
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (selected)
      dispatch(
        reportContentActions.fetchReportByPublicKey({
          publicKey: selected,
        }),
      );
  }, [dispatch, selected]);

  return (
    <div style={{ marginTop: 30 }}>
      <DiseaseTab />
      <div
        style={{ border: '1px solid #DDDDDD', padding: 10, margin: '10px 0px' }}
      >
        <SubDiseaseTab />
        <ReportContent />
      </div>
    </div>
  );
}

export default React.memo(Report);
