import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { dashboardActions } from '../../features/dashboard/dashboardSlice';
import { DashboardTitle } from '../../common/styles/dashboardStyles';
import { getMonthStartDate, getNowDate } from '../../common/utils/dateUtils';
import CalendarPicker from '../../components/CalendarPicker';
import LineChart from '../../features/dashboard/LineChart';

function DashBoard() {
  const dispatch = useAppDispatch();
  const { reportsCnt, appCnt } = useAppSelector((state) => state.dashboard);
  const [startDate, setStartDate] = useState(getMonthStartDate());
  const [endDate, setEndDate] = useState(getNowDate());
  const [startDate2, setStartDate2] = useState(getMonthStartDate());
  const [endDate2, setEndDate2] = useState(getNowDate());
  const appChartRef = useRef<any>(null);
  const reportChartRef = useRef<any>(null);

  useEffect(() => {
    dispatch(
      dashboardActions.fetchStatCnt({
        statType: 'reports',
        startDate: startDate,
        endDate: endDate,
      }),
    );
    dispatch(
      dashboardActions.fetchAppDownloadCnt({
        statType: 'app',
      }),
    );
    return () => {
      dispatch(dashboardActions.resetChart());
    };
  }, [dispatch]);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          marginBottom: 14,
          marginTop: 14,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <DashboardTitle>앱 다운로드 횟수</DashboardTitle>
        </div>
        <CalendarPicker
          statType={'app'}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          chartRef={appChartRef}
        />
      </div>
      <div style={{ border: '1px solid #DDDDDD', padding: '20px 50px' }}>
        <div style={{ display: 'flex', marginBottom: '20px' }}>
          <DashboardTitle>{`총 다운로드 수 ${appCnt}건`}</DashboardTitle>
        </div>
        <LineChart
          statType="app"
          endDate={endDate}
          startDate={startDate}
          chartRef={appChartRef}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          marginBottom: 14,
          marginTop: 14,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <DashboardTitle>검사지 다운로드 횟수</DashboardTitle>
        </div>
        <CalendarPicker
          statType={'reports'}
          startDate={startDate2}
          endDate={endDate2}
          setStartDate={setStartDate2}
          setEndDate={setEndDate2}
          chartRef={reportChartRef}
        />
      </div>
      <div
        style={{
          border: '1px solid #DDDDDD',
          padding: '20px 50px',
        }}
      >
        <div style={{ display: 'flex', marginBottom: '20px' }}>
          {reportsCnt ? (
            <>
              <DashboardTitle
                style={{ marginRight: '40px' }}
              >{`전체 검사지 수 ${reportsCnt.reportCnt}건`}</DashboardTitle>
              <DashboardTitle>{`다운로드 된 검사지 수 ${reportsCnt.reportDownload}건`}</DashboardTitle>
            </>
          ) : null}
        </div>
        <LineChart
          statType="reports"
          endDate={endDate2}
          startDate={startDate2}
          chartRef={reportChartRef}
          options={{
            scales: {
              y: {
                ticks: {
                  stepSize: 1,
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
}

export default DashBoard;
