import { Theme, css } from '@emotion/react';
import styled from '@emotion/styled';

export const defaultForm = (theme: Theme) => css`
  display: flex;
  flex-direction: column;
  width: 400px;
  .MuiTextField-root {
    margin-bottom: 16px;
  }
  button[type='submit'] {
    height: 56px;
    background-color: ${theme.palette.primary2};
    color: ${theme.palette.light};
    border: none;
    border-radius: 4px;
    font-size: 16px;
  }
  .none-button {
    background: none;
    border: none;
    font-size: 12px;
    cursor: pointer;
    border: 1px solid #dddddd;
    padding: 4px 10px;
    border-radius: 4px;
    margin-left: 5px;
  }
`;

export const searchForm = () => css`
  width: 100%;
`;

export const diseaseForm = () => css`
  width: 100%;
  .MuiTextField-root {
    margin-bottom: 20px;
  }
`;

export const boardForm = () => css`
  width: 100%;
  button {
    margin: 20px 0px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
  }
  .file_btn {
    margin: 0px;
    width: 20%;
    background-color: #fff;
    border: 1px solid #68aacf;
    color: #68aacf;
    margin-left: 6px;
    font-size: 16px;
    font-weight: 400;
    padding: 16.5px 0px;
    border-radius: 4px;
  }
  .MuiTextField-root.file_input {
    width: 80%;
    margin: 0px;
  }
  .MuiAutocomplete-endAdornment {
    top: -5px;
  }
  .filebox {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  .filebox input[type='file'] {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
  .file_label {
    cursor: pointer;
    padding: 16.5px 0px;
    text-align: center;
    border-radius: 4px;
  }
`;

export const FormLabel = styled.label`
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
  position: absolute;
  top: -5px;
  left: 10px;
  padding: 0px 5px;
  background-color: #fff;
`;

export const FormContainer = styled.div`
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  padding: 15px 10px;
  margin-bottom: 20px;
`;
