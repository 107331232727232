import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import PopUp from '../../components/PopUp';
import { resetAuthTokenAndHeader } from '../auth/authService';
import { deleteMember, updateProfile } from '../member/memberSlice';

function ProfilePopup({ setOpen }: any) {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [institution, setInstitution] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');

  const { user } = useSelector((state: RootState) => state.user);

  const memberInputList = [
    { label: '이메일', state: email, setState: setEmail, type: 'text' },
    { label: '이름', state: name, setState: setName, type: 'text' },
    {
      label: '소속기관',
      state: institution,
      setState: setInstitution,
      type: 'text',
    },
    {
      label: '전화번호',
      state: phoneNumber,
      setState: setPhoneNumber,
      type: 'input',
      inputType: 'number',
    },
    {
      label: '비밀번호 변경',
      state: password,
      setState: setPassword,
      type: 'password',
      placeholder: '비밀번호',
      inputType: 'password',
    },
  ];

  useEffect(() => {
    if (user) {
      setEmail(user.email);
      setName(user.nickname);
      setInstitution(user.institution);
      setPhoneNumber(user.phoneNumber);
    }
  }, [setEmail, setName, setInstitution, setPhoneNumber, user]);

  const handleClickPopupButton = useCallback(
    (e) => {
      e.preventDefault();

      dispatch(
        updateProfile({
          id: user?.id,
          data: { phoneNumber: phoneNumber, password: password },
        }),
      );
    },
    [user, phoneNumber, password, dispatch],
  );

  const handleClickDeleteMember = useCallback(() => {
    dispatch(
      deleteMember({
        id: user?.id,
      }),
    );
    resetAuthTokenAndHeader().then((v) => {
      if (v) {
        window.location.replace('/');
      }
    });
  }, [dispatch, user]);

  return (
    <PopUp
      onClose={() => setOpen(false)}
      inputList={memberInputList}
      title={'내정보'}
      buttonTitle={'변경사항 저장'}
      onClick={handleClickPopupButton}
      bottomComponent={
        <button
          type="button"
          onClick={handleClickDeleteMember}
          style={{ all: 'unset', marginTop: 20, cursor: 'pointer' }}
        >
          <span
            style={{
              fontSize: 16,
              textDecoration: 'underline',
              color: '#cfcfcf',
            }}
          >
            회원탈퇴
          </span>
        </button>
      }
    ></PopUp>
  );
}

export default ProfilePopup;
