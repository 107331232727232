import apiModule, { ApiRequest } from '../../common/wrapper/axios';
import { name } from './dashboardSlice';
import { stringify } from 'query-string';
import { getDateReqFormat } from '../../common/utils/dateUtils';

export interface Datasets {
  data: number[];
  label: string;
}

export interface StatEntity {
  data: {
    datasets: Datasets[];
    labels: string[];
  };
}

export interface StatQuery {
  endDate?: Date;
  startDate?: Date;
  statType: 'reports' | 'app';
}

export interface StatCntEntity {
  reportCnt: number;
  reportDownload: number;
}

export const fetchReportStat: ApiRequest<StatQuery, StatEntity> = (
  params = { statType: 'reports' },
) => {
  return apiModule.get(
    `${name}/${params.statType}/stat?${stringify({
      startDate: getDateReqFormat(params.startDate),
      endDate: getDateReqFormat(params.endDate),
    })}`,
  );
};

export const fetchAppStatCnt: ApiRequest<StatQuery, number> = (
  params = { statType: 'app' },
) => {
  return apiModule.get(`${name}/app/stat/count`);
};

export const fetchReportStatCnt: ApiRequest<StatQuery, StatCntEntity> = (
  params = { statType: 'reports' },
) => {
  return apiModule.get(`${name}/reports/stat/count`);
};
