// import * as pdfjs from 'pdfjs-dist/legacy/build/pdf';

// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@2.5.207/legacy/build/pdf.worker.min.js`;

// pdfjs.then((pdfjs) => console.log(pdfjs));

export default class PDFJs {
  constructor() {
    this.iframe = document.createElement('iframe');
  }

  init = (source, element) => {
    // const iframe = document.createElement('iframe');

    this.iframe.src = `/pdfjs-2.12.313-dist/web/viewer.html?file=${source}`;
    this.iframe.width = '100%';
    this.iframe.height = '100%';

    element.appendChild(this.iframe);
  };
}
