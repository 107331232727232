import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { menus, Menu } from './staticData/menu';
import { pathnameFactory } from '../../common/utils/routeUtils';

const name = 'menu';

//types
interface MenuState {
  menus: Menu[];
  selected: string | string[];
}

const initialState: MenuState = {
  menus,
  selected: '/dashboard',
};

const menuSlice = createSlice({
  name,
  initialState,
  reducers: {
    setCurrentPath(state, action: PayloadAction<string>) {
      state.selected = action.payload;
    },
  },
});

const menuSelector = (state: RootState) =>
  state.menu.menus.map((menu) => ({
    ...menu,
    path: menu.url,
    url: pathnameFactory(menu.url),
  }));
const currentPathSelector = (state: RootState) => state.menu.selected;
const subMenuSelector = createSelector(
  currentPathSelector,
  (currentPath: string | string[]) =>
    menus.filter((f) => currentPath.includes(f.url))[0].subMenu || [],
);

export const menuActions = {
  menuSelector,
  currentPathSelector,
  subMenuSelector,
  ...menuSlice.actions,
};
export default menuSlice.reducer;
