// client
const CLIENT = 'GC녹십자지놈' as const;

// user
const EMAIL = '메일 주소' as const;
const PW = '비밀번호' as const;

// common
const LOGIN = 'LOGIN' as const;

export const login = {
  EMAIL_PLACEHOLDER: `${EMAIL}`,
  PW_PLACEHOLDER: `${PW}`,
  SUBMIT_TITLE: `${LOGIN}`,
  FIND_PW_TITLE: `${PW} 찾기`,
} as const;

export const footer = {
  COPYRIGHT_TITLE: `Copyright 2021. ${CLIENT} Inc. All Right Reserved.`,
} as const;

export const menu = {
  DASHBOARD: '전체 통계',
  REPORTS: '검사결과 조회',
  SERVICE: '앱 관리',
  SERVICE_DISEASE: '질환 관리',
  SERVICE_NEWS: '건강 상식',
  SERVICE_PUSH: 'PUSH 메시지',
  SERVICE_TRIGGER: 'PUSH 트리거',
  MEMBER: '사용자 관리',
  MEMBER_USER: '사용자 관리',
  MEMBER_INSTITUTION: '기관 관리',
  HISTORY: '시스템',
  HISTORY_SERVER: '데이터 연동 서버 관리',
  HISTORY_REPORT: '보고서 데이터 연동 로그',
  HISTORY_ACCESS: '접근 이력 로그',
  HISTORY_DOWNLOAD: '다운로드 내역 로그',
  HISTORY_PUSH: 'PUSH 내역 로그',
  HISTORY_CHECKUP: '검사지 관리',
  HISTORY_SERCODE: '서비스 코드 관리',
} as const;
