//types
type localStorageNameType = 'auth';

// functions
const set = (name: localStorageNameType, token: string) => {
  sessionStorage.setItem(name, token);
};
const get = (name: localStorageNameType) => {
  return sessionStorage.getItem(name);
};
const remove = (name: localStorageNameType) => {
  sessionStorage.removeItem(name);
};

const _ = {
  set,
  get,
  remove,
};

export default _;
