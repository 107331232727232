import React, { useCallback, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {
  getInstitutions,
  requestInstitutions,
  memberActions,
} from '../../features/member/memberSlice';
import { institutioncolumns } from './staticData/member';
import DataGrid from 'react-data-grid';
import { NoneButton } from '../disease/Tree';

function InstitutionTable() {
  const dispatch = useAppDispatch();
  const institutionList = useAppSelector(
    (state) => state.member.institutionList,
  );

  useEffect(() => {
    dispatch(getInstitutions());
  }, [dispatch]);

  const handleSave = useCallback(() => {
    dispatch(
      requestInstitutions({
        data: {
          institutions: institutionList.map((m) => {
            return {
              institutionName: m.institutionName,
              id: typeof m.id === 'string' ? null : m.id,
            };
          }),
        },
      }),
    );
  }, [institutionList, dispatch]);

  const handleRowChange = useCallback(
    (v) => {
      //setRow
      dispatch(memberActions.setInsRow(v));
    },
    [dispatch],
  );

  return (
    <>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: 20,
        }}
      >
        <NoneButton onClick={() => dispatch(memberActions.addInsRow())}>
          행추가
        </NoneButton>
        <NoneButton onClick={handleSave}>저장</NoneButton>
      </div>
      <DataGrid
        columns={institutioncolumns}
        rows={institutionList}
        className="grid rdg-light"
        onRowsChange={handleRowChange}
        emptyRowsRenderer={() => (
          <div style={{ textAlign: 'center' }}>No Data...</div>
        )}
      />
    </>
  );
}

export default InstitutionTable;
