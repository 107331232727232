import React from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';

type optionMenuType = {
  options: any;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

function OptionMenu({ options, setOpen }: optionMenuType) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ float: 'right', marginTop: 10 }}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MenuIcon style={{ color: '#fff' }} />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {options.map(({ label, component: Component }: any) => (
          <Component key={label} onClose={handleClose} setOpen={setOpen} />
        ))}
      </Menu>
    </div>
  );
}

export default OptionMenu;
