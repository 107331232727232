import React from 'react';
import { useFormik } from 'formik';
import Form from '../../components/SubmitFormWithFormik';
import { subDiseaseField } from './staticData/subDisease';
import { boardForm } from '../../common/styles/FormStyles';
// import ImageUpload from '../../components/ImageUpload';
import { SubDiseaseEntity } from './subDiseaseApi';
import { SubDiseaseActions } from './subDiseaseSlice';
import { useAppDispatch } from '../../app/hooks';

interface SubDiseaseFormProps {
  data: SubDiseaseEntity;
}

function SubDiseaseForm({ data }: SubDiseaseFormProps) {
  const dispatch = useAppDispatch();
  const formik = useFormik({
    initialValues: {
      summary: data.summary,
      detail: data.detail,
      advice: data.advice,
      risk: data.risk,
      target: data.target,
      period: data.period,
      test: data.test,
      meanKorean: data.meanKorean,
      max: data.max,
      top5: data.top5,
      source: data.source,
      maxAllele: data.maxAllele,
      iconId: data.icon ? data.icon.id : null,
    },
    onSubmit: (v) => {
      dispatch(
        SubDiseaseActions.requestSubDiseases({
          method: 'put',
          id: data.id,
          data: v,
        }),
      );
    },
  });

  return (
    <Form formik={formik} formData={subDiseaseField} css={boardForm}>
      {/*<ImageUpload*/}
      {/*  formik={formik}*/}
      {/*  defaultImage={data.icon}*/}
      {/*  title="아이콘"*/}
      {/*  size="100"*/}
      {/*  valueType="iconId"*/}
      {/*/>*/}
      <button type="submit">글 수정하기</button>
    </Form>
  );
}

export default React.memo(SubDiseaseForm);
