import { Column, SelectCellFormatter } from 'react-data-grid';
import EditCheckupButton from '../EditCheckupButton';
import { FieldProps } from '../../../components/SubmitFormWithFormik';

export const columns: Column<any>[] = [
  {
    name: '검사지 종류',
    key: 'title',
    headerCellClass: 'cell-center',
  },
  {
    name: '상세 결과 제공',
    key: 'hasDetail',
    headerCellClass: 'cell-center',
    formatter({ row, onRowChange, isCellSelected }) {
      return (
        <SelectCellFormatter
          tabIndex={-1}
          value={row.hasDetail ? row.hasDetail : false}
          onChange={() => {
            onRowChange({ ...row, hasDetail: !row.hasDetail });
          }}
          onClick={(e) => e.stopPropagation()}
          isCellSelected={isCellSelected}
        />
      );
    },
  },
  {
    name: 'PDF 보고서 제공',
    key: 'hasPdf',
    headerCellClass: 'cell-center',
    formatter({ row, onRowChange, isCellSelected }) {
      return (
        <SelectCellFormatter
          tabIndex={-1}
          value={row.hasPdf ? row.hasPdf : false}
          onChange={() => {
            onRowChange({ ...row, hasPdf: !row.hasPdf });
          }}
          onClick={(e) => e.stopPropagation()}
          isCellSelected={isCellSelected}
        />
      );
    },
  },
  {
    name: '수정',
    key: 'action',
    headerCellClass: 'cell-center',
    cellClass: 'cell-center',
    formatter({ row }) {
      return <EditCheckupButton id={row.id} />;
    },
    width: 50,
  },
];

export const checkupFields: FieldProps[] = [
  {
    type: 'text',
    name: 'title',
    variant: 'outlined',
    label: '검사지 종류',
    placeholder: '검사지 종류를 입력하세요',
  },
  {
    type: 'text',
    name: 'hasDetail',
    label: '상세 결과 제공',
    variant: 'outlined',
    select: true,
    option: [
      { value: 'true', label: '제공' },
      { value: 'false', label: '미제공' },
    ],
  },
  {
    type: 'text',
    name: 'hasPdf',
    label: 'PDF 보고서 제공',
    variant: 'outlined',
    select: true,
    option: [
      { value: 'true', label: '제공' },
      { value: 'false', label: '미제공' },
    ],
  },
  {
    type: 'text',
    name: 'summary',
    label: '요약',
    placeholder: '요약을 입력하세요',
    variant: 'outlined',
    multiline: true,
    rows: 4,
  },
  {
    type: 'text',
    name: 'description',
    label: '설명',
    placeholder: '설명을 입력하세요',
    variant: 'outlined',
    multiline: true,
    rows: 4,
  },
];
