import React, { useCallback } from 'react';
import MenuItem from '@material-ui/core/MenuItem';

const ProfileButton = React.forwardRef((props: any, ref: any) => {
  const handleClickProfile = useCallback(() => {
    props.setOpen(true);
    props.onClose();
  }, [props]);

  return (
    <MenuItem ref={ref} onClick={handleClickProfile}>
      프로필
    </MenuItem>
  );
});

export default ProfileButton;
