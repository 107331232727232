import { Route } from 'react-router-dom';
import { RouteConfigWithSubRoute } from './routeConfig';
//types

function SubRoute(route: RouteConfigWithSubRoute) {
  return (
    <Route
      path={route.path}
      render={(props) => <route.component {...props} routes={route.routes} />}
    />
  );
}

export default SubRoute;
