/** @jsxImportSource @emotion/react */
import React from 'react';
import map from 'lodash/map';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { reportContentActions } from './reportContetSlice';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { defaultTab } from '../../common/styles/TabStyles';
import styled from '@emotion/styled';

function DiseaseTab() {
  const dispatch = useAppDispatch();
  const [diseaseList, value] = useAppSelector((state) => [
    reportContentActions.getDiseasesList(state),
    reportContentActions.selectedDiseaseSelector(state),
  ]);

  return (
    <section css={[defaultTab]}>
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        value={value}
        onChange={(e, v) =>
          dispatch(reportContentActions.setSelectDisease(String(v)))
        }
      >
        {map(diseaseList, ({ id, label, isWarn }) => {
          return (
            <Tab
              key={id}
              label={
                <TabDiv>
                  <div>{label}</div>
                  {isWarn ? <Indicator /> : null}
                </TabDiv>
              }
              value={String(id)}
            />
          );
        })}
      </Tabs>
    </section>
  );
}

const TabDiv = styled.div`
  flex-direction: row;
  display: flex;
`;
const Indicator = styled.div`
  background-color: ${(props) => props.theme.palette.primary1};
  width: 4px;
  height: 4px;
  border-radius: 16px;
`;

export default DiseaseTab;
