import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import cn from 'classnames';

import Container from '@material-ui/core/Container';
import Login from './features/auth/Login';
import Menu from './features/menu/Menu';

import Route from './app/share/MyRoute';
import { routes } from './app/share/routeConfig';
import useLocalStorageToken from './common/hooks/useLocalStorageToken';
import {
  AbsoluteContainer,
  Header,
  Footer,
} from './common/styles/CommonStyles';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { getUser } from './features/user/userSlice';

function App() {
  const dispatch = useAppDispatch();
  const [isAuth] = useLocalStorageToken();
  const user = useAppSelector((state) => state.user.user);

  React.useEffect(() => {
    if (isAuth) {
      dispatch(getUser());
    }
  }, [dispatch, isAuth]);

  return (
    <AbsoluteContainer>
      <Header />
      <AbsoluteContainer>
        <Container fixed className={cn({ loginContainer: !isAuth })}>
          {!isAuth ? (
            <Login />
          ) : (
            <BrowserRouter>
              {user && (
                <>
                  <Menu user={user} />
                  <Route routes={routes} />
                </>
              )}
            </BrowserRouter>
          )}
        </Container>
      </AbsoluteContainer>
      <Footer>Copyright 2021. GC녹십자지놈 Inc. All Right Reserved.</Footer>
    </AbsoluteContainer>
  );
}

export default App;
