import React from 'react';
import { useFormik } from 'formik';
import Form from '../../components/SubmitFormWithFormik';
import { checkupFields } from './staticData/checkup';
import ImageUpload from '../../components/ImageUpload';
import CheckupAutoComplete from './CheckupAutoComplete.';
import { boardForm } from '../../common/styles/FormStyles';

const initialCheckupFormData = {
  title: '',
  hasDetail: false,
  hasPdf: false,
  summary: '',
  description: '',
  image: '',
  imageId: null,
  serviceCodes: [],
};

function CheckupForm({ id, data = initialCheckupFormData, onSubmit }: any) {
  const formik = useFormik({
    initialValues: {
      title: data.title,
      hasDetail: data.hasDetail,
      hasPdf: data.hasPdf,
      summary: data.summary,
      description: data.description,
      image: data.image,
      imageId: data.image ? data.image.id : null,
      serviceCodes: data.serviceCodes,
    },
    onSubmit: (v) => {
      onSubmit(v);
    },
  });

  return (
    <Form
      formik={formik}
      formData={checkupFields}
      disableArr={['hasPdf', 'hasDetail']}
      css={boardForm}
    >
      <CheckupAutoComplete
        formik={formik}
        valueType="serviceCodes"
        defaultValue={data.serviceCodes}
      />
      <ImageUpload
        formik={formik}
        defaultImage={data.image}
        title="이미지"
        size="200"
        valueType="imageId"
      />
      <button type="submit" style={{ marginBottom: 0 }}>
        {`${Number(id) > 0 ? '수정' : '저장'}하기`}
      </button>
    </Form>
  );
}

export default CheckupForm;
