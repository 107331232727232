import React from 'react';
import ReactPlayer from 'react-player/lazy';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import { FileEntity } from '../features/file/fileApi';
import { fileActions } from '../features/file/fileSlice';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { getHash } from '../common/utils/stringUtils';

interface PlayerProps {
  formik: any;
  defaultVideo: FileEntity | null | undefined;
  valueType: string;
}
interface FileState {
  hash?: string;
  file?: File;
}

function Player({ formik, defaultVideo, valueType }: PlayerProps) {
  const dispatch = useAppDispatch();
  const files = useAppSelector((state) => state.files.files);
  const [file, setFile] = React.useState<FileState>();

  const [filePath, setFilePath] = React.useState(
    defaultVideo ? defaultVideo.filePath : '',
  );

  const handleChange = (e: any) => {
    const hash = getHash();

    setFile({ file: e.target.files[0], hash });

    const formData = new FormData();
    formData.append('file', e.target.files[0]);

    dispatch(
      fileActions.requestFiles({
        method: 'post',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
        hash,
      }),
    );
  };

  const selectedFile = React.useMemo(() => {
    if (files.length > 0 && file) {
      return files.filter((f) => {
        return f.hash === file.hash;
      })[0];
    }
  }, [files, file]);

  React.useEffect(() => {
    if (selectedFile) {
      setFilePath(selectedFile.filePath);
      formik.setFieldValue('videoId', selectedFile.id);
      formik.setFieldValue('video', selectedFile);
    }
  }, [selectedFile]);

  return (
    <>
      <RadioGroup
        aria-label="video-type"
        name="videoType"
        value={formik.values['videoType']}
        onChange={formik.handleChange}
        row
      >
        <FormControlLabel
          value="link"
          control={<Radio color="primary" />}
          label="링크 삽입"
        />
        <FormControlLabel
          value="file"
          control={<Radio color="primary" />}
          label="파일 업로드"
        />
      </RadioGroup>

      {formik.values['videoType'] === 'link' ? (
        <TextField
          type="text"
          name={valueType}
          value={formik.values[valueType] || ''}
          onChange={formik.handleChange}
          variant="outlined"
          label="유튜브 링크"
          error={!!formik.errors['url']}
          helperText={formik.errors['url']}
          required
        />
      ) : (
        <div className="filebox">
          <input
            type="file"
            id="ex_file"
            onChange={handleChange}
            accept=" video/*"
            name={valueType}
            required
          />
          <TextField
            className="file_input"
            type="text"
            variant="outlined"
            label="동영상 경로"
            placeholder="동영상 경로"
            name={valueType}
            disabled
            required
            value={
              file
                ? file.file?.name
                : defaultVideo
                ? defaultVideo.originalFileName
                : ''
            }
          />
          <label htmlFor="ex_file" className="file_btn file_label">
            동영상 업로드
          </label>
        </div>
      )}

      {formik.values['videoType'] === 'link'
        ? formik.values[valueType] && (
            <ReactPlayer url={formik.values[valueType]} width="100%" />
          )
        : (defaultVideo || selectedFile) && (
            <video
              controls
              src={filePath}
              style={{ maxWidth: 640, maxHeight: 360 }}
            />
          )}
    </>
  );
}

export default Player;
