/** @jsxImportSource @emotion/react */
import React, { useState, useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  TablePagination,
} from '@material-ui/core';
import { css } from '@emotion/react';
import PaginationUi from './CustomPaginationUi';
import Skeleton from '@material-ui/lab/Skeleton';

const center = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export type Column = {
  name: string;
  property: string;
  align?: string;
  accessor?: string[];
  style?: { head?: any; body?: any };
  onClick?: any;
};

export type PageState = {
  page: number;
  rowsPerPage: number;
};

export type PagingActions = {
  handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => void;
  handleChangeRowsPerPage?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
};

export type TableProps = {
  list: any;
  fetching: boolean;
  columns: Column[];
  defaultLength?: number;
  error?: string;
  isPaging?: boolean;
  pageState?: PageState;
  actions?: PagingActions;
  handleClick?: any;
};

function formatter(liElement: any, property: string) {
  if (property == 'phoneNumber') {
    var formatNum = '';
    if (liElement.length == 11) {
      formatNum = liElement.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    } else if (liElement.length == 8) {
      formatNum = liElement.replace(/(\d{4})(\d{4})/, '$1-$2');
    } else {
      if (liElement.indexOf('02') == 0) {
        formatNum = liElement.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
      } else {
        formatNum = liElement.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
      }
    }
    return formatNum;
  }
  return liElement;
}

function CustomTable({
  list,
  fetching,
  columns = [],
  defaultLength = 10,
  error,
  isPaging,
  pageState,
  actions,
  handleClick = () => {},
}: TableProps): JSX.Element {
  const [rows, setRows] = useState(defaultLength);
  const handleMoreButton = (): void => {
    setRows(list.totalElements);
  };

  const pagination = useMemo(() => {
    return (
      isPaging &&
      pageState &&
      actions && (
        <TablePagination
          rowsPerPageOptions={[]}
          count={list.totalElements}
          rowsPerPage={pageState.rowsPerPage}
          page={pageState.page}
          onPageChange={actions.handleChangePage}
          // onChangeRowsPerPage={actions.handleChangeRowsPerPage}
          // SelectProps={{
          //   inputProps: { 'aria-label': 'rows per page' },
          //   native: true,
          // }}
          ActionsComponent={PaginationUi}
          css={css`
            .MuiTablePagination-input {
              color: #3a3a3a;
            }
            .MuiTablePagination-spacer {
              flex: none;
            }
            .MuiTablePagination-toolbar {
              padding: 0px 10px;
              border: solid 1px #dedede;
              border-top: 0px;
              border-bottom: 0px;
              p {
                font-size: 0.75rem;
              }
            }
            .MuiTablePagination-select {
              text-align-last: left;
            }
            .MuiTablePagination-selectRoot {
              margin-left: 0;
              width: 92px;
              border: 1px solid #d4d4d5;
              font-size: 0.75rem;
            }
          `}
        />
      )
    );
  }, [isPaging, list, pageState, actions]);

  const arrayCell = (
    { property, element }: { property: string; element: any[] },
    accessor?: string[],
  ): JSX.Element => {
    const length = element.length;
    const maxLength = accessor ? 1 : 5;
    return (
      <TableCell
        key={property}
        align="left"
        css={css`
          &.MuiTableCell-root {
            font-size: 0.85rem;
            border: solid 1px #dedede;
          }
        `}
      >
        {length > 0 ? (
          <>
            <ul
              css={css`
                padding: 10px;
              `}
            >
              {element.slice(0, maxLength).map((value, i) => {
                if (typeof value === 'object') {
                  return (
                    <li key={i}>
                      {(accessor || []).map((acc) => value[acc]).join(', ')}
                    </li>
                  );
                }
                return <li key={i}>{value}</li>;
              })}
            </ul>
            {length > maxLength && '...'}
          </>
        ) : (
          <div css={center}>-</div>
        )}
      </TableCell>
    );
  };

  const row = (data: any, li: any): JSX.Element => {
    return (
      <>
        {data.map(
          ({ property, accessor, align, onClick, name, condition }: any) => {
            if (Array.isArray(li[property])) {
              return arrayCell({ property, element: li[property] }, accessor);
            }

            return (
              <TableCell
                key={property}
                align={align}
                css={css`
                  &.MuiTableCell-root {
                    font-size: 0.85rem;
                    border: solid 1px #dedede;
                    padding: 0.5rem;
                  }
                `}
              >
                {onClick ? (
                  condition ? (
                    condition(li[property]) ? (
                      <button
                        style={{
                          width: '100%',
                          border: 'none',
                          background: 'none',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          if (onClick) onClick(li);
                        }}
                      >
                        <span
                          style={{
                            border: 'solid 1px #68AACF',
                            borderRadius: 4,
                            padding: 2,
                            color: '#68AACF',
                          }}
                        >
                          {li[property]}
                        </span>
                      </button>
                    ) : (
                      li[property]
                    )
                  ) : (
                    <button
                      style={{
                        width: '100%',
                        border: 'none',
                        background: 'none',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        if (onClick) onClick(li);
                      }}
                    >
                      <span
                        style={{
                          border: 'solid 1px #68AACF',
                          borderRadius: 4,
                          padding: 2,
                          color: '#68AACF',
                        }}
                      >
                        {name}
                      </span>
                    </button>
                  )
                ) : li[property] ? (
                  formatter(li[property], property)
                ) : (
                  <div css={center}>-</div>
                )}

                {/* {onClick && condition && condition(li[property]) ? (
                  <button
                    style={{
                      width: '100%',
                      border: 'none',
                      background: 'none',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      if (onClick) onClick(li);
                    }}
                  >
                    <span
                      style={{
                        border: 'solid 1px #68AACF',
                        borderRadius: 4,
                        padding: 2,
                        color: '#68AACF',
                      }}
                    >
                      {name}
                    </span>
                  </button>
                ) : li[property] ? (
                  li[property]
                ) : (
                  <div css={center}>-</div>
                )} */}
              </TableCell>
            );
          },
        )}
      </>
    );
  };

  return (
    <>
      {fetching ? (
        <div>
          <Skeleton animation="wave" height={50} />
          <Skeleton animation="wave" variant="rect" height={322} />
        </div>
      ) : (
        <TableContainer
          component={Paper}
          css={css`
            margin: 5px 0px;
          `}
        >
          {list && (
            <>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map(({ name, property, style }) => (
                      <TableCell
                        key={name}
                        variant="head"
                        align="center"
                        title={property.toUpperCase()}
                        style={style && style.head}
                        css={css`
                          &.MuiTableCell-root {
                            background-color: #f3f3f3;
                            font-size: 0.9rem;
                            font-weight: bold;
                            border: solid 1px #dedede;
                            padding: 0.5rem;
                          }
                        `}
                      >
                        {name.toUpperCase()}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {list.totalElements <= 0 ? (
                  //FIXME: no data 수정
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={columns.length}
                        css={css`
                          &.MuiTableCell-root {
                            font-size: 0.85rem;
                            border: solid 1px #dedede;
                            padding: 0.5rem;
                          }
                        `}
                      >
                        {'no data...'}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {(list.content || []).slice(0, rows).map((li: any) => (
                      <TableRow
                        key={li.id}
                        hover
                        onClick={() => handleClick(li)}
                        css={css`
                          &.MuiTableRow-root {
                            ${handleClick && 'cursor: pointer;'}
                          }
                        `}
                      >
                        {row(columns, li)}
                      </TableRow>
                    ))}
                  </TableBody>
                )}
                {(isPaging || rows < list.totalElements) &&
                  list.totalElements > 0 && (
                    <TableFooter>
                      <TableRow>
                        {isPaging ? (
                          pagination
                        ) : (
                          <TableCell
                            align="right"
                            colSpan={columns.length}
                            css={css`
                              &.MuiTableCell-root {
                                font-size: 0.85rem;
                                border: solid 1px #dedede;
                                padding: 0.5rem;
                              }
                            `}
                          >
                            {rows < list.totalElements && (
                              <button
                                style={{
                                  fontSize: '0.9rem',
                                  border: 'none',
                                  background: 'none',
                                  textDecoration: 'underline',
                                  color: '#0075b7',
                                }}
                                type="button"
                                onClick={handleMoreButton}
                              >
                                Show more...
                              </button>
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    </TableFooter>
                  )}
              </Table>
            </>
          )}
        </TableContainer>
      )}
    </>
  );
}

export default CustomTable;
