import apiModule, { ApiRequest } from '../../common/wrapper/axios';

export interface LoginDto {
  email: string;
  password: string;
  rememberMe?: boolean;
}

export interface LoginEntity {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  scope: string;
  token_type: string;
}

export const doLogin: ApiRequest<LoginDto, LoginEntity> = (params) => {
  return apiModule.post('/sign-in', params);
};
