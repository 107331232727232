import React, { useEffect } from 'react';

import PDFViewer from '../../components/PDFViewer';
import PDFJSBackend from '../../components/PDFViewer/backends';
import apiModule from '../../common/wrapper/axios';

function DownloadReport({ checkup }: { checkup: any }) {
  const [src, setSrc] = React.useState('');

  useEffect(() => {
    apiModule({
      method: 'GET',
      url: '/reports/' + checkup + '/pdf-url',
    })
      .then(({ data }) => {
        setSrc(data.pdfUrl);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [checkup]);

  return (
    <div
      style={{
        border: '1px solid #DDDDDD',
        padding: 10,
        margin: '10px 0px',
        height: 1000,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      {src ? (
        <PDFViewer backend={PDFJSBackend} src={src} />
      ) : (
        '보고서 PDF 파일이 아직 연동되지 않았습니다.'
      )}
    </div>
  );
}

export default DownloadReport;
