import { Location } from 'history';
import qs from 'query-string';

export const parse = (location: Location) => {
  return qs.parse(location.search);
};

export const parseQuery = (search: string) => {
  return qs.parse(search);
};

export const stringify = (obj: any) => {
  return qs.stringify(obj);
};

export const pathnameFactory = (url: string) => {
  switch (url) {
    case '/':
      return `/dashboard`;
    case '/service':
      return `/service/disease`;
    case `/member`:
      return `/member/user`;
    case `/history`:
      return `/history/server`;
    default:
      return url;
  }
};
