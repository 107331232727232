/** @jsxImportSource @emotion/react */
import React from 'react';
import { Link } from 'react-router-dom';
import map from 'lodash/map';
import includes from 'lodash/includes';
import cn from 'classnames';
import { Menu } from '../features/menu/staticData/menu';
import { defaultNav } from '../common/styles/NavStyles';

export interface NavListProps<T = string> {
  menus: T[];
  authority?: string;
  selected?: string | string[];
  css?: any;
}

function NavList({ menus, selected, css, authority }: NavListProps<Menu>) {
  return (
    <ul css={[defaultNav, css]}>
      {map(menus, ({ id, label, url, path, authority: a }: Menu) => {
        if (authority && a && !a.includes(authority)) {
          return;
        }
        return (
          <li key={id}>
            <Link
              to={url}
              className={cn({ selected: includes(selected, path || url) })}
            >
              {label}
            </Link>
          </li>
        );
      })}
    </ul>
  );
}

export default React.memo(NavList);
