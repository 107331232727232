import { useCallback, useMemo, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'query-string';
import { TableQuery } from '../../features/table/staticData/table';
import {
  getYearStartDate,
  getNowDate,
  getDateReqFormat,
} from '../../common/utils/dateUtils';

function useTableRoutePath(initialQuery: TableQuery) {
  const location = useLocation();
  const history = useHistory();
  const [startDate, setStartDate] = useState(getYearStartDate());
  const [endDate, setEndDate] = useState(getNowDate());

  const search = useMemo(() => {
    const search = qs.parse(location.search);
    delete search.id;
    return search;
  }, [location.search]);

  const query = useMemo(() => {
    return {
      ...initialQuery,
      ...search,
      startDate: getDateReqFormat(startDate),
      endDate: getDateReqFormat(endDate),
    };
  }, [initialQuery, search, startDate, endDate]);

  const queryToString = useMemo(() => {
    return qs.stringify(query);
  }, [query]);

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      history.push(
        `${history.location.pathname}?${qs.stringify({
          ...query,
          page: newPage,
        })}`,
      );
    },
    [history, query],
  );

  const routePath = useCallback(
    (page, addQuery) => {
      history.push(
        `${history.location.pathname}?${qs.stringify({
          ...query,
          ...addQuery,
          page,
        })}`,
      );
    },
    [history, query],
  );

  const [keyword, setKeyword] = useState('');
  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      history.push(
        `${history.location.pathname}?${qs.stringify({
          ...initialQuery,
          search: keyword,
        })}`,
      );
    },
    [keyword, history, initialQuery],
  );

  return {
    currentPath: location.pathname,
    query,
    queryToString,
    handleChangePage,
    keyword,
    setKeyword,
    onSubmit,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    routePath,
  };
}

export default useTableRoutePath;
