import React, { useState } from 'react';
import Tree from './Tree';
import SubDisease from './SubDisease';

function Disease() {
  const [selected, setSelected] = useState('');

  return (
    <div style={{ display: 'flex' }}>
      <Tree selected={selected} setSelected={setSelected} />
      <div style={{ width: '80%', paddingLeft: 40, marginBottom: 40 }}>
        <SubDisease selected={selected} />
      </div>
    </div>
  );
}

export default Disease;
