import apiModule, { ApiRequest } from '../../common/wrapper/axios';
import { name as genotype } from './genotypeSlice';
import { genotypeType } from '../report/reportApi';
import { RequestDto } from '../news/newsApi';

export type GenotypeReqestDto = RequestDto<genotypeType[]>;
export type GenotypeEntity = genotypeType[];

export const requestGenotype: ApiRequest<GenotypeReqestDto, GenotypeEntity> = (
  params = { method: 'get' },
) => {
  const { method, id, data } = params;

  return apiModule.request({
    url: `sub-disease/${genotype}/${id}`,
    method,
    data,
  });
};
