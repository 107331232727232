/** @jsxImportSource @emotion/react */
import React, { memo } from 'react';
import map from 'lodash/map';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { defaultForm } from '../common/styles/FormStyles';

interface Option {
  value: string;
  label: string;
}

export type FieldProps = TextFieldProps & {
  label?: string;
  fieldType?: 'select' | 'multiline';
  name: string;
  type: 'text' | 'email' | 'password' | 'hidden';
  placeholder?: string;
  option?: Option[];
};

interface SubmitFormProps {
  formik: any;
  formData: FieldProps[];
  children: React.ReactElement[] | React.ReactElement;
  css?: any;
  disableArr?: string[];
}

function SubmitFormWithFormik({
  formik,
  formData,
  children,
  css,
  disableArr = [],
}: SubmitFormProps) {
  return (
    <form onSubmit={formik.handleSubmit} css={[defaultForm, css]}>
      {map(formData, ({ name, option, ...rest }: FieldProps) => {
        return (
          <TextField
            key={name}
            {...rest}
            name={name}
            onChange={formik.handleChange}
            value={formik.values[name]}
            error={!!formik.errors[name]}
            helperText={formik.errors[name]}
            disabled={disableArr.includes(name)}
          >
            {option &&
              map(option, ({ value, label }: Option) => {
                return (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                );
              })}
          </TextField>
        );
      })}
      {children}
    </form>
  );
}

export default memo(SubmitFormWithFormik);
