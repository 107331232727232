import React, { useMemo } from 'react';
import ReportList from '../../features/report/ReportList';
import Summary from '../../features/report/Summary';
import DownloadReport from '../../features/report/DownloadReport';
import Report from '../../features/report/Report';
import { name, reportActions } from '../../features/report/reportSlice';
import useRoutePath from '../../common/hooks/useRoutePath';
import { parse } from '../../common/utils/routeUtils';
import { useAppDispatch } from '../../app/hooks';
import { DownBtn, DownloadText } from '../../common/styles/dashboardStyles';
import { ReactComponent as DownloadIcon } from '../../assets/icon.svg';
import apiModule from '../../common/wrapper/axios';
import { useAppSelector } from '../../app/hooks';
import Skeleton from '@material-ui/lab/Skeleton';

function Reports() {
  const dispatch = useAppDispatch();
  const [summary, checkup, loading] = useAppSelector((state) => [
    reportActions.reportByPublicKey(state),
    reportActions.checkupSelector(state),
    state.reports.loading,
  ]);

  const routes = useRoutePath(
    {
      stateKey: name,
      setPath: reportActions.setSelectedKey,
      defaultParams: 'publicKey',
    },
    (location) => parse(location).publicKey,
  );

  const isNormalReport = useMemo(() => {
    return checkup && checkup.hasPdf && checkup.hasDetail;
  }, [checkup]);

  React.useEffect(() => {
    return () => {
      dispatch(reportActions.resetSelectedKey());
    };
  }, [dispatch]);

  const downbtn = useMemo(() => {
    return (
      <DownBtn
        onClick={(e: any) => {
          apiModule({
            method: 'GET',
            url: '/reports/' + routes.selected + '/pdf',
            responseType: 'blob', // important
          })
            .then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute(
                'download',
                response.headers['content-disposition'].split('"')[1],
              ); //or any other extension
              document.body.appendChild(link);
              link.click();
            })
            .catch((e) => {
              console.log(e);
            });
        }}
      >
        <DownloadText>다운로드</DownloadText>
        <DownloadIcon />
      </DownBtn>
    );
  }, [routes.selected]);

  return (
    <div style={{ display: 'flex', padding: '50px 0px' }}>
      <ReportList {...routes} />
      <div style={{ width: '80%' }}>
        {summary ? (
          <>
            <div style={{ display: 'flex' }}>
              <div>
                <h3 style={{ marginTop: 0, marginRight: '16px' }}>
                  {routes.selected}
                </h3>
              </div>
              <div>{isNormalReport && downbtn}</div>
            </div>
            <Summary summary={summary} />
            {isNormalReport ? (
              <>
                {new Date(summary?.receiptDate) >= new Date('2022-05-09') ? (
                  <DownloadReport checkup={routes.selected} />
                ) : (
                  <Report {...routes} />
                )}
              </>
            ) : (
              <DownloadReport checkup={routes.selected} />
            )}
          </>
        ) : (
          <div>
            {loading ? (
              <div style={{ backgroundColor: '#F6F6F6', padding: 20 }}>
                <h2 style={{ width: '40%' }}>
                  <Skeleton animation="wave" />
                </h2>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 20,
                  }}
                >
                  <div style={{ width: '30%' }}>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                  </div>

                  <div style={{ width: '30%' }}>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                  </div>

                  <div style={{ width: '30%' }}>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                  </div>
                </div>
                <div>
                  <Skeleton animation="wave" variant="rect" height={322} />
                </div>
              </div>
            ) : (
              'No Data'
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Reports;
