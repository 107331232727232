import { menu } from '../../../common/text/constants';
import LogoutButton from '../LogoutButton';
import ProfileButton from '../ProfileButton';

// types
type menuType = keyof typeof menu;
type OriginMenu = { l: string; s: string[]; a: string[] };
type CommonMenuType = {
  id: string;
  url: string;
  path?: string;
  authority?: string[];
  label: typeof menu[menuType];
};
export type Menu = CommonMenuType & {
  subMenu?: CommonMenuType[];
};

// data
export const originMenu: OriginMenu[] = [
  { l: 'dashboard', s: [], a: ['ADMIN', 'MANAGER'] },
  { l: 'reports', s: [], a: ['ADMIN', 'MANAGER', 'DOCTOR'] },
  {
    l: 'service',
    s: ['disease', 'news', 'push', 'trigger'],
    a: ['ADMIN', 'MANAGER'],
  },
  { l: 'member', s: ['user', 'institution'], a: ['ADMIN', 'MANAGER'] },
  {
    l: 'history',
    s: ['server', 'report', 'access', 'download', 'push', 'checkup', 'sercode'],
    a: ['ADMIN', 'MANAGER'],
  },
];

export const menus: Menu[] = originMenu.map(
  ({ l, s, a }: OriginMenu, id: number) => {
    const labelUpperCase = l.toUpperCase() as menuType;
    return {
      id: String(id),
      url: `/${l}`,
      label: menu[labelUpperCase],
      authority: a,
      subMenu: s.map((s, i) => {
        const subLabelUpperCase = `${labelUpperCase}_${s.toUpperCase()}` as menuType;
        return {
          id: `${s}_${i}`,
          url: `/${l}/${s}`,
          label: menu[subLabelUpperCase],
        };
      }),
    };
  },
);

interface OptionMenu {
  label: string;
  component?: any;
  handlePopClose?: any;
}
export const optionMenus: OptionMenu[] = [
  {
    label: '프로필',
    component: ProfileButton,
  },
  {
    label: '로그아웃',
    component: LogoutButton,
  },
];
