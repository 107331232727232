import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { pushActions } from '../../features/push/pushSlice';
import { DataGridContainter } from '../../features/disease/Histogram';
import DataGrid from 'react-data-grid';
import { pushColumns } from '../../features/push/staticData/push';
import PopUp from '../../components/PopUp';
import { triggerAction, triggerType } from '../../features/push/type';
import { Button2, CreateContainer, UpperContainer } from '../member';

const Trigger = () => {
  const dispatch = useAppDispatch();
  const { triggers, loading } = useAppSelector((state) => state.push.trigger);
  const [isCreate, create] = useState<boolean>();
  const [action, setAction] = useState<triggerAction>('CREATE');
  const [body, setBody] = useState('');
  const [title, setTitle] = useState('');
  const [type, setType] = useState<triggerType>('NEWS');
  const [targetToken, setTargetToken] = useState('');

  const inputList = [
    {
      label: '제목',
      state: title,
      setState: (v: string) => setTitle(v),
    },
    {
      label: '메시지',
      state: body,
      setState: (v: string) => setBody(v),
    },
    {
      label: '트리거 타입',
      state: type,
      setState: (v: triggerType) => setType(v),
    },
    {
      label: '타겟 ID',
      state: targetToken,
      setState: (v: string) => setTargetToken(v),
    },
    {
      label: 'ACTION',
      state: action,
      setState: (v: triggerAction) => setAction(v),
    },
  ];

  useEffect(() => {
    dispatch(pushActions.fetchPushTriggers());
  }, [dispatch]);
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <DataGridContainter>
      <UpperContainer style={{ marginBottom: 16 }}>
        <div></div>
        <CreateContainer>
          <Button2 onClick={() => create(true)}>트리거 추가하기</Button2>
        </CreateContainer>
      </UpperContainer>
      {isCreate ? (
        <PopUp
          inputList={inputList}
          title={'트리거 추가하기'}
          onClose={() => {
            create(false);
          }}
          onClick={() => {
            create(false);
            dispatch(
              pushActions.createPushTriggers({
                title,
                type,
                body,
                action,
                targetToken,
              }),
            );
          }}
        />
      ) : null}
      <DataGrid
        columns={pushColumns}
        rows={triggers}
        // onRowsChange={handleRowChange}
        className="grid rdg-light"
        // emptyRowsRenderer={EmptyRowsRenderer}
      />
    </DataGridContainter>
  );
};

export default Trigger;
