import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import {
  Button1,
  Container,
  Input1,
  LowerContainer,
  SearchContainer,
  UpperContainer,
} from '../member';
import { getReportDownloadLog } from '../../features/history/historySlice';
import useTableRoutePath from '../../common/hooks/useTableRoutePath';
import {
  columns,
  initialDownloadQuery,
} from '../../features/history/staticData/download';
import Table from '../../components/CustomTable';
import { parseQuery } from '../../common/utils/routeUtils';
import { useLocation } from 'react-router-dom';
import CalendarPicker from '../../components/CalendarPicker';

function Download() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { reportDownloadLog } = useSelector(
    (state: RootState) => state.history.reportDownloadLogState,
  );
  const {
    query,
    handleChangePage,
    keyword,
    onSubmit,
    setKeyword,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
  } = useTableRoutePath(initialDownloadQuery);

  useEffect(() => {
    dispatch(getReportDownloadLog(query));
  }, [dispatch, query]);
  return (
    <Container>
      <UpperContainer>
        <SearchContainer onSubmit={onSubmit}>
          <Input1
            type={'text'}
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            placeholder={'확인 코드 입력'}
          />
          <Button1 type="submit">검색</Button1>
        </SearchContainer>
        <CalendarPicker
          isOnlyCalender
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      </UpperContainer>
      <LowerContainer>
        <Table
          columns={columns}
          list={reportDownloadLog}
          fetching={false}
          isPaging
          pageState={{
            page: Number(parseQuery(location.search).page || 0),
            rowsPerPage: 10,
          }}
          actions={{
            handleChangePage,
          }}
        />
      </LowerContainer>
    </Container>
  );
}

export default Download;
