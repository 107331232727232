import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../app/hooks';
import PopUp from '../../components/PopUp';
import { memberActions, initPassword } from '../member/memberSlice';

function ResetPasswordPopup({ setOpen, readonly }: any) {
  const dispatch = useDispatch();

  const member = useAppSelector((state) => memberActions.memberSelector(state));

  const [email, setEmail] = useState(member ? member.email : '');

  const memberInputList = [
    {
      label: '이메일',
      state: email,
      setState: setEmail,
      type: 'input',
      readonly: readonly,
      inputType: 'email',
    },
  ];

  const handleClickPopupButton = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(
        initPassword({
          email: email,
        }),
      );
    },
    [dispatch, email],
  );

  const handelClosePopup = useCallback(() => {
    dispatch(memberActions.resetSelected());
    setOpen(false);
  }, [dispatch, setOpen]);

  return (
    <PopUp
      onClose={handelClosePopup}
      inputList={memberInputList}
      title={'임시 비밀번호 발송'}
      onClick={handleClickPopupButton}
      component={
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <h3>비밀번호 재설정</h3>
          <span>
            가입하신 이메일 주소를 입력하시면 임시 비밀번호가 발송됩니다.
          </span>
          <br />
          <span>로그인 후 [프로필]-[내 정보]-[비밀번호 변경] 메뉴를 통해</span>
          <span> 비밀번호를 재설정하여 사용바랍니다.</span>
          <br />
        </div>
      }
    />
  );
}

export default ResetPasswordPopup;
